<template>
    <div>
          <div class="business-details-area">
            <div class="container">
              <div class="row">
                <div class="col-md-3 col order-first">
                  <ul class="businesslist">
                    <!-- <li :class="($route.params.id == cat.id) ? testClass : ''"  v-for="(cat, i) in categories" :key="i"><a href @click.prevent="clkCategory(cat.id)">{{ cat.category }}</a></li> -->
                    <li :style="($route.params.id == cat.id) ? 'background: #0070ba; color: #fff !important;': 'color:white'" v-for="(cat, i) in categories" :key="i"><a href @click.prevent="clkCategory(cat.id)">{{ cat.category }}</a></li>
				  </ul>
                </div>
                <div class="col-md-9 order-last">
					
				  <div class="website-area">
					  <ul class="weblist">
							<li v-for="(s, i) in sms" :key="i">
								<a :href="s.link" target="_blank">{{s.sub_category}}</a>
							</li>
					</ul>
				  </div>
                  <div v-for="(des, i) in descs" :key="i" class="description">
                    <p>{{ des.desc }}</p>
                  </div>
				  
                  <div class="more-details d-flex">
					<div class="img-cat">
                      	<carousel v-if="ims.length > 0" :autoplay="true" :nav="false" :items="1" :responsive="{0:{items:1,nav:false},600:{items:1}}">
							<div v-for="(img, index) in ims" :key="index">
								<img class="img-responsive" v-lazy="url + img.image" @error="$event.target.src='/dist/main.jpg'">
							</div>
						</carousel>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        
    </div>
</template>

<script>
import axios from 'axios'
import carousel from 'vue-owl-carousel'
export default {
	props: ['ul'],
	components: {
		carousel
	},
    created() {
		this.$root.$on('category', (e) => {
			this.form.id = e
			let lang = localStorage.getItem('lang')
			if(lang){
				this.getData(lang)
			}else{
				this.getData("English")
			}
		})
	  	if(this.ul.lang == 'English'){
			//this.form.id = this.$route.params.id
            this.commonWork()
        }else if(this.ul.lang == 'Arabic'){
			//this.form.id = this.$route.params.id
            this.commonWork()
        }else if(this.ul.lang == 'French'){
			//this.form.id = this.$route.params.id
            this.commonWork()
        }else{
			let lang = localStorage.getItem('lang')
			if(lang){
				this.getData(lang)
			}else{
				this.getData("English")
			}
        }
      
      this.$root.$on('english', (e) => {
        this.form.lang = e
        this.commonWork()
      })

      this.$root.$on('french', (e) => {
        this.form.lang = e
        this.commonWork()
      })

      this.$root.$on('arabic', (e) => {
        this.form.lang = e
        this.commonWork()
      })

    },
    data() {
        return {
            url: this.ul.url,
            categories: [],
            sms: [],
            descs: [],
            ims:[],
            dummies: [],
            form: {
                lang: this.ul.lang,
                id: this.$route.params.id
            },
			pId: this.$route.params.id,
			testClass: 'businesslist li:nth-child() a'
        }
    },
	methods: {
		getData(lang){
			this.form.lang = lang
			//this.form.id = this.$route.params.id
            this.commonWork()
		},
        commonWork(){
			this.ims = []
			this.categories = []
			this.descs = []
			this.sms = []
            axios.get(this.url +'api/getBusinessCategory', {params: this.form})
				.then(({data}) => {
					this.categories = data.cats
					this.descs = data.descs
					this.ims = data.images
					this.sms = data.sub_cats

			})
        },
		clkCategory(id){
			this.$root.$emit('category', id)
		}
	}
}
</script>

<style scoped>
/* Nomalise*/
.img-responsive{
        display:block; 
		height:300px !important; 
		width:800px !important;
		margin:0 !important;
    }
/* Nomalise*/
img {
    max-width: 100%;
}
.img-cat {
    overflow: hidden;
}
.navbar.navbar-expand-lg.navbar-light.bg-light {
	margin: 0;
	padding: 0;
}
hr {
	margin: 0;
}
/*Main  Area*/
.main_area {
	border-bottom: 1px solid #ddd;
}
.business-details-area {
	padding-bottom: 100px;
}
.col_area {
	background-color: #ddd;
	padding-left: 10px;
    padding-bottom: 20px;
	margin-bottom: 10px;
	text-align: center;
}
.title {
	text-align: center;
	font-size: 20px;
	color: #00954c;
}
.title.tb{
	color:red;
}
.col_area p {
	font-size: 16px !important;
    line-height: 1.1em !important;
    margin-bottom: 0;
}
.donation-btn {
	background: red;
	color: #fff;
	border-radius: 4px;
	display: block;
	width: 141px;
	height: 44px;
	line-height: 20px;
    margin: 0 auto;
	text-align: center;
	padding: 11px 10px;
	border-radius: 8px;
	cursor: pointer;
}
.donation_area,.imagarea {
	text-align: center;
}
.donationtitl h1{
color: #00954c;
font-weight: 700;
text-align: center;
padding: 24px 0;
	font-weight: 700;
}
.colpdtrb{
padding-top: 0;
padding-bottom:0;
padding-right: 0;
}
.colpdtlb{
   padding-top: 0;
   padding-bottom:0;
   padding-left: 30px;
}
.savelife {
	text-align: center;
	font-size: 90px;
	color: green;
}
.text_area p {
	font-size: 24px;
	font-family: 'BenSen', sans-serif;
	font-weight: normal;
}
.savelife span {
	color: #000 !important;
}
.eq p {
	text-align: center;
	font-family: 'BenSen', sans-serif;
}
.text_area {
	padding-top: 30px;
}
.socilalink ul {
	list-style: none;
	padding: 0;
	margin: 0;
}
.socilalink {
	margin-right: 20px;
}
.d-flex.justify-content-between {
	padding-left: 30px;
}
.donate-ways.marchent {
	text-align: center;
}
.donate-ways.marchent a {
	color: green;
}
.title.tr {
	color: red;
}
.donation-btn.green {
	background: green;
}
.hatbariye {
	margin-top: -33%;
	position: relative;
	z-index: 9;
}
.carousel-item{
	width: 100%;
	height: 100vh;
	background-size: cover;
}
.cat-logo{
	margin-bottom: 20px;
}
.description p{
	color:#777;
	line-height: 35px;
	font-family: 'Rubik';
	font-size: 18px;
}
.details-ledt h3 {
	font-size: 18px;
	font-family: 'Rubik';
}
.icon-bg {
	width: 100px;
	height: 50px;
	background: #0070ba;
	text-align: center;
	line-height: 50px;
	border-radius: 76%;
	color: #fff;
	font-size: 21px;
	margin-right: 30px;
}
ul.weblist li {
	transition: .6s;
}
ul.weblist li:hover a {
    color: #0070ba;
}
/*common css*/
.description {
	margin-bottom: 25px;
}
.website-area {
    margin-bottom: 35px;
}
ul.weblist {
    padding: 0;
    list-style: none;
}

.description{
    margin-bottom: 25px;
}
ul.weblist li a {
    color: #777;
    font-family: 'Rubik';
    font-size: 18px;
}
/* endcommon css*/
.details-ledt > h3 {
	margin-bottom: 35px;
	color: #222;
	font-family: 'Rubik';
}
.dt-title-des h3 {
	font-size: 18px;
	padding-bottom: 10px;
	font-family: 'Rubik';
}
.dt-title-des p {
	color: #777;
	margin-top: 20px;
	font-size: 16px;
	font-family: 'Rubik';
	line-height: 30px;
}
.dt-content {
	padding-right: 50px;
	margin-bottom: 30px;
}
h3.qote{
	font-size: 18;
	color: #777;
}
.web a {
	background: #0070ba;
	width: 200px;
	height: 50px;
	display: block;
	line-height: 50px;
	text-align: center;
	color: #fff;
	font-size: 16px;
	border-radius: 0 30px 30px 0;
}
.test{
	background: #4e5153;
    color: #fff !important;
}
    /*list*/
.businesslist {
	list-style: none;
	padding: 0;
	margin: 0;
}
.businesslist li {
	border: 1px solid rgb(0,0,0, .1);
	transition: .4s;
	margin-bottom: 10px;
}
.businesslist li:nth-child() a {
    background: #4e5153;
    color: #fff !important;
}
ul.businesslist li:hover {
    background: #0070ba;
}
ul.businesslist li:hover a{
	color: #fff;
	text-decoration: none;
}
.businesslist li a{
    color: #222;
    display: block;
    padding: 20px;
    font-size: 16px;
    font-family: 'Rubik';
	line-height: 34px;
}

/* Extra small devices (portrait phones, less than 576px)*/
/* No media query since this is the default in Bootstrap*/

@media only screen and (max-width: 575px) and (min-width: 420px)  {
  	.website-area{
    	margin-top: 35px;
	}
	
	.order-first {
	-webkit-box-ordinal-group: 0;
	-ms-flex-order: -1;
	order: 13 !important;
	}

	.order-last {
	-webkit-box-ordinal-group: 14;
	-ms-flex-order: 13;
	order: -1 !important;
	}

	.order-0 {
	-webkit-box-ordinal-group: 1;
	-ms-flex-order: 0;
	order: 0;
	}
	.img-responsive{
			height:auto !important; 
	}
	
}
/* Medium devices (tablets, 768px and up)*/
@media only screen and (max-width: 480px) and (min-width: 320px)  {
  .description{
    	margin-top: 35px;
	}
	.order-first {
  -webkit-box-ordinal-group: 0;
  -ms-flex-order: -1;
  order: 13 !important;
  }

  .order-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: -1 !important;
  }
  .img-responsive{
		  height:auto !important; 
    }
}
/* Medium devices (tablets, 768px and up)*/
@media only screen and (max-width: 767px) and (min-width: 576px)  {
	.website-area{
    	margin-top: 35px;
	}
	
.order-first {
  -webkit-box-ordinal-group: 0;
  -ms-flex-order: -1;
  order: 13;
}

.order-last {
  -webkit-box-ordinal-group: 14;
  -ms-flex-order: 13;
  order: -1;
}

.order-0 {
  -webkit-box-ordinal-group: 1;
  -ms-flex-order: 0;
  order: 0;
}
.img-responsive{
		height:auto !important; 
    }
    .description{
      margin-top: 40px;
  }
}
/* Medium devices (tablets, 768px and up)*/
@media only screen and (max-width: 991px) and (min-width: 768px)  {
	.businesslist li a{
    padding: 15px;
  }
  .img-responsive{
		height:auto !important; 
    }
}
/* Large devices (desktops, 992px and up)*/
@media only screen and (max-width: 1199px) and (min-width: 992px)  {
	
	
}
/* Extra large devices (large desktops, 1200px and up)*/

@media only screen and (max-width: 1600px) and (min-width: 1200px)  {
	
}
</style>