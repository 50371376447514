<template>
    <div>
        <div>
            <about-us :ul="this.url"></about-us>
            
            <counter-area :ul="this.url"></counter-area>

            <category-area :ul="this.url"></category-area>

            <map-area :ul="this.url"></map-area>

            <blog-area :ul="this.url"></blog-area>
            
            <achievement-area :ul="this.url"></achievement-area>

            <partner-area :ul="this.url"></partner-area>
        </div>
    </div>
</template>

<script>
export default {
    props: ['ul'],
    created() {
        this.$root.$emit('change')
        localStorage.removeItem('title')
        localStorage.removeItem('id')
        localStorage.removeItem('category')
        localStorage.removeItem('lang')
        this.$root.$emit('urlTopbar')
    },
    data() {
        return {
           url:{
                url: this.ul.url,
                lang: this.ul.lang,
            },
            
        }
    },
    methods: {
       
    }
}
</script>
