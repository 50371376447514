<template>
 <div>
      <section class="achievements-area">
        <div class="container">
          <div class="row">
            <div class="col-md-12 col-12">
              <div class="site-title ">
                <h4 class="sub-title">
                  {{titleArea.heading}}
                </h4>
                <h2>{{titleArea.title}}</h2>
              </div>
            </div>
          </div>
        </div>
        <div class="container-fluid">
            <div class="row">
              <div class="no-gutters col-md-12 col-lg-12 col-12">
                <carousel v-if="achievements.length > 0" :autoplay="true" :nav="false" :items="3" :responsive="{0:{items:1,nav:false},600:{items:3}}">
                    <div v-for="(img, index) in achievements" :key="index">
                        <img class="img" :src="url + img.image" alt="dd" >
                        <div class="achi-details">
                          <h3>{{img.title}}</h3>
                          <a href @click.prevent="clkAcmnt(img.id, img.lang)">{{img.rm}}</a>
                        </div>
                    </div>
                </carousel>
              </div>
              
              <!-- <div class="no-gutters col-md-4 col-lg-4 col-12" v-for="a in achievements.data" :key="a.id">
                <div class="achievements-bengal">
                    <div class="img">
                      <img :src="url + a.image" >
                    </div>
                    <div class="achi-details">
                      <h3>{{a.title}}</h3>
                      <p>{{ a.desc }} </p>
                      <a href @click.prevent="clkAcmnt(a.id)">{{a.rm}}</a>
                      <router-link :to="{name: 'achieve_details', params:{id: a.id}}" href="#" class="">{{ a.rm }}</router-link>
                    </div>
                </div>
              </div>
              -->
            </div>
              <!-- <div class="d-flex justify-content-end justify-content-sm-start" style="margin-top:20px;">
              <button style="color:white; font-size:16px; margin-right:10px; padding-right: 10px; padding-left: 10px;" @click.prevent="prevAcvnt"  class="btn btn-sm btn-info">Prev</button>
                <button style="color:white; font-size:16px" @click.prevent="nextAcvnt" class="btn btn-sm btn-info">Next</button>
            </div> -->
        </div>
      </section>  
        

 </div>

</template>


<script>
import axios from 'axios'
import carousel from 'vue-owl-carousel'
export default {
    props: ['ul'],
    components: {
		    carousel
	},
    created() {
        //this.getTitle()
        //this.getData()

        if(this.ul.lang == 'English'){
          this.commonTitle()
            this.commonWork()
        }else if(this.ul.lang == 'Arabic'){
          this.commonTitle()
            this.commonWork()
        }else if(this.ul.lang == 'French'){
          this.commonTitle()
            this.commonWork()
        }else{
          this.getTitle()
          this.getData()
        }
      
        this.$root.$on('english', (e) => {
          this.form.lang = e
          this.commonTitle()
          this.commonWork()
        }),
        this.$root.$on('arabic', (e) => {
          this.form.lang = e
          this.commonTitle()
          this.commonWork()
        }),
        this.$root.$on('french', (e) => {
          this.form.lang = e
          this.commonTitle()
          this.commonWork()
        })
    },
    data() {
      return {
        url: this.ul.url,
        page: 1,
        achievements: [],
        titleArea: {},
        form: {
          lang: this.ul.lang,
          category: 'Achievement'
        },
        dataC: {
          category: '',
          id: '',
          name: 'achievement',
          lang: ''
        }
      }
    },
    methods: {
      getData(){
        this.form.lang = 'English'
        this.commonWork()
      },
      commonWork(){
        axios.get(this.url + 'api/getAchievements', {params: this.form})
        .then(({data}) => {
            this.achievements = data
        })
      },
      commonTitle(){
        axios.get(this.url + 'api/getCommonTitle', {params: this.form})
        .then(({data}) => {
            this.titleArea = data
        })
      },
      getTitle(){
        this.form.lang = 'English'
        this.commonTitle()
      },
      clkAcmnt(id, lang){
        if(lang == 'English'){
          this.dataC.category = 'menu'
          this.dataC.id = id
          this.dataC.lang = lang
          this.$root.$emit('home', this.dataC)
        }else if(lang == 'Arabic'){
          this.dataC.category = 'menu'
          this.dataC.id = id
          this.dataC.lang = lang
          this.$root.$emit('home', this.dataC)
        }else{
          this.dataC.category = 'menu'
          this.dataC.id = id
          this.dataC.lang = lang
          this.$root.$emit('home', this.dataC)
        }
      },
      getAcvmnts(){
        axios.post(this.url + 'api/getAchievements?page=' + this.page, this.form)
          .then(({data}) => {
            this.achievements = data
          })
      },
      nextAcvnt(){
        this.page = this.page + 1
        axios.post(this.url + 'api/getAchievements?page=' + this.page, this.form)
          .then(({data}) => {
            this.achievements = data
          })
      },
	   prevAcvnt(){
        this.page = this.page - 1
        axios.post(this.url + 'api/getAchievements?page=' + this.page, this.form)
          .then(({data}) => {
            this.achievements = data
          })
      }
    }
}
</script>

<style scoped>
.owl-carousel .owl-item img{
  display:block; 
  height: auto !important;
  margin:0 auto 30px;
  max-width: 100% !important;
}
img {
    	max-width: 100%;
      
	}
/** Achievements Area  Start **/
.no-gutters {
	padding: 0;
}
.site-title {
	text-align: center;
	margin-bottom: 60px;
}
.site-title h4 {
	font-size: 18px;
	position: relative;
	color: #0070ba;
	display:inline-block;
  font-family: 'Rubik';
}
.site-title h2 {
	font-size: 48px;
	color: #222;
  font-family: 'Rubik';
  font-weight: 700;
  margin-top: 0;
}
.achi-details {
	padding-left: 20px;
}
.achi-details a {
	color: #0e75bc;
	font-weight: bold;
	font-size: 16px;
	display: block;
  font-family: 'Rubik';
}

.site-title h4::before {
	position: absolute;
	width: 63px;
	height: 2px;
	background: #0070ba;
	top: 10px;
	left: 110%;
	content: "";
}
.site-title h4::after {
    position: absolute;
    width: 63px;
    height: 2px;
    background: #0070ba;
    top: 10px;
    content: "";
    left: -76px;
}
.achievements-area {
	background: #fbfbfb;
	padding: 20px 0;
}

.achi-details h3 {
	color: #222;
	margin-top: 30px;
	margin-bottom: 30px;
  font-family: 'Rubik';
  font-weight: 700;
}
.achi-details p {
	color: #777;
}
.btn-group-sm>.btn, .btn-sm{
  font-family: 'Rubik';
}
@media (min-width: 576px) { 
	
}
/* Medium devices (tablets, 768px and up)*/
@media only screen and (max-width: 575px) and (min-width: 320px)  {
	.site-title h2 {
		font-size: 30px;
	}
.owl-carousel .owl-item img{
    height: auto !important;
    margin: 0 auto 10px;
    
}
.achi-details h3{
  font-size: 18px;
}

  
}
/* Medium devices (tablets, 768px and up)*/
@media only screen and (max-width: 767px) and (min-width: 576px)  {
		.website-area{
    	margin-top: 35px;
	}
	.order-first {
  -webkit-box-ordinal-group: 0;
  -ms-flex-order: -1;
  order: 13;
	}

	.order-last {
	-webkit-box-ordinal-group: 14;
	-ms-flex-order: 13;
	order: -1;
	}
.img-responsive{
		  height:auto !important; 
    }

}
/* Medium devices (tablets, 768px and up)*/
@media only screen and (max-width: 991px) and (min-width: 768px) {
	.achi-details h3 {
    font-size: 20px;
    height: 45px;
  }
}
/* Large devices (desktops, 992px and up)*/
@media only screen and (max-width: 1199px) and (min-width: 992px)  {
	
	.achi-details h3 {
    font-size: 18px;
    
  }
}
/* Extra large devices (large desktops, 1200px and up)*/

@media only screen and (max-width: 1600px) and (min-width: 1200px)  {
	
}
/** Achievements Area END **/
</style>