<template>
    <div>
    <section class="all-service odd offers">
      <div class="container">
          <div class="row justify-content-center items">
              <div v-for="c in commons" :key="c.id" data-aos="fade-up" class="col-12 col-md-6 item">
                  <div class="card">
                     <img v-lazy="url + c.image">
                      <h4>{{c.category}}</h4>
                      <p>{{c.desc.substring(0, 100) + '..'}}</p>
                      <a href @click.prevent="clkCategory(c.menu_categorie_id, 'category')"><i class="btn-icon pulse fas fas fa-arrow-right"></i></a>
                  </div>
              </div>
          </div>
      </div>
  </section>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    props: ['ul'],
    created() {

        let lang = localStorage.getItem('lang')
        if(lang){
            this.getData(lang)
        }else{
            this.getData("English")
        }

        if(this.ul.lang == 'English'){
            this.getData(this.ul.lang)
        }else if(this.ul.lang == 'Arabic'){
            this.getData(this.ul.lang)
        }else if(this.ul.lang == 'French'){
            this.getData(this.ul.lang)
        }else{
            this.getData(this.ul.lang)
        }

        this.$root.$on('english', (e) => {
            this.form.lang = e
            this.getData()
        })

        this.$root.$on('arabic', (e) => {
            this.form.lang = e
            this.getData()
        })

        this.$root.$on('french', (e) => {
           this.form.lang = e
            this.getData()
        })
        
    },
    data() {
        return {
            url: this.ul.url,
            form: {
                lang: this.ul.lang
            },
            final: {
                one: '',
                two: '',
                three: '',
                four: '',
                five: '',
                six: '',
                seven: '',
                eight: ''
            },
            dummies: [],
            commons: [],
            dataC: {
                category: '',
                id: '',
                name: ''
            }
           
        }
    },
    methods: {
        getData(){
        this.dummies = []
        this.commons = []
        axios.get(this.url + 'api/getMainCategory', {params: this.form})
          .then(({data}) => {
              this.dummies = data
              let count = 0
              this.dummies.forEach((item) => {
                  if(item.id != count){
                      this.commons.push(item)
                      count = item.id
                  }
              })
          })
        },
       
        clkCategory(id, category){
            this.dataC.id = id
            this.dataC.category = category
            this.$root.$emit('business', this.dataC)
        }
        
    }
}
</script>

<style scoped>
    /** all businees Area  Start **/
    .img-responsive{
        display:block; 
		height:300px !important; 
		width:100% !important;
		margin:0 auto 30px;
    }
/* Nomalise*/
img {
    max-width: 100%;
}
.img-cat {
    overflow: hidden;
}
.no-gutters {
	padding: 0;
}
.site-title {
	text-align: center;
	margin-bottom: 60px;
}
.site-title h4 {
	font-size: 18px;
	position: relative;
	color: #0070ba;
	display:inline-block;
}
.site-title h2 {
	font-size: 48px;
	color: #222;	
}
.site-title h4::before {
	position: absolute;
	width: 63px;
	height: 2px;
	background: #0070ba;
	top: 10px;
	left: 110%;
	content: "";
}
.all-service.odd.offers {
	background: #eef4ed;
	padding: 100px 0px;
}
.card h4 {
	color: #222;
    font-family: 'Rubik';
}
.card-columns {
    margin: 0 15px;
    column-gap: 30px;
}

.card-columns .card {
    margin-bottom: 30px;
}

.card {
    position: relative;
    z-index: 1;
    width: 100%;
    padding: 45px;
	border-radius: 10px;
	
    border: none;
    background: #fff;
    -webkit-transition: all .4s ease-out 0s;
    -o-transition: all .4s ease-out 0s;
    -moz-transition: all .4s ease-out 0s;
	transition: all .4s ease-out 0s;
	margin-bottom: 30px;
    height: 300px;
}
.card p{
	color: #777;
    font-family: 'Rubik';
}
.card a {
	position: absolute;
	right: 20px;
	top: 20px;
	font-size: 24px;
	color: #003087;
}
.card.no-hover {
    background-color: transparent;
    box-shadow: none;
}

.card p {
    margin: 0 0 10px;
    white-space: pre-wrap;
    -webkit-transition: all .4s ease-out 0s;
    -o-transition: all .4s ease-out 0s;
    -moz-transition: all .4s ease-out 0s;
    transition: all .4s ease-out 0s;
}

.card-img-top {
    border-radius: 0;
}

.card-body {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    min-height: 1px;
    padding: 35px 30px;
}

.card-body h3, .card-body h4, .card-body h5, .card-body h6 {
    margin-top: 0;
}

.card-footer {
    border-radius: 0;
    background: none;
    padding: 30px 0;
    margin: 0 30px;
    border-top: 1px solid var(--primary-l-color);
}

.card-footer a {
    margin: 0 10px;
}

.card-footer a:not(.btn) {
    line-height: 1.2;
    text-align: left;
    color: var(--primary-p-color);
}

.card-footer i {
    margin: 0 10px 2.5px 0;
}

.card.simple {
    box-shadow: none;
    background: none;
    padding: 0 2rem;
}

.card.simple:hover {
    background: none;
}

.card:not(.no-hover):hover {
    transform: translateY(-7.5px);
}
.all-service.odd.offers {
	background: #eef4ed;
}
.card > img {
	width: 50px;
	height: 50px;
	margin-bottom: 30px;
}
@media (min-width: 576px) { 
	
}
/* Medium devices (tablets, 768px and up)*/
@media only screen and (max-width: 575px) and (min-width: 320px)  {
	.site-title h2 {
		font-size: 36px;
	}
    .order-first {
  -webkit-box-ordinal-group: 0;
  -ms-flex-order: -1;
  order: 13;
}

.order-last {
  -webkit-box-ordinal-group: 14;
  -ms-flex-order: 13;
  order: -1;
}
.card{
    height: 320px;
}

}
/* Medium devices (tablets, 768px and up)*/
@media only screen and (max-width: 767px) and (min-width: 576px)  {
	
}
/* Medium devices (tablets, 768px and up)*/
@media only screen and (max-width: 991px) and (min-width: 768px)  {
	
}
/* Large devices (desktops, 992px and up)*/
@media only screen and (max-width: 1199px) and (min-width: 992px)  {
	
	
}
/* Extra large devices (large desktops, 1200px and up)*/

@media only screen and (max-width: 1600px) and (min-width: 1200px)  {
	
}
/** Achievements Area END **/
</style>