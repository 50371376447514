<template>
<div>
  <!-- about-section -->
        <section class="about-section">
          <div class="auto-container">
              <div class="inner-container clearfix">
                  <div class="row clearfix">
                      <div class="col-lg-6 col-md-6 col-sm-12 video-column">
                          <div id="video_block_1" class="video-inner">
                             
                              <div class="video-inner" :style="`background-image: url(${url + image_1})`">
                                  <a @click.prevent="showModal=true" href class="lightbox-image">
                                    <i class="fas fa-play"></i>
                                  </a>
                              </div>
                            </div>
                      </div>
                      <div class="col-lg-6 col-md-6 col-sm-12 content-column">
                          <div id="content_block_1">
                            <!-- arabiclan  -->
                              <div class="content-box ">
                                  <div class="sec-title">
                                      <span><!--{{ abouts.heading }}--></span>
                                      <h2>{{ abouts.title }}</h2>
                                  </div>
                                  <div class="text">
                                      <p><i class="fas fa-quote-left"></i> {{ abouts.desc_1 }} <i class="fas fa-quote-right"></i></p>
                                  </div>
                                  
                                  <div class="btn-box">
                                      <a href class="theme-btn" data-toggle="modal" data-target="#exampleModalLong">
                                        {{ abouts.view_more}}<i class="fas fa-long-arrow-alt-right">
                                        </i></a>
                                  </div>
                                  
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>


        
          <div class="begal-logo">
            <div class="container">
              <carousel v-if="home_images.length > 0" :autoplay="true" :nav="false" :items="5" :responsive="{0:{items:2,nav:false},600:{items:5}}">
                <div v-for="img in home_images" :key="img.id">
                   <a @click.prevent="imageLink(img.link)"><img class="img-responsive" v-lazy="url + img.image" alt="dd" ></a>
                </div>
              </carousel> 
            </div> 
          </div>
         
        
        </section>
        <!-- about-section end -->

          <!-- Modal -->
          <div class="modal fade" id="exampleModalLong" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">{{ abouts.title }}</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <img v-lazy="url + image_2">
                  <p>
                  “{{ abouts.desc_2 }}”</p>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                  
                </div>
              </div>
            </div>
          </div>
      


        <Modal v-model="showModal">
          <iframe v-if="showModal == true" class="embed-responsive-item" :src=" utvUrl + '?autoplay=1&showinfo=0&modestbranding=1&rel=0&mute=0'" allowfullscreen="allowfullscreen" width=600px height=330px  allowscriptaccess="always"  allow="autoplay"></iframe>
        </Modal> 
</div>

</template>

<script>
import VueModal from '@kouts/vue-modal'
import '@kouts/vue-modal/dist/vue-modal.css'
import carousel from 'vue-owl-carousel'

export default {
  props: ['ul'],
  components: {
    'Modal': VueModal,
    carousel
  },
  created(){
    this.getBengalLogo()

    if(this.ul.lang == 'English'){
      this.getBengalLogo()
      this.commonWork()
    }else if(this.ul.lang == 'Arabic'){
      this.getBengalLogo()
      this.commonWork()
    }else if(this.ul.lang == 'French'){
      this.getBengalLogo()
      this.commonWork()
    }else{
      this.getData()
    }

    this.$root.$on('english', (e) => {
       //Style For new English Language 10-28-2021
       //this.arabiclan = 'content-box english-lanStyle'
        this.form.lang = e
        this.commonWork()
    })

    this.$root.$on('french', (e) => {
        //Style For new English Language 10-28-2021
        //this.arabiclan = 'content-box french-lanStyle'
        this.form.lang = e
        this.commonWork()
    })

    this.$root.$on('arabic', (e) => {
        //Style For new Arabic Language 10-28-2021
        //this.arabiclan = 'content-box arabic-lanStyle'
        this.form.lang = e
        this.commonWork()
        
    })

  },
  data() {
    return {
      showModal: false,
      url: this.ul.url,
      abouts: {},
      utvUrl: '',
      counter: {},
      image_1: '',
      image_2: '',
      currentRoute: window.location.href,
      form: {
          lang: this.ul.lang
      },
      home_images: [],
     // arabiclan:'content-box'
    };
  },
  methods: {
    getData(){
      this.form.lang = 'English'
      this.commonWork()
    },
    commonWork(){
      if(this.form.lang == 'English'){
        this.$http.get(this.url + 'api/getMessage', {params: this.form})
          .then(({data}) => {
              this.abouts = data
              this.image_1 = this.abouts.image_1
              this.image_2 = this.abouts.image_2
              this.utvUrl = this.abouts.link
          })
      }else{
         this.$http.get(this.url + 'api/getMessage', {params: this.form})
          .then(({data}) => {
              this.abouts = data.about
              this.image_1 = data.c_short
              this.image_2 = data.c_long
              this.utvUrl = this.abouts.link
          })
      }
    },
    getBengalLogo(){
         this.$http.get(this.url + 'api/getBengalLogo')
            .then(({data}) => {
              this.home_images = data
            })
      },
    imageLink(link){
      window.open(link, "_blank");
    },
  }
};
</script>

<style scoped>
.vm{
  max-width: 630px;
  height: 500px;
}

img {
    max-width: 100%;
}
  button.btn.btn-secondary {
    background: #0070ba;
    border: none;
    font-family: 'Rubik';
}
#exampleModalLong .modal-body{
    position: relative;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    font-size: 18px;
}
#exampleModalLong .modal-content {
    padding: 30px;
}
.modal-header {
    padding-left: 0;
}

  .img-responsive{
    display:block; 
    height: auto!important;
    margin: 0 auto 30px;
    max-width: 100% !important;
    filter: grayscale(1);
    transition: .5s;
    cursor: pointer;
    }

    
.img-responsive:hover{
	filter: grayscale(0);
}
body {
  margin:2rem;
  }
.modal-dialog {
      max-width: 800px;
      margin: 30px auto;
  }

.modal-body p {
    margin-top: 30px;
}

.modal-body {
  position:relative;
  padding:0px;
}
.close {
  position:absolute;
  right:-30px;
  top:0;
  z-index:999;
  font-size:2rem;
  font-weight: normal;
  color:#fff;
  opacity:1;
}

/** Abut Us Area  Start **/

/** about-section **/
/* .content-box.arabic-lanStyle{
  text-align: right;
}
.content-box.english-lanStyle, .content-box.french-lanStyle{
  text-align: left;
} */
.about-section{
	position: relative;
	/* height: 100vh; */
  padding-top: 120px;
  }
  
  #video_block_1 .video-inner{
	position: relative;
	width: 100%;
	padding: 260px 0px;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
  text-align: center;
  color: #2962FF;
  }
  #video_block_1 .video-inner a {
	position: relative;
	display: inline-block;
	font-size: 24px;
	width: 54px;
	height: 54px;
	line-height: 54px;
	background: rgba(255,255,255,0.95);
	text-align: center;
	border-radius: 50%;
  z-index: 2;
}
  
  #video_block_1 .video-inner a:after, 
  #video_block_1 .video-inner a:before {
	width: 70px;
	height: 70px;
	border-radius: 50%;
	background: transparent;
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	-webkit-animation-delay: .9s;
	animation-delay: .9s;
	content: "";
	position: absolute;
	-webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
	box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
	-webkit-animation: ripple 3s infinite;
	animation: ripple 3s infinite;
	-webkit-transition: all .4s ease;
	transition: all .4s ease;
  }
  
  #video_block_1 .video-inner a:after {
	-webkit-animation-delay: .6s;
	animation-delay: .6s;
  }
  
  @-webkit-keyframes ripple {
	70% {
	  -webkit-box-shadow: 0 0 0 40px rgba(255, 255, 255, 0);
			  box-shadow: 0 0 0 40px rgba(255, 255, 255, 0);
	}
	100% {
	  -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
			  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
	}
  }
  @keyframes ripple {
	70% {
	  -webkit-box-shadow: 0 0 0 40px rgba(255, 255, 255, 0);
			  box-shadow: 0 0 0 40px rgba(255, 255, 255, 0);
	}
	100% {
	  -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
			  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
	}
  }
  
  .about-section .inner-container{
	position: relative;
	background: #fff;
	margin-top: -70px;
	box-shadow: 0 0 30px rgba(0,0,0,.08);
	z-index: 1;
  }
  
  #content_block_1 .content-box{
	position: relative;
	padding: 62px 110px 70px 50px;
  }
  
  #content_block_1 .content-box .sec-title{
	margin-bottom: 21px;
  }
  
  #content_block_1 .content-box .text{
	margin-bottom: 25px;
  }
  
  #content_block_1 .content-box .list li{
	position: relative;
	display: block;
	font-size: 16px;
	font-family: 'Heebo', sans-serif;
	font-weight: 700;
	color: #282932;
	margin-bottom: 10px;
	padding-left: 30px;
  }
  
  #content_block_1 .content-box .list li:last-child{
	margin-bottom: 0px;
  }
  
  #content_block_1 .content-box .list li:before{
	position: absolute;
	content: '\f058';
	font-size: 18px;
	font-family: 'Font Awesome 5 Free';
	font-weight: 400;
	left: 0px;
	top: 0px;
  }
  
  #content_block_1 .content-box .list{
	position: relative;
	margin-bottom: 29px;
  }
  
  #content_block_1 .content-box .theme-btn{
	padding: 17px 24px;
  }
  .sec-title span {
	position: relative;
	display: block;
	font-size: 16px;
	line-height: 26px;
	font-family: 'Heebo', sans-serif;
	font-weight: 800;
	margin-bottom: 13px;
}
.sec-title h2 {
	position: relative;
	font-size: 56px;
	line-height: 57px;
	color: #353535;
	font-weight: 800;
	padding-bottom: 0px;
  font-family: 'Rubik';
}
.sec-title span {
	color: #0070ba;
}
#content_block_1 .content-box .text {
	margin-bottom: 25px;
}
p {
	position: relative;
	font-size: 16px;
	line-height: 30px;
	font-family: 'Rubik', sans-serif;
	color: #565872;
	margin: 0px;
	transition: all 500ms ease;
}
.theme-btn {
	position: relative;
	display: inline-block;
	font-size: 15px;
	line-height: 26px;
	font-weight: 700;
	font-family: 'Rubik', sans-serif;
	color: #fff !important;
	text-align: center;
	cursor: pointer;
	border-radius: 5px;
	padding: 17px 30px;
	z-index: 1;
	transition: all 500ms ease;
	background: #0070ba;
}
.theme-btn i, .svg-inline--fa.fa-w-14 {
	position: relative;
	top: 2px;
	font-size: 20px;
	margin-left: 40px;
}
.svg-inline--fa.fa-play.fa-w-14 {
    margin-left: 0;
    top: 0;
}
.auto-container {
	position: static;
	max-width: 1200px;
	padding: 0px 15px;
	margin: 0 auto;
}
.begal-logo {
	background: #fbfbfb;
	padding: 63px 0;
  margin-top: 100px;
 
}
/* Extra small devices (portrait phones, less than 576px)*/
/* No media query since this is the default in Bootstrap*/

/* Small devices (landscape phones, 576px and up)*/
@media only screen and (max-width: 480px) and (min-width: 320px)  { 
	
  .begal-logo {
    margin-top: 70px;
  }
  .sec-title h2{
    font-size: 30px;
    line-height: 30px;
    font-family: 'Rubik';
  }
  .about-section{
    position: relative;
    height: inherit;
    
  }
  #video_block_1 .video-inner{    
    padding: 160px 0px;
    
  }
  #content_block_1 .content-box {
    padding: 62px 10px 21px 10px;
  }
  .singleabout img{
    max-width: 100%;
  }
  .close{
    
    right: 0;
    
  }
  
}
@media only screen and (max-width: 767px) and (min-width: 481px)  {
	section.about-section {
        height: inherit !important;
    }
    #video_block_1 .video-inner{
      padding: 310px 0px;
    }
    
  
}
/* Medium devices (tablets, 768px and up)*/
@media only screen and (max-width: 991px) and (min-width: 768px)  {
  section.about-section {
        height: inherit !important;
    }
	.sec-title h2 {
		font-size: 30px;
		line-height: 30px;
	}
  .single_counter {
		margin-right: 25px;
	}
  #content_block_1 .content-box {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
   
 
}
/* Large devices (desktops, 992px and up)*/
@media only screen and (max-width: 1199px) and (min-width: 992px)  {
	.sec-title h2{
    font-size: 40px;  
  }
  .single_counter h3{
	  font-size: 30px;
  }
  .single_counter i{
	  font-size: 28px;
  }
}
/* Extra large devices (large desktops, 1200px and up)*/

@media only screen and (max-width: 1600px) and (min-width: 1200px)  {
  .single_counter h3{
	  font-size: 26px;
  }
  .single_counter i {
    font-size: 25px;
    
  }
	
}
/** Abut Us Area  End **/

</style>