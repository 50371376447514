<template>
    <div>
        <section class="blog-area">
          <div class="container">
			<div class="row">
            <div class="col-md-12">
              <div class="site-title ">
                <h4 class="sub-title">
                  {{titleArea.heading}}
                </h4>
                <h2>{{titleArea.title}}</h2>
              </div>
            </div>
          </div>
            <div class="row">
              <div class="col-md-5 col-sm-6 col-12">
                <div v-for="b in news" :key="b.id" class="bengal-blog bm">
                  <div class="blog-img">
                      <img :src="url + b.image">
                      <div class="blog-details">
						  <a href @click.prevent="clkBlog(b.id, b.lang)"><h4>{{ b.title}}</h4></a>
                        <!-- <router-link :to="{name: 'news_details', params:{id: b.id}}" href class=""><h4>{{ b.title}}</h4></router-link> -->
                      </div>
                  </div>
                  
                </div>
               
              </div>
              <div class="col-md-7 col-sm-6 col-12">
                <div id="video_block_1">
                  <div class="video-inner" :style="`background-image: url(${url + utvLink.image})`">
                      <a @click.prevent="showModal=true" href class="lightbox-image">
                        <i class="fas fa-play"></i>
                      </a>
					  <div class="corpo-video-title">
                        <h4 v-if="enableEnglish == true">Corporate video</h4>
						<h4 v-if="enableArabic == true">فيديوهات الشركات</h4>
						<h4 v-if="enableFrench == true">Vidéos d'entreprise</h4>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
		 <Modal v-model="showModal">
          <iframe v-if="showModal == true" class="embed-responsive-item" :src=" url1 + '?autoplay=1&showinfo=0&modestbranding=1&rel=0&mute=0'" width=600px height=330px allowfullscreen  allowscriptaccess="always"  allow="autoplay"></iframe>
        </Modal>  
    </div>
</template>

<script>
import axios from 'axios'
import VueModal from '@kouts/vue-modal'
import '@kouts/vue-modal/dist/vue-modal.css'
  export default {
	components: {'Modal': VueModal, },
    props: ['ul'],
    created() {

	if(this.ul.lang == 'English'){
		this.commonWork()
		this.enableEnglish = true
		this.enableArabic = false
		this.enableFrench = false
	}else if(this.ul.lang == 'Arabic'){
		this.enableEnglish = false
		this.enableArabic = true
		this.enableFrench = false
		this.commonWork()
	}else if(this.ul.lang == 'French'){
		this.enableEnglish = false
		this.enableArabic = false
		this.enableFrench = true
		this.commonWork()
	}else{
		this.getData()
	}
	this.$root.$on('english', (e) => {
	this.form.lang = e
	this.commonWork()
	this.enableEnglish = true
	this.enableArabic = false
	this.enableFrench = false
	})

      this.$root.$on('french', (e) => {
        this.form.lang = e
        this.commonWork()
		this.enableEnglish = false
		this.enableArabic = false
		this.enableFrench = true
      })

      this.$root.$on('arabic', (e) => {
        this.form.lang = e
        this.commonWork()
		this.enableEnglish = false
		this.enableArabic = true
		this.enableFrench = false
      })

    },
    data () {
      return {
		showModal: false,
        enableEnglish: true,
		enableArabic: false,
		enableFrench: false,
        url1: '',
		url: this.ul.url,
        results: [],
		page: 1,
		news: [],
		popular: [],
		titleArea: {},
		utvLink: {},
		infos: {},
		form: {
			lang: this.ul.lang
		},
		dataC: {
			category: '',
			id: '',
			name: 'news',
			lang: ''
		}
      }
    },
    methods: {
    getData(){
        this.form.lang = 'English'
        this.commonWork()
    },
    commonWork(){
        this.results = []
        this.news = []
        axios.get(this.url + 'api/getNews', {params: this.form})
		.then(({data}) => {
			this.results = data.news
            this.results.forEach((value, index) => {
                if(index == 0 || index == 1){
                    this.news.push(value)
                }
            })
			this.titleArea = data.title
            this.utvLink = data.link
			this.url1 = this.utvLink.link
		})
    },
	getTitle(){
        axios.post(this.url + 'api/getTitle', this.data)
            .then(({data}) => {
              this.titleArea = data
            })
      },
	  getLink(){
        axios.get(this.url + 'api/getVideoLink')
            .then(({data}) => {
              this.utvLink = data.corporate
			  //alert(this.utvLink)
            })
      },
	clkBlog(id, lang){
		if(lang == 'English'){
			this.dataC.category = 'menu'
			this.dataC.id = id
			this.dataC.lang = lang
			this.$root.$emit('home', this.dataC)
		}else if(lang == 'Arabic'){
			this.dataC.category = 'menu'
			this.dataC.id = id
			this.dataC.lang = lang
			this.$root.$emit('home', this.dataC)
		}else{
			this.dataC.category = 'menu'
			this.dataC.id = id
			this.dataC.lang = lang
			this.$root.$emit('home', this.dataC)
		}
	},
	
    }
  }
</script>


<style scoped>
img {
    max-width: 100%;
}
    /** Blog Area  Start **/
	.site-title {
	text-align: center;
	margin-bottom: 60px;
	}
.site-title h4 {
	font-size: 18px;
	position: relative;
	color: #0070ba;
	display:inline-block;
  font-family: 'Rubik';
}
.site-title h2 {
	font-size: 48px;
	color: #222;
  font-family: 'Rubik';
  font-weight: 700;
  margin-top: 0;
}
.site-title h4::before {
	position: absolute;
	width: 63px;
	height: 2px;
	background: #0070ba;
	top: 10px;
	left: 110%;
	content: "";
}
.site-title h4::after {
    position: absolute;
    width: 63px;
    height: 2px;
    background: #0070ba;
    top: 10px;
    content: "";
    left: -76px;
}


    .blog-area{
        margin-top:20px;
    }
#video_block_1 .video-inner{
	position: relative;
	width: 100%;
	padding: 262px 0px !important;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	text-align: center;
  }
  #video_block_1 .video-inner a {
	position: relative;
	display: inline-block;
	font-size: 24px;
	width: 54px;
	height: 54px;
	line-height: 54px;
	background: rgba(255,255,255,0.95);
	text-align: center;
	border-radius: 50%;
}
  
  #video_block_1 .video-inner a:after, 
  #video_block_1 .video-inner a:before {
	width: 70px;
	height: 70px;
	border-radius: 50%;
	background: transparent;
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	-webkit-animation-delay: .9s;
	animation-delay: .9s;
	content: "";
	position: absolute;
	-webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
	box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
	-webkit-animation: ripple 3s infinite;
	animation: ripple 3s infinite;
	-webkit-transition: all .4s ease;
	transition: all .4s ease;
  }
  
  #video_block_1 .video-inner a:after {
	-webkit-animation-delay: .6s;
	animation-delay: .6s;
  }
  
  @-webkit-keyframes ripple {
	70% {
	  -webkit-box-shadow: 0 0 0 40px rgba(255, 255, 255, 0);
			  box-shadow: 0 0 0 40px rgba(255, 255, 255, 0);
	}
	100% {
	  -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
			  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
	}
  }
  @keyframes ripple {
	70% {
	  -webkit-box-shadow: 0 0 0 40px rgba(255, 255, 255, 0);
			  box-shadow: 0 0 0 40px rgba(255, 255, 255, 0);
	}
	100% {
	  -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
			  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
	}
  }
  .bm{
	  margin-bottom: 30px;
  }

	.blog-area{
    	margin-top: 100px;
    	margin-bottom: 100px;
	}

  .blog-details {
	position: absolute;
	width: 80%;
	height: 100%;
	background: rgba(0,0,0, .38);
	top: 100%;
	opacity: 0;
	transition: .5s;
	left: calc(50% - 180px);
	}
  .blog-details h4 {
	color: #fff;
	font-size: 18px;
	text-align: center;
	padding-top: 12px;
	font-family: 'Rubik';
	}
	.blog-img{
		transition: 5s;
		position: relative;
		overflow: hidden;
	}
	.blog-img:hover .blog-details {
		top: 76.8%;
		opacity: 1;
		
		/* width: 79%; */
		/* left: calc(50% - 180px); */
	}
	.corpo-video-title {
		position: absolute;
		left: 31px;
		bottom: 40px;
	}
	.corpo-video-title h4{
		color: #fff;
		font-family: 'Rubik';
		
	}

	
/* Extra small devices (portrait phones, less than 576px)*/
/* No media query since this is the default in Bootstrap*/

/* Medium devices (tablets, 768px and up)*/

@media only screen and (max-width: 576px) and (min-width: 320px)  { 
#video_block_1 .video-inner{
    padding: 120px 0px !important;
  }
  .site-title h2{
    font-size: 30px;
  }
  .blog-img:hover .blog-details{
    top: 40.8%;
    width: 100% !important;
    left: 0 !important;
	}
}
@media only screen and (max-width: 767px) and (min-width: 576px)  { 
	#video_block_1 {
		margin-top: 0;

	}
	
	.blog-details {
		left: calc(50% - 164px);
	}
	.blog-details h4 {
		padding-top: 8px;
	}
	.blog-img:hover .blog-details {
		top: 85.8%;
	}
	
	.footer-bottom.d-flex{
		display: block;
	}
	.site-title h2{
    	font-size: 30px;
	}
	
	#video_block_1 .video-inner{
    padding: 135px 0px !important;
	}
	.blog-img:hover .blog-details{
    top: 20.8%;
    opacity: 1;
    /* width: 79%; */
    left: calc(50% - 100px);
	}
}

/* Medium devices (tablets, 768px and up)*/
@media only screen and (max-width: 991px) and (min-width: 768px)  {
	.blog-img:hover .blog-details {
		top: 60.8%;
		
	}
	.blog-details {
		left: calc(50% - 96px);
	}
	#video_block_1 .video-inner{
    	padding: 187px 0px !important;
	}
	.blog-img:hover .blog-details{
    top: 40.8%;
    width: 100% !important;
    left: 0 !important;
	}
}
/* Large devices (desktops, 992px and up)*/
@media only screen and (max-width: 1199px) and (min-width: 992px)  {
	
	.blog-details {
		left: calc(50% - 150px);
	}
	#video_block_1 .video-inner{
    	padding: 212px 0px !important;
	}
	.blog-img:hover .blog-details{
    top: 40.8%;
    width: 100% !important;
    left: 0 !important;
	}
}
/* Extra large devices (large desktops, 1200px and up)*/

@media only screen and (max-width: 1600px) and (min-width: 1200px)  {
	
	#video_block_1 .video-inner{
    	padding: 256px 0px;
	}
	.blog-img:hover .blog-details {
    	top: 69.8%;
    	left: calc(50% - 157px);
	}
}

/** Blog Area END **/
</style>