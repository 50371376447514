<template>
    <div>
        <!--<page-loader></page-loader>-->
        <div class="contact-block-section section-padding">
          <div class="container">
            <div class="row align-items-center no-gutters">
              <div class="col-md-4">
                <div class="contact-block">
                  <div class="con-icon">
                    <i class="flaticon-call"></i>
                  </div>
                  <div class="con-details">
                    {{ contact.num_1 }}<br>
                    {{ contact.num_2 }}
                  </div>
                  <h4 class="con-title">{{ contact.phone }}</h4>
                </div>
              </div>
              <div class="col-md-4">
                <div class="contact-block contact-highlight" style="background-image: url('img/contact-hb.jpg');">
                  <div class="con-icon">
                    <i class="flaticon-open"></i>
                  </div>
                  <div class="con-details">
                    {{ contact.email_1 }} <br>
                    <!-- {{ contact.email_2 }} -->
                  </div>
                  <!-- <h4 class="con-title">{{ contact.txt }}</h4> -->
                  <!-- <div class="con-btn">
                    <a href="#">Submit Request</a>
                  </div> -->
                </div>
              </div>
              <div class="col-md-4">
                <div class="contact-block">
                  <div class="con-icon">
                    <i class="flaticon-location"></i>
                  </div>
                  <div class="con-details">
                    <!-- Bengal House<br> 75 Gulshan Avenue<br> Gulshan 1, Dhaka 1212, Bangladesh -->
                    <!-- {{ contact.addrs.substring(0,12) }}<br>{{ contact.addrs.substring(12,30) }}<br> Gulshan 1, Dhaka 1212, Bangladesh -->
                    {{ contact.addrs }}
                  </div>
                  <h4 class="con-title">{{ contact.catch }}</h4>
                </div>
              </div>
            </div>
          </div>
        </div> 
    </div>
</template>

<script>
import axios from 'axios'
export default {
    props: ['ul'],
    created() {

        if(this.ul.lang == 'English'){
            this.commonWork()
        }else if(this.ul.lang == 'Arabic'){
            this.commonWork()
        }else if(this.ul.lang == 'French'){
            this.commonWork()
        }else{
            this.getData()
        }

        this.$root.$on('english', (e) => {
            this.form.lang = e
            this.commonWork()
        })

        this.$root.$on('french', (e) => {
            this.form.lang = e
            this.commonWork()
        })

        this.$root.$on('arabic', (e) => {
            this.form.lang = e
            this.commonWork()
        })
    },
    data(){
        return {
            url: this.ul.url,
            form: {
                lang: this.ul.lang
            },
            contact: {}
        }
    },
    methods: {
        getData(){
            this.form.lang = 'English'
            this.commonWork()
        },
        commonWork(){
           axios.get(this.url + 'api/getContact', {params: this.form})
                .then(({data}) => {
                    this.contact = data
                })
        }
       
    }
}
</script>

<style scoped>

/** contact page Start **/
.no-gutters {
	padding: 0;
}
.site-title {
	text-align: center;
	margin-bottom: 60px;
}
.site-title h4 {
	font-size: 18px;
	position: relative;
	color: #0070ba;
	display:inline-block;
}
.site-title h2 {
	font-size: 48px;
	color: #222;	
}
.achi-details {
	padding-left: 20px;
}
.achi-details a {
	color: #0e75bc;
	font-weight: bold;
	font-size: 16px;
	display: block;
}

.site-title h4::before {
	position: absolute;
	width: 63px;
	height: 2px;
	background: #0070ba;
	top: 10px;
	left: 110%;
	content: "";
}
/* 
::::::::::::::::::::::
32. Contact styles
::::::::::::::::::::::
*/
.contact-block {
	background: #f8f8f8;
	padding: 40px;
	text-align: center;
	z-index: 2;
	position: relative;
  }
  
  .con-icon {
	font-size: 80px;
	line-height: 1;
  }
  
  .con-details {
	margin: 28px auto 35px;
	font-size: 16px;
	
	font-weight: 700;
  font-family: 'Rubik';
  }
  
  .con-title {
	font-size: 24px;
	line-height: 1;
	font-weight: 700;
	font-family: 'Rubik';
	letter-spacing: -0.72px;
	margin-bottom: 0;
  font-family: 'Rubik';
  }
  
  .contact-highlight {
	position: relative;
	color: #fff;
	z-index: 1;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
  }
  
  .contact-highlight::after {
	content: '';
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	z-index: -1;
	background: #002e5b;
	opacity: 0.9;
  }
  
  .con-btn {
	margin-top: 30px;
  }
  
  .con-btn a {
	border: 2px solid #2b5175;
	font-size: 16px;
	font-weight: 700;
	line-height: 1;
	display: inline-block;
	color: #fff;
	padding: 20px 40px;
  }
  
  .contact-highlight .con-icon {
	color: #fce328;
  }
  .contact-block-section {
	padding: 100px 0;
}
@media (min-width: 576px) { 
	
}
/* Medium devices (tablets, 768px and up)*/
@media only screen and (max-width: 575px) and (min-width: 320px)  {
	.site-title h2 {
		font-size: 36px;
	}
}
/* Medium devices (tablets, 768px and up)*/
@media only screen and (max-width: 767px) and (min-width: 576px)  {
	
}
/* Medium devices (tablets, 768px and up)*/
@media only screen and (max-width: 991px) and (min-width: 768px)  {
  .contact-block-section{
    padding: 0 0;
  }
	.contact-block{
    padding:40px
  }
}
/* Large devices (desktops, 992px and up)*/
@media only screen and (max-width: 1199px) and (min-width: 992px)  {
	
	
}
/* Extra large devices (large desktops, 1200px and up)*/

@media only screen and (max-width: 1600px) and (min-width: 1200px)  {
	
}
</style>