<template>
  <div>
  <!-- ====START PROMOT==== -->
  <section class="counter-vice-quote">
    <div class="container">
      <div class="row">
          <div class="col-sm-12 align-items-center">
              <div class="aboutbox">
                  <div v-if="thangCounter == true" class="counter_area ">
                      <div v-for="d in details" :key="d.id" class="single_counter">
                        <i :class="d.icon"></i>
                          <h3 class="counter">
                            <countTo :startVal='startVal' :endVal='parseInt(d.count, 10)' :duration='10000'></countTo>
                          </h3>
                          <p>{{d.title}}</p>
                      </div>
                      <!-- <div class="single_counter">
                        <i class="fas fa-globe"></i>
                          <h3 class="counter"><countTo :startVal='0' :endVal='60' :duration='20000'></countTo></h3>
                          <p>Export</p>
                      </div>
                      <div class="single_counter">
                        <i class="fas fa-industry"></i>
                          <h3 class="counter"><countTo :startVal='0' :endVal='28' :duration='20000'></countTo></h3>
                          <p>Factories</p>
                      </div>
                      <div class="single_counter">
                        <i class="fas fa-baseball-ball"></i>
                          <h3 class="counter"><countTo :startVal='0' :endVal='26' :duration='20000'></countTo></h3>
                          <p>SBUs</p>
                      </div>
                      <div class="single_counter">
                       <i class="fab fa-digital-ocean"></i>
                          <h3 class="counter"><countTo :startVal='0' :endVal='8' :duration='20000'></countTo></h3>
                          <p>Sectors </p>
                      </div> -->
                  </div>
                  <div v-else class="counter_area ">
                      <div v-for="d in details" :key="d.id" class="single_counter">
                          <i :class="d.icon"></i>
                          <h3 class="counter">{{d.count}}</h3>
                          <p>{{d.title}}</p>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    </div>
    <div class="promot_area">
    
      <div class="container">
        <div class="row">
            <div class="col-md-6 col-sm-12 col-xs-12">
                <div class="promo_text">
                    <h3>{{counter.title}}</h3>
                    <p><i class="fas fa-quote-left"></i> {{ counter.desc }} <i class="fas fa-quote-right"></i></p>
                </div>
            </div>
            
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 video-column">
              <div id="video_block_1">
                  
                  <div class="video-inner" :style="`background-image: url(${url + image})`">
                      <a @click.prevent="showModal=true" href class="lightbox-image"><i class="fas fa-play"></i></a>
                  </div>
              </div>
            </div>
            
        </div>
      </div>
    </div>
    
</section>

<Modal v-model="showModal">
  <iframe v-if="showModal == true" class="embed-responsive-item" :src=" url1 + '?autoplay=1&showinfo=0&modestbranding=1&rel=0&mute=0'" allowfullscreen  allowscriptaccess="always" width=600px height=330px  allow="autoplay"></iframe>
</Modal> 
<!-- ====PROMOT AREA END==== -->
  </div>
</template>

<script>
import VueModal from '@kouts/vue-modal'
import '@kouts/vue-modal/dist/vue-modal.css'
import countTo from 'vue-count-to';
export default {
  components: { countTo, 'Modal': VueModal, },
  props: ['ul'],
  created() {
      //this.getData()

      if(this.ul.lang == 'English'){
        this.thangCounter = true
        this.commonWork()
      }else if(this.ul.lang == 'Arabic'){
        this.thangCounter = false
        this.commonWork()
      }else if(this.ul.lang == 'French'){
        this.thangCounter = false
        this.commonWork()
      }else{
        this.getData()
      }

      this.$root.$on('english', (e) => {
          this.form.lang = e
          this.commonWork()
          this.thangCounter = true
      })

      this.$root.$on('arabic', (e) => {
          this.form.lang = e
          this.commonWork()
          this.thangCounter = false
      })

      this.$root.$on('french', (e) => {
          this.form.lang = e
          this.commonWork()
          this.thangCounter = false
      })

    

  },
  data () {
    return {
      url: this.ul.url,
      showModal: false,
      startVal: 1000,
      endVal: 0,
      url1: '',
      counter: {},
      details: [],
      image: '',
      form: {
          lang: this.ul.lang
      },
      thangCounter: true
    }
  },
  methods: {
   
    getData(){
      this.form.lang = 'English'
      this.commonWork()
    },
    commonWork(){
     if(this.form.lang == 'English'){
        this.$http.get(this.url + 'api/getCounter', {params: this.form})
        .then(({data}) => {
          this.counter = data.counter
          this.details = data.details
          this.image = this.counter.image
          this.url1 = this.counter.link
        })
     }else{
        this.$http.get(this.url + 'api/getCounter', {params: this.form})
        .then(({data}) => {
          this.counter = data.counter
          this.details = data.details
          this.image = data.image
          this.url1 = this.counter.link
        })
     }
    },
   
  }
}
</script>

<style scoped>
img {
  max-width: 100%;
}
  /** Counter-area **/
/* section.counter-vice-quote {
  margin-top: 100px;
} */
.single_counter i {
  color: #0070ba;
  display: inline-block;
 
}
.svg-inline--fa.fa-user.fa-w-14, .svg-inline--fa.fa-w-16 {
  font-size: 26px;
  color: #0070ba;
}
.single_counter h3 {
  display: inline-block;
  font-family: 'Rubik', sans-serif;
  font-size: 36px;
  font-weight: 700;
  padding-left: 20px;
  margin-bottom: 10px;
}

.single_counter > p {
  color: #b9b9b9;
  font-family: 'Rubik', sans-serif;
  font-size: 18px;
  font-weight: 400;
  padding-left: 11px;
  position: relative;
}

.single_counter > p::after {
  background: #0070ba;
  content: "";
  height: 20px;
  left: 0;
  position: absolute;
  top: 5px;
  width: 1px;
}
.single_counter {
  display: inline-block;
  margin-right: 30px;
  width: 17%;
  height: 100px;
}
.promot_area {
  padding: 50px 0 52px;
  position: relative;
  background: #004ea0;
  background-repeat: no-repeat;
  background-attachment: fixed;
  margin-top:30px;
}

.promo_text {
  position: relative;
  z-index: 1;
  color: #fff;
}
.svg-inline--fa.fa-quote-left.fa-w-16, .svg-inline--fa.fa-quote-right.fa-w-16 {
  font-size: 14px;
  color: #fff;
}
.promo_text > h3,
.post_title {
  font-size: 22px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 17px;
  font-family: "Open Sans", sans-serif;
}

.promo_text > p {
  color: #e9e9e9;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
  margin-bottom: 0;
  width: 75%;
}

.promot_area::after {
  background: rgba(2, 27, 39, 0.93);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.promot_area .cl-effect-2 a span {
  margin-top: 10px;
  height: 55px;
  line-height: 55px;
  width: 190px;
}
.promot_area .row {
  position: relative;
  z-index: 4;
}
.aboutbox {
padding-bottom: 120px;
padding-top: 120px;
}

#video_block_1 .video-inner {
position: absolute;
width: 100%;
padding: 153px 0px;
background-repeat: no-repeat;
background-position: center;
text-align: center;
right: 0;
bottom: -52px;
}
#video_block_1 .video-inner a {
position: relative;
display: inline-block;
font-size: 24px;
width: 54px;
height: 54px;
line-height: 54px;
background: rgba(255,255,255,0.95);
text-align: center;
border-radius: 50%;
z-index: 2;
}

#video_block_1 .video-inner a:after, 
#video_block_1 .video-inner a:before {
width: 70px;
height: 70px;
border-radius: 50%;
background: transparent;
position: absolute;
top: 50%;
left: 50%;
-webkit-transform: translate(-50%, -50%);
transform: translate(-50%, -50%);
-webkit-animation-delay: .9s;
animation-delay: .9s;
content: "";
position: absolute;
-webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
-webkit-animation: ripple 3s infinite;
animation: ripple 3s infinite;
-webkit-transition: all .4s ease;
transition: all .4s ease;
}

#video_block_1 .video-inner a:after {
-webkit-animation-delay: .6s;
animation-delay: .6s;
}

@-webkit-keyframes ripple {
70% {
  -webkit-box-shadow: 0 0 0 40px rgba(255, 255, 255, 0);
      box-shadow: 0 0 0 40px rgba(255, 255, 255, 0);
}
100% {
  -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
}
}
@keyframes ripple {
70% {
  -webkit-box-shadow: 0 0 0 40px rgba(255, 255, 255, 0);
      box-shadow: 0 0 0 40px rgba(255, 255, 255, 0);
}
100% {
  -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
}
}


/* Extra small devices (portrait phones, less than 576px)*/
/* No media query since this is the default in Bootstrap*/

/* Small devices (landscape phones, 576px and up)*/
/* Medium devices (tablets, 768px and up)*/
@media only screen and (max-width: 575px) and (min-width: 320px)  {
.svg-inline--fa.fa-w-16{
  font-size: 18px;

}

.svg-inline--fa.fa-user.fa-w-14{
  font-size: 18px;
}
.single_counter > p{
  font-size: 14px;
}
#video_block_1 .video-inner {
  
  width: 50%;
  padding: 140px 0px;
  bottom: -52px;
}
.promo_text > h3, .post_title {
  font-size: 18px;
}
.promo_text {
  width: 90%;
}

.counter-vice-quote {
  padding-top: 5px;
}
.aboutbox{
  padding-bottom: 40px;
}
.counter_area {
  padding-left: 30px;
}
.single_counter{
  width: 44%;
  margin-right: 6px;
}
.single_counter i{
  font-size: 22px;
}
.single_counter h3{
  font-size: 20px;
}

}

@media only screen and (max-width: 767px) and (min-width: 576px)  {
#video_block_1 .video-inner{
  width: 57%;
}
.single_counter {
  margin-right: 6px;
  width: 30%;
}
.single_counter i{
  font-size: 26px;
}
.single_counter h3{
  font-size: 26px;
}

}
/* Medium devices (tablets, 768px and up)*/
@media only screen and (max-width: 991px) and (min-width: 768px)  {
.single_counter {
  margin-right: 6px;
  width: 19%;
}
.single_counter > p{
  font-size: 16px;
}
.single_counter i{
  font-size: 20px;
}
.single_counter h3{
  font-size: 22px;
}
}
/* Large devices (desktops, 992px and up)*/
@media only screen and (max-width: 1199px) and (min-width: 992px)  {

.single_counter {
  margin-right: 6px;
}
.single_counter h3{
  font-size: 28px;
 
}

/* section.counter-vice-quote {
  padding-top: 120px;
} */
}
/* Extra large devices (large desktops, 1200px and up)*/

@media only screen and (max-width: 1600px) and (min-width: 1200px)  {




}
/* Extra large devices (large desktops, 1200px and up)*/

@media only screen and (max-width: 1600px) and (min-width: 1200px)  {
/* section.counter-vice-quote {
  padding-top: 120px;
} */
.single_counter{
  display: inline-block;
  margin-right: 25px;
  width: 17%;
}
.single_counter i{
 font-size: 26px; 
}
.single_counter h3{
  font-size: 28px;
}
}
/** Counter Area  End **/
</style>