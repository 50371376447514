<template>
    <div>
        <!--<page-loader></page-loader>-->
          <div class="news-page section-padding bg-light">
          <div class="container">
            <div class="row justify-content-md-center">
              <div class="col-lg-8">
                <article class="single-post-content">
                  <div class="post-block-tags">
                    <img :src="url + news.image"/>
                    <!-- <ul>
                      <li><a style="background-color:#fde428; color:black" href>{{ news.category }}</a></li>
                    </ul> -->
                  </div>
                  <div class="new-details">
                      <h3 class="post-block-title">{{ news.title }}</h3>
                  <div class="post-block-meta">
                    <div class="d-flex">
                      
                      <div class="post-block-comments">
                        <i class="fal fa-calendar-alt"></i> {{ news.dt }}
                      </div>
                    </div>
                  </div>
                  <div class="post-content">
                    <p>{{ news.desc }}</p>

                    <!-- <div class="" v-for="n in newsDtls" :key="n.id">
                      <h4>{{ n.title_one }}</h4>
                      <div class="col-md-12 mt-xs-30">
                        <p>{{ n.desc_one }}</p>
                      </div>
                    </div> -->
                  </div>
                  </div>
                </article>
              </div>

              <!-- <div class="col-lg-4">
                <div class="finpress-sidebar">
                  <div class="widget-box">
                    <h3 class="widget-title">Popular News</h3>
                    <div class="recent-post-widget">
                      <div v-for="n in popular" :key="n.id" class="recent-post-item">
                        <div class="d-flex">
                          <div class="rp-thumb">
                            <img :src="url + n.image" alt="" class="img-fluid rounded-circle">
                          </div>
                          <div class="rp-details">

                            <h2 class="post-title-sm">
                             <router-link :to="{name: 'news_details', params:{id: n.id}}" href class="">{{ n.title }}</router-link>
                              </h2>
                            <div class="rp-date"><i class="fal fa-calendar-alt"></i> {{ n.dt }}</div>

                          </div>
                        </div>
                      </div>
               
                    </div>
                  </div>
                 
                </div>
              </div> -->


            </div>
          </div>
        </div>
        
    </div>
</template>

<script>
import axios from 'axios'
export default {
    props: ['ul'],
    created() {
        this.getData()

        // this.$root.$on('english', (e) => {
        //     this.form.lang = e
        //     this.commonWork()
        // })

        // this.$root.$on('french', (e) => {
        //     this.form.lang = e
        //     this.commonWork()
        // })

        // this.$root.$on('arabic', (e) => {
        //     this.form.lang = e
        //     this.commonWork()
        // })

      
    },
    data() {
        return {
          url: this.ul.url,
          page: 1,
          news: {},
          newsDtls: [],
          form: {
            lang: '',
            id: this.$route.params.id
          },
          popular:[]
        }
    },
    methods:{
        getData(){
            axios.get(this.url + 'api/getNewsDetails', {params: this.form})
            .then(({data}) => {
                this.news = data
            })
        }
    }
}
</script>

<style scoped>
   /** all businees Area  Start **/

.post-block-tags img {
    max-width: 100%;
}
.new-details {
    padding: 50px;
}
.no-gutters {
	padding: 0;
}
.site-title {
	text-align: center;
	margin-bottom: 60px;
}
.site-title h4 {
	font-size: 18px;
	position: relative;
	color: #0070ba;
	display:inline-block;
}
.site-title h2 {
	font-size: 48px;
	color: #222;	
}
.achi-details {
	padding-left: 20px;
}
.achi-details a {
	color: #0e75bc;
	font-weight: bold;
	font-size: 16px;
	display: block;
}

.site-title h4::before {
	position: absolute;
	width: 63px;
	height: 2px;
	background: #0070ba;
	top: 10px;
	left: 110%;
	content: "";
}
/* 
::::::::::::::::::::::
40. News Page styles
::::::::::::::::::::::
*/

.post-block-item {
	position: relative;
	display: block;
  }
  
  .post-block-item:not(:last-child) {
	margin-bottom: 40px;
  }
  
  .post-block-content {
	background: #fff;
	padding: 50px;
  }
  
  .post-block-tags ul {
	list-style: none;
	padding-left: 0;
	margin-left: 0;
  }
  
  .post-block-tags ul li {
	margin-right: 20px;
  }
  
  .post-block-tags ul li a,
  .post-block-tags ul li a:visited {
	background: #003087;
	color: #fff;
	padding: 10px 30px;
	font-size: 14px;
	font-weight: 700;
  }
  
  .post-block-title {
	font-size: 34px;
	line-height: 46px;
	color: #002e5b;
	letter-spacing: -1.02px;
	margin: 30px auto 20px;
	display: block;
  font-family: 'Rubik';
  }
  .post-content p {
    font-size: 16px;
    font-family: 'Rubik';
    line-height: 30px;
}
.post-content h4 {
    font-family: 'Rubik';
}
h4{
 font-family: 'Rubik'; 
}
  .post-block-title a,
  .post-block-title a:visited {
	color: #002e5b;
  }
  
  .post-block-meta {
	margin-bottom: 30px;
	font-size: 14px;
  }
  
  .post-block-views,
  .post-block-comments {
	margin-right: 30px;
  }
  
  .post-read-more {
	position: relative;
  }
  
  .post-read-more a,
  .post-read-more a:visited {
	color: #002e5b;
	font-weight: 700;
	font-size: 14px;
	position: relative;
	-webkit-transition: all 300ms linear 0ms;
	-khtml-transition: all 300ms linear 0ms;
	-moz-transition: all 300ms linear 0ms;
	-ms-transition: all 300ms linear 0ms;
	-o-transition: all 300ms linear 0ms;
	transition: all 300ms linear 0ms;
  }
  
  .post-read-more a:hover {
	letter-spacing: 1.5px;
  }
  
  .post-read-more a::before {
	content: '\f061';
	font-family: "Font Awesome 5 Pro";
	font-style: normal;
	font-weight: 300;
	right: 140px;
	-webkit-transition: all 300ms linear 0ms;
	-khtml-transition: all 300ms linear 0ms;
	-moz-transition: all 300ms linear 0ms;
	-ms-transition: all 300ms linear 0ms;
	-o-transition: all 300ms linear 0ms;
	transition: all 300ms linear 0ms;
	margin-right: 7px;
  }
  
  .author-avater {
	margin-right: 15px;
  }
  
  .posted-by {
	font-size: 16px;
	font-weight: 700;
	color: #002e5b;
  }
  
  .posted-by a,
  .posted-by a:visited {
	color: #002e5b;
  }
  
  .post-block-footer {
	position: relative;
	display: block;
	margin-top: 40px;
  }
  
  .post-thumbnail {
	position: relative;
  }
  
  .video-post-icon {
	width: 80px;
	height: 80px;
	position: absolute;
	background: #003087;
	color: #002e5b;
	z-index: 2;
	top: 50%;
	left: 50%;
	-webkit-transform: translateX(-50%) translateY(-50%);
	transform: translateX(-50%) translateY(-50%);
	text-align: center;
	line-height: 80px;
  }
  
  .quote-title {
	position: relative;
	padding-left: 100px;
  }
  
  .quote-title::before {
	font-family: Flaticon;
	font-size: 60px;
	font-style: normal;
	margin-left: 20px;
	color: #333;
	content: "\f12d";
	position: absolute;
	left: 0;
	top: 15px;
  }
  
  .post-quote .post-block-content {
	background: #003087;
  }
  
  .page-link {
	padding: 15px;
	color: #001d23;
	font-size: 14px;
	font-weight: 700;
  }
  
  .page-item.active .page-link {
	z-index: 3;
	color: #002e5b;
	background-color: #003087;
	border-color: #003087;
  }
  
  /* sidebar widgets  */
  .finpress-sidebar {
	margin-left: 20px;
  }
  
  .widget-box {
	padding: 40px;
	background: #fff;
	margin-bottom: 40px;
  }
  
  .widget-title {
	font-size: 20px;
	font-weight: 700;
	color: #002e5b;
	position: relative;
	border-left: 2px solid #e6e6e6;
	padding-left: 30px;
	margin-bottom: 40px;
  font-family: 'Rubik';
  }
  
  .author-social-accounts ul {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	padding-left: 0;
	margin-bottom: 0;
	list-style: none;
	-ms-flex-pack: center !important;
	justify-content: center !important;
  }
  
  .author-social-accounts ul li {
	margin: 0px;
  }
  
  .author-social-accounts ul li a,
  .author-social-accounts ul li a:visited {
	color: #b3bed3;
	font-size: 14px;
	width: 30px;
	height: 30px;
	text-align: center;
	display: block;
	line-height: 30px;
  }
  
  .author-social-accounts ul li a:hover {
	background: #003087;
	color: #002e5b;
  }
  
  .author-photo {
	max-width: 140px;
	margin: 0px auto;
  }
  
  .author-details,
  .author-photo {
	text-align: center;
  }
  
  .author-name {
	margin: 30px auto 25px;
  }
  
  .author-social-accounts {
	margin-top: 25px;
  }
  
  .search-input {
	height: 60px;
	background: #f9f9f9;
	border: none;
	padding: 5px 20px;
	max-width: calc(100% - 60px);
	width: 100%;
  }
  
  .search-submit {
	height: 60px;
	width: 60px;
	background: #003087;
	color: #fff;
	border: none;
	font-size: 14px;
	text-align: center;
	float: right;
  }
  
  .rp-thumb {
	max-width: 80px;
	margin-right: 20px;
  }
  
  .post-title-sm {
	font-size: 16px;
	line-height: 22px;
	margin-bottom: 15px;
  font-family: 'Rubik';
  }
  
  .post-title-sm a,
  .post-title-sm a:visited {
	color: #002e5b;
  }
  
  .recent-post-item:not(:last-child) {
	margin-bottom: 60px;
  }
  
  .post-categories ul {
	padding-left: 0;
	margin-bottom: 0;
	list-style: none;
  }
  
  .post-categories ul li {
	width: 100%;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
  }
  
  .post-categories ul li:not(:last-child) {
	margin-bottom: 10px;
  }
  
  .categories-count {
	background: #003087;
	font-size: 14px;
	color: #002e5b;
	font-weight: 700;
	width: 50px;
	height: 50px;
	display: inline-block;
	text-align: center;
	line-height: 50px;
	
  }
  
  .post-categories ul li a {
	width: calc(100% - 50px);
	height: 50px;
	line-height: 50px;
	background: #f9f9f9;
	color: #002e5b;
	padding-left: 30px;
	position: relative;
	z-index: 1;
	font-size: 14px;
	font-weight: 700;
  }
  
  .post-categories ul li a::after {
	content: '';
	position: absolute;
	background: #fde428;
	width: 0px;
	height: 100%;
	left: 0;
	-webkit-transition: all 300ms linear 0ms;
	-khtml-transition: all 300ms linear 0ms;
	-moz-transition: all 300ms linear 0ms;
	-ms-transition: all 300ms linear 0ms;
	-o-transition: all 300ms linear 0ms;
	transition: all 300ms linear 0ms;
	z-index: -1;
  }
  
  .post-categories ul li a:hover::after {
	width: 100%;
  }
  
  .social-widget ul {
	padding-left: 0;
	margin-bottom: 0;
	list-style: none;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
  }
  
  .social-widget ul li {
	margin: 0px 5px;
  }
  
  .social-widget ul li a,
  .social-widget ul li a:visited {
	display: block;
	background: #f9f9f9;
	color: #002e5b;
	width: 40px;
	height: 40px;
	text-align: center;
	line-height: 40px;
	-webkit-transition: all 300ms linear 0ms;
	-khtml-transition: all 300ms linear 0ms;
	-moz-transition: all 300ms linear 0ms;
	-ms-transition: all 300ms linear 0ms;
	-o-transition: all 300ms linear 0ms;
	transition: all 300ms linear 0ms;
  }
  
  .social-widget ul li a:hover {
	background: #003087;
  }
  
  .fp-tags-widget ul {
	padding-left: 0;
	margin-bottom: 0;
	list-style: none;
  }
  
  .fp-tags-widget ul li {
	display: inline-block;
	margin: 5px;
  }
  
  .fp-tags-widget ul li a,
  .fp-tags-widget ul li a:visited {
	background: #f9f9f9;
	color: #002e5b;
	font-size: 12px;
	font-weight: 700;
	display: block;
	padding: 10px 20px;
	text-transform: uppercase;
	-webkit-transition: all 300ms linear 0ms;
	-khtml-transition: all 300ms linear 0ms;
	-moz-transition: all 300ms linear 0ms;
	-ms-transition: all 300ms linear 0ms;
	-o-transition: all 300ms linear 0ms;
	transition: all 300ms linear 0ms;
  }
  
  .fp-tags-widget ul li a:hover {
	background: #003087;
  }
/* 
::::::::::::::::::::::
41. News Details Page styles
::::::::::::::::::::::
*/
.single-post-content {
	background: #fff;
	
  }
  
  .styled-list {
	padding-left: 0;
	margin-bottom: 0;
	list-style: none;
	margin-left: 15px;
  }
  
  .styled-list li {
	margin-bottom: 15px;
	position: relative;
  }
  
  .post-content h2 {
	margin-top: 30px;
  }
  
  .post-content blockquote,
  .service-content blockquote {
	background: #003087;
	color: #fff;
	margin: 30px 0px;
	padding: 60px 70px;
	text-align: center;
	position: relative;
	z-index: 2;
  }
  
  .post-content blockquote .fp-ba,
  .service-content blockquote .fp-ba {
	font-size: 14px;
	font-weight: 700;
	margin-bottom: 20px;
	display: block;
  }
  
  blockquote p {
	font-size: 28px;
	line-height: 38px;
	font-weight: 700;
	letter-spacing: -0.84px;
  }
  
  .post-content blockquote::before,
  .service-content blockquote::before {
	font-family: Flaticon;
	font-size: 120px;
	line-height: 1;
	font-style: normal;
	color: #002e5b;
	content: "\f12f";
	position: absolute;
	right: 50px;
	bottom: 30px;
	z-index: -1;
	opacity: 0.1;
  }
  
  .single-post-meta {
	margin-top: 50px;
	margin-bottom: 50px;
  }
  
  .single-post-tags {
	padding-left: 0;
	margin-bottom: 0;
	list-style: none;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
  }
  
  .single-post-tags li {
	margin-right: 10px;
  }
  
  .single-post-tags li a,
  .single-post-tags li a:visited {
	font-size: 12px;
	text-transform: uppercase;
	font-weight: 700;
	padding: 10px 20px;
	display: block;
	color: #002e5b;
	background: #f9f9f9;
	-webkit-transition: all 300ms linear 0ms;
	-khtml-transition: all 300ms linear 0ms;
	-moz-transition: all 300ms linear 0ms;
	-ms-transition: all 300ms linear 0ms;
	-o-transition: all 300ms linear 0ms;
	transition: all 300ms linear 0ms;
  }
  
  .single-post-tags li a:hover {
	background: #003087;
  }
  
  .post-meta-title {
	font-size: 20px;
	font-weight: 700;
  }
  
  .post-share-box ul {
	padding-left: 0;
	margin-bottom: 0;
	list-style: none;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-ms-flex-pack: end !important;
	justify-content: flex-end !important;
  }
  
  .post-share-box ul li {
	margin-left: 15px;
  }
  
  .post-share-box ul li a,
  .post-share-box ul li a:visited {
	color: #b5becc;
	font-size: 18px;
  }
  
  .post-share-box ul li a:hover {
	color: #003087;
  }
  
  .post-nav-box {
	text-align: right;
  }
  
  .post-box-next {
	margin-left: auto;
  }
  
  .single-post-nav {
	border-top: 1px solid #eaeaea;
	border-bottom: 1px solid #eaeaea;
	padding: 50px 0;
  }
  
  .post-nav-title {
	font-size: 14px;
	font-weight: 700;
	color: #002e5b;
	margin-bottom: 15px;
  }
  
  .post-nav-content {
	font-size: 28px;
	font-weight: 700;
  }
  
  .post-nav-content a,
  .post-nav-content a:visited {
	color: #002e5b;
  }
  
  .post-nav-home {
	font-size: 40px;
	color: #d4e1ed;
  }
  
  .related-post-title {
	font-size: 26px;
	margin-bottom: 40px;
  }
  
  .related-post-box {
	padding: 50px 0;
  }
  
  .related-post-box .post-block-title {
	font-size: 22px;
	line-height: 1.4;
	margin: 10px auto 15px;
  }
  
  .related-post-box .post-block-title a:hover {
	color: #003087;
  }
  
  .related-post-box .post-block-content {
	padding: 20px;
  }
  
  .related-post-box .post-block-meta {
	margin-bottom: 10px;
  }
  
  .related-post-box .post-block-item {
	-webkit-box-shadow: 0 5px 83px rgba(11, 38, 83, 0.08);
	-khtml-box-shadow: 0 5px 83px rgba(11, 38, 83, 0.08);
	-moz-box-shadow: 0 5px 83px rgba(11, 38, 83, 0.08);
	-ms-box-shadow: 0 5px 83px rgba(11, 38, 83, 0.08);
	-o-box-shadow: 0 5px 83px rgba(11, 38, 83, 0.08);
	box-shadow: 0 5px 83px rgba(11, 38, 83, 0.08);
  }
  
  .related-post-box .post-block-item:hover .post-block-content {
	background: #f9f9f9;
  }
  
  .post-author-box {
	background: #f9f9f9;
	padding: 40px;
  }
  
  .posted-by-title {
	font-size: 12px;
	font-weight: 700;
  }
  
  .post-author-name {
	font-size: 36px;
	margin-bottom: 25px;
  }
  
  .author-descriptions {
	font-size: 14px;
	line-height: 26px;
  }
@media (min-width: 576px) { 
	
}
/* Medium devices (tablets, 768px and up)*/
@media only screen and (max-width: 575px) and (min-width: 300px)  {
	.site-title h2 {
		font-size: 36px;
	}
	.single-post-content{
		margin-top: 40px;
	}
	.finpress-sidebar{
		margin-left: 0;
	}
	.post-block-title{
    	font-size: 30px;
    }
	.new-details{
		padding: 20px;
	}
}
/* Medium devices (tablets, 768px and up)*/
@media only screen and (max-width: 767px) and (min-width: 576px)  {
	
}
/* Medium devices (tablets, 768px and up)*/
@media only screen and (max-width: 991px) and (min-width: 768px)  {
	
}
/* Large devices (desktops, 992px and up)*/
@media only screen and (max-width: 1199px) and (min-width: 992px)  {
	
	
}
/* Extra large devices (large desktops, 1200px and up)*/

@media only screen and (max-width: 1600px) and (min-width: 1200px)  {
	
}
/** Achievements Area END **/
</style>