<template>
    <div>
        <!--<page-loader></page-loader>-->
    <section class="achievements-area">
        <!-- <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="site-title ">
                <h4 class="sub-title">
                  Lorem Lorem
                </h4>
                <h2>All Achievement</h2>
              </div>
            </div>
          </div>
        </div> -->
        <div class="container">
            <div class="row">
              <div v-for="a in achievements" :key="a.id" class="col-md-6 col-12">
                <div class="achievements-bengal">
                    <div class="img">
                      <img v-lazy="url + a.image" >
                    </div>
                    <div class="achi-details">
                      <h3>{{ a.title }}</h3>
                      <p>{{ a.desc.substring(0,150) + '...' }}. </p>
                      <!-- <router-link :to="{name: 'achievement-details', params:{id: a.id}}" href="#" class="">{{ a.rm }}</router-link> -->
                      <a href @click.prevent="clkAcmnt(a.id, a.lang)">{{a.rm}}</a>
                    </div>
                </div>
              </div>
         
              
            </div>
            <!-- <div class="row">
              <div class="col-md-6 col-lg-6 col">
                <div class="achievements-bengal">
                    <div class="img">
                      <img src="img/achivement.jpg" >
                    </div>
                    <div class="achi-details">
                      <h3>Achievement Lorem Lorem</h3>
                      <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>
                      <a href="#">Read More</a>
                    </div>
                </div>
              </div>
              <div class="col-md-6 col-lg-6 col">
                <div class="achievements-bengal">
                    <div class="img">
                      <img src="img/achivement.jpg" >
                    </div>
                    <div class="achi-details">
                      <h3>Achievement Lorem Lorem</h3>
                      <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>
                      <a href="#">Read More</a>
                    </div>
                </div>
              </div>
              
            </div> -->
            
        </div>
      </section>  
        

    </div>
</template>

<script>
import axios from 'axios'
export default {
    props: ['ul'],
    created() {

        if(this.ul.lang == 'English'){
            this.commonWork()
        }else if(this.ul.lang == 'Arabic'){
            this.commonWork()
        }else if(this.ul.lang == 'French'){
            this.commonWork()
        }else{
            this.getData()
        }

        this.$root.$on('english', (e) => {
            this.form.lang = e
            this.commonWork()
        })

        this.$root.$on('french', (e) => {
            this.form.lang = e
            this.commonWork()
        })

        this.$root.$on('arabic', (e) => {
            this.form.lang = e
            this.commonWork()
        })

    },
    data() {
        return {
            url: this.ul.url,
            achievements: [],
            form: {
                lang: this.ul.lang
            },
            dataC: {
            category: '',
            id: '',
            name: 'achievement',
            lang: ''
          }
        }
    },
    methods: {
      clkAcmnt(id, lang){
        if(lang == 'English'){
          this.dataC.category = 'menu'
          this.dataC.id = id
          this.dataC.lang = lang
          this.$root.$emit('home', this.dataC)
        }else if(lang == 'Arabic'){
          this.dataC.category = 'menu'
          this.dataC.id = id
          this.dataC.lang = lang
          this.$root.$emit('home', this.dataC)
        }else{
          this.dataC.category = 'menu'
          this.dataC.id = id
          this.dataC.lang = lang
          this.$root.$emit('home', this.dataC)
        }
      },
        getData(){
            this.form.lang = 'English'
            this.commonWork()
        },
        commonWork(){
            axios.get(this.url + 'api/getAchievements', {params: this.form})
            .then(({data}) => {
                this.achievements = data
            })
        }
    }
}
</script>

<style scoped>

/** Achievements Area  Start **/
.no-gutters {
	padding: 0;
}
.site-title {
	text-align: center;
	margin-bottom: 60px;
}
.site-title h4 {
	font-size: 18px;
	position: relative;
	color: #0070ba;
	display:inline-block;
}
.site-title h2 {
	font-size: 48px;
	color: #222;	
  font-family: 'Rubik';
}
.achi-details {
	padding-left: 20px;
}
.achi-details a {
	color: #0e75bc;
	font-weight: bold;
	font-size: 14px;
	display: block;
  font-family: 'Rubik';
}
.achievements-bengal {
    margin-bottom: 30px;
}
.site-title h4::before {
	position: absolute;
	width: 63px;
	height: 2px;
	background: #0070ba;
	top: 10px;
	left: 110%;
	content: "";
}
.achievements-area {
	background: #fbfbfb;
	padding-bottom: 50px;
}
.achievements-area .row:nth-child(1) {
	margin-bottom: 40px;
}
.achi-details h3 {
	color: #222;
	margin-top: 30px;
	margin-bottom: 30px;
  font-family: 'Rubik';
}
.achi-details p {
	color: #777;
  font-family: 'Rubik';
}

/* Medium devices (tablets, 768px and up)*/
@media only screen and (max-width: 480px) and (min-width: 320px)  {
	.site-title h2 {
		font-size: 36px;
	}
  section.achievements-area {
    padding-top: 40px;
  }
}
@media only screen and (max-width: 767px) and (min-width: 481px)  {
  section.achievements-area {
    padding-top: 40px;
  }
	
}
/* Medium devices (tablets, 768px and up)*/
@media only screen and (max-width: 767px) and (min-width: 576px)  {
	
}
/* Medium devices (tablets, 768px and up)*/
@media only screen and (max-width: 991px) and (min-width: 768px)  {
	
}
/* Large devices (desktops, 992px and up)*/
@media only screen and (max-width: 1199px) and (min-width: 992px)  {
	
	.col-md-6{
    overflow: hidden;
  }
}
/* Extra large devices (large desktops, 1200px and up)*/

@media only screen and (max-width: 1600px) and (min-width: 1200px)  {
	.col-md-6{
    overflow: hidden;
  }
  
}
/** Achievements Area END **/
</style>