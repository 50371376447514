<template>
    <div>
        <top-bar v-if="mobileView == true" :ul="this.ul"></top-bar>

        <topbar-mobile v-else :ul="this.ul"></topbar-mobile>

        <router-view :ul="this.ul"></router-view>

        <footer-area :ul="this.ul"></footer-area>
    </div>
</template>

<script>

export default {
    created() {
        this.handleView()
        
        this.$root.$on('english', (e) => {
            this.ul.lang = e
            
        })
        this.$root.$on('french', (e) => {
            this.ul.lang = e
            
        })
        this.$root.$on('arabic', (e) => {
            this.ul.lang = e
            
        })

        
    },
    data() {
        return {
            ul:{
                //url: 'http://localhost:8002/',
                url: 'https://admin.bengalgroup.com/',
                lang: '',
                id: ''
               
            },
            mobileView: true,
        }
    },
    methods: {
        handleView() {
            if(window.innerWidth <= 575){
                this.mobileView = false
            }else if(window.innerWidth <= 767){
                this.mobileView = false
            }else{
                this.mobileView = true
            }
        },
    }
}
</script>
<style src="./assets/css/common.css">

</style>