<template>
 <div>
<nav class="navbar navbar-expand-md bg-dark navbar-dark">
   <div class="container-full">
     <div class="row">
        <div class="col-sm-3 col-xs-3 col-md-3">
    <!-- <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
      <span @click.prevent="clkBar" v-if="enableNav == true" class="navbar-toggler-icon"></span>
      <span @click.prevent="clkTime" v-else><i class="fas fa-times" aria-hidden="true"></i></span>
    </button> -->
    <button v-if="enableNav == true" class="navbar-toggler" data-toggle="collapse" data-target="#collapsibleNavbar">
      <span @click.prevent="clkBar"  class="navbar-toggler-icon"></span>
    </button>
    <div v-else class="navbar-toggler" @click.prevent="clkTime" data-toggle="collapse" data-target="#collapsibleNavbar">
      <div class="menu-icon">
        <span @click.prevent="clkTime"><i class="fas fa-times" aria-hidden="true"></i></span>
      </div>
    </div>
    </div>
    <div class="col-sm-5 col-xs-5 col-md-5">
      <div v-if="enableLang == true" class="language">
          <div class="dropdown">

              <button v-if="enableEnglish == true" class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Language 
              </button>
              <button v-if="enableArabic == true" class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                لغة 
              </button>
              <button v-if="enableFrench == true" class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Langue 
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <a class="dropdown-item" @click.prevent="clickEnglish" href="#">English</a>
                <a class="dropdown-item" @click.prevent="clickArabic" href="#">Arabic</a>
                <a class="dropdown-item" @click.prevent="clickFrench" href="#">French</a>
              </div>
          </div>
      </div>
    </div>
    <div class="col-sm-4 col-xs-4 col-md-4">
      <a href="#"  @click.prevent="homeClicked"  class="mainlogo">
        <!-- <img src="images/logo.png" alt="Bengal Logo"> -->
        <img :src="url + siteImage" alt="Bengal Logo">
      </a>
    </div>
    <div class="col-xs-12">
      <div v-if="testNav == true" class="collapse navbar-collapse" id="collapsibleNavbar">
        <ul class="navbar-nav">
        <li>
          <a href @click.prevent="aboutus(1, 'menu')" v-if="enableEnglish == true">About Us</a>
          <a href @click.prevent="aboutus(8, 'menu')" v-if="enableArabic == true">معلومات عنا</a>
          <a href @click.prevent="aboutus(21, 'menu')" v-if="enableFrench == true">À propos de nous</a>
        </li>
        <li>
          <a href @click.prevent="category(2, 'menu')" v-if="enableEnglish == true">What We Do</a>
          <a href @click.prevent="category(12, 'menu')" v-if="enableArabic == true">الذي نفعله</a>
          <a href @click.prevent="category(22, 'menu')" v-if="enableFrench == true">Ce que nous faisons</a>
        </li>
        <li>
          <a href @click.prevent="achievement(3, 'menu')" v-if="enableEnglish == true">Our Achievements</a>
          <a href @click.prevent="achievement(13, 'menu')" v-if="enableArabic == true">إنجازاتنا</a>
          <a href @click.prevent="achievement(23, 'menu')" v-if="enableFrench == true">Nos réalisations</a>
        </li>
        <li>
          <a href @click.prevent="responsibility(4, 'menu')" v-if="enableEnglish == true">Social Responsibility</a>
          <a href @click.prevent="responsibility(14, 'menu')" v-if="enableArabic == true">مسؤولية اجتماعية</a>
          <a href @click.prevent="responsibility(24, 'menu')" v-if="enableFrench == true">Responsabilité sociale</a>
        </li>
        <li>
            <a href @click.prevent="career(5, 'menu')" v-if="enableEnglish == true">Career</a>
            <a href @click.prevent="career(15, 'menu')" v-if="enableArabic == true">حياة مهنية</a>
            <a href @click.prevent="career(25, 'menu')" v-if="enableFrench == true">Carrière</a>
        </li>
        <li>
          <a href @click.prevent="news(6, 'menu')" v-if="enableEnglish == true">News and Events</a>
          <a href @click.prevent="news(16, 'menu')" v-if="enableArabic == true">أخبار وأحداث</a>
          <a href @click.prevent="news(26, 'menu')" v-if="enableFrench == true">Nouvelles et Evènements</a>
        </li>
        <li>
          <a href @click.prevent="contactus(9, 'menu')" v-if="enableEnglish == true">Contact us</a>
          <a href @click.prevent="contactus(17, 'menu')" v-if="enableArabic == true">اتصل بنا</a>
          <a href @click.prevent="contactus(27, 'menu')" v-if="enableFrench == true">Nous contacter</a>
        </li>
    </ul>
      </div>
    </div>
        
     </div>
   </div>
</nav>

      
        <div v-if="videoarea" class="bengal-video-area">
            <div class="container-fluid">
              <div class="row">
                    <div class="col-sm-12 justify-content-center">
                      <video-background 
                        :src="url + link.link"
                        style=" height: 100vh;"
                    >
                   
                    <div class="bengal-title">
                    <h1>{{titleArea.heading}} <br> {{titleArea.heading_two}}</h1>
                    <p>{{titleArea.title}} <br> {{titleArea.title_two}}</p>
                  </div>
                </video-background>
                 
                </div>
              </div>
            </div>
        </div>
      <!-- Hero -->
       <!-- Hero -->
      <section v-if="heroArea" id="bengal-about-header" class="hero p-0 odd featured">
          <div class="swiper-container no-slider animation slider-h-50 slider-h-auto">
              <div class="swiper-wrapper">
                    <!-- Item 1 -->
                  <div class="swiper-slide slide-center">
                        <!-- Media -->
                        <img v-if="loader == true" style="width:1920px; height:500px" src="https://new.bengalgroup.com/dist/default.jpg" alt="Full Image" class="full-image" data-mask="80">
                        <img v-else :src="url + bredcrumb.image" alt="Full Image" class="full-image" data-mask="80">

                      <div class="slide-content">
                            <div class="container">
                                <div class="row">
                                    <div class="bdtittle_area">
                                      <div class="breadcumb-title">
                                      <h2 v-if="langText.category">{{ langText.category }}</h2>
                                       <h2 v-else>{{ langText.menu }}</h2>
                                      <p>{{ langText.desc }}</p>
                                    </div>
                                  <!-- Content -->
                                  </div>
                                  <nav class="breadcrumb-menu">
                                    <ol class="breadcrumb-nav">
                                        <li v-if="enableEnglish == true" class="breadcrumb-item"><a href="#" @click.prevent="homeClicked">Home</a></li>
                                        <li v-if="enableArabic == true" class="breadcrumb-item"><a href="#" @click.prevent="homeClicked">الصفحة الرئيسية</a></li>
                                        <li v-if="enableFrench == true" class="breadcrumb-item"><a href="#" @click.prevent="homeClicked">Domicile</a></li>
                                        <li v-if="bredcrumb.category" class="breadcrumb-item"><a href="#">{{langText.category}}</a></li>
                                        <li v-else class="breadcrumb-item"><a href="#">{{langText.menu}}</a></li>
                                    </ol>
                                  </nav>
                              </div>
                          </div>
                      </div>
                        
                  </div>
                </div>
          </div>
      </section>
</div>
</template>

<script>
import axios from 'axios'
export default {
    props: ['ul'],
    created() {
       this.$root.$on('change', () => {
        let id = this.$route.params.id
          if(id){
            this.enableLang = false
          }else{
            this.enableLang = true
          }
      })

      this.getSiteLogo()
      this.getLinkVideo()
      this.getTitle()
      this.getData()
      this.getIcon()

      this.$root.$on('urlTopbar', ()=> {
        this.loadWork()
        this.videoarea = true
        this.heroArea = false
      })

      this.$root.$on('category', (id)=> {
        this.changeText = 'business-category'
        this.loadWork()
        this.comonWork()
        this.getBredcrumb(id, 'category')
        localStorage.setItem('id', id)
        localStorage.setItem('category', 'category')
        localStorage.setItem('title', 'business-category')
        this.$router.push({ name: 'business-category', params: { id: id } }).catch()
      })

      this.$root.$on('page', (id)=> {
        this.changeText = 'page'
        this.loadWork()
        this.comonWork()
        this.getBredcrumb(id, 'page')
        localStorage.setItem('id', id)
        localStorage.setItem('category', 'page')
        localStorage.setItem('title', 'page')
        this.$router.push({ name: 'page-category', params: { id: id } }).catch()
      })

      this.reloadItem = localStorage.getItem('title')
      let id = localStorage.getItem('id')
        if(this.reloadItem == 'aboutus'){
          if(id == 1){
            this.loadWork()
            this.comonWork()
            this.enableEnglish = true
            this.enableArabic = false
            this.enableFrench = false
            this.getBredcrumb(1, 'menu')
            this.$root.$emit('english', 'English')
          }else if(id == 8){
            this.loadWork()
            this.comonWork()
            this.enableEnglish = false
            this.enableArabic = true
            this.enableFrench = false
            this.getBredcrumb(8, 'menu')
            this.$root.$emit('arabic', 'Arabic')
          }else{
            this.loadWork()
            this.comonWork()
            this.enableEnglish = false
            this.enableArabic = false
            this.enableFrench = true
            this.getBredcrumb(21, 'menu')
            this.$root.$emit('french', 'French')
          }
        }else if(this.reloadItem == 'main-category'){
          if(id == 2){
            this.loadWork()
            this.comonWork()
            this.enableEnglish = true
            this.enableArabic = false
            this.enableFrench = false
            this.getBredcrumb(2, 'menu')
            this.$root.$emit('english', 'English')
          }else if(id == 12){
            this.loadWork()
            this.comonWork()
            this.enableEnglish = false
            this.enableArabic = true
            this.enableFrench = false
            this.getBredcrumb(12, 'menu')
            this.$root.$emit('arabic', 'Arabic')
          }else{
            this.loadWork()
            this.comonWork()
            this.enableEnglish = false
            this.enableArabic = false
            this.enableFrench = true
            this.getBredcrumb(22, 'menu')
            this.$root.$emit('french', 'French')
          }
        }else if(this.reloadItem == 'achievement'){
          if(id == 3){
            this.loadWork()
            this.comonWork()
            this.enableEnglish = true
            this.enableArabic = false
            this.enableFrench = false
            this.getBredcrumb(3, 'menu')
            this.$root.$emit('english', 'English')
          }else if(id == 13){
            this.loadWork()
            this.comonWork()
            this.enableEnglish = false
            this.enableArabic = true
            this.enableFrench = false
            this.getBredcrumb(13, 'menu')
            this.$root.$emit('arabic', 'Arabic')
          }else{
            this.loadWork()
            this.comonWork()
            this.enableEnglish = false
            this.enableArabic = false
            this.enableFrench = true
            this.getBredcrumb(23, 'menu')
            this.$root.$emit('french', 'French')
          }
        }else if(this.reloadItem == 'responsibility'){
          if(id == 4){
            this.loadWork()
            this.comonWork()
            this.enableEnglish = true
            this.enableArabic = false
            this.enableFrench = false
            this.getBredcrumb(4, 'menu')
            this.$root.$emit('english', 'English')
          }else if(id == 14){
            this.loadWork()
            this.comonWork()
            this.enableEnglish = false
            this.enableArabic = true
            this.enableFrench = false
            this.getBredcrumb(14, 'menu')
            this.$root.$emit('arabic', 'Arabic')
          }else{
            this.loadWork()
            this.comonWork()
            this.enableEnglish = false
            this.enableArabic = false
            this.enableFrench = true
            this.getBredcrumb(24, 'menu')
            this.$root.$emit('french', 'French')
          }
        }else if(this.reloadItem == 'career'){
          if(id == 5){
            this.loadWork()
            this.comonWork()
            this.enableEnglish = true
            this.enableArabic = false
            this.enableFrench = false
            this.getBredcrumb(5, 'menu')
            this.$root.$emit('english', 'English')
          }else if(id == 15){
            this.loadWork()
            this.comonWork()
            this.enableEnglish = false
            this.enableArabic = true
            this.enableFrench = false
            this.getBredcrumb(15, 'menu')
            this.$root.$emit('arabic', 'Arabic')
          }else{
            this.loadWork()
            this.comonWork()
            this.enableEnglish = false
            this.enableArabic = false
            this.enableFrench = true
            this.getBredcrumb(25, 'menu')
            this.$root.$emit('french', 'French')
          }
        }else if(this.reloadItem == 'news'){
          if(id == 6){
            this.loadWork()
            this.comonWork()
            this.enableEnglish = true
            this.enableArabic = false
            this.enableFrench = false
            this.getBredcrumb(6, 'menu')
            this.$root.$emit('english', 'English')
          }else if(id == 16){
            this.loadWork()
            this.comonWork()
            this.enableEnglish = false
            this.enableArabic = true
            this.enableFrench = false
            this.getBredcrumb(16, 'menu')
            this.$root.$emit('arabic', 'Arabic')
          }else{
            this.loadWork()
            this.comonWork()
            this.enableEnglish = false
            this.enableArabic = false
            this.enableFrench = true
            this.getBredcrumb(26, 'menu')
            this.$root.$emit('french', 'French')
          }
        }else if(this.reloadItem == 'contactus'){
          if(id == 9){
            this.loadWork()
            this.comonWork()
            this.enableEnglish = true
            this.enableArabic = false
            this.enableFrench = false
            this.getBredcrumb(9, 'menu')
            this.$root.$emit('english', 'English')
          }else if(id == 17){
            this.loadWork()
            this.comonWork()
            this.enableEnglish = false
            this.enableArabic = true
            this.enableFrench = false
            this.getBredcrumb(17, 'menu')
            this.$root.$emit('arabic', 'Arabic')
          }else{
            this.loadWork()
            this.comonWork()
            this.enableEnglish = false
            this.enableArabic = false
            this.enableFrench = true
            this.getBredcrumb(27, 'menu')
            this.$root.$emit('french', 'French')
          }
        }else if(this.reloadItem == 'gallery'){
          if(id == 29){
            this.loadWork()
            this.comonWork()
            this.enableEnglish = true
            this.enableArabic = false
            this.enableFrench = false
            this.getBredcrumb(29, 'menu')
            this.$root.$emit('english', 'English')
          }else if(id == 30){
            this.loadWork()
            this.comonWork()
            this.enableEnglish = false
            this.enableArabic = true
            this.enableFrench = false
            this.getBredcrumb(30, 'menu')
            this.$root.$emit('arabic', 'Arabic')
          }else{
            this.loadWork()
            this.comonWork()
            this.enableEnglish = false
            this.enableArabic = false
            this.enableFrench = true
            this.getBredcrumb(31, 'menu')
            this.$root.$emit('french', 'French')
          }
        }
        else if(this.reloadItem == 'business-category'){
          let id = this.$route.params.id
          this.clkCategory(id, 'category')
        }

        this.$root.$on('footer', () => {
          this.footer(29, 'menu')
        })
        this.$root.$on('footerA', () => {
          this.footer(30, 'menu')
        })
        this.$root.$on('footerF', () => {
          this.footer(31, 'menu')
        })

        this.$root.$on('footer_menu', (e) => {
          if(e == 'home'){
            this.loadWork()
            this.homeClicked()
          }else if(e.name == 'aboutus'){
            this.aboutus(e.id, e.category)
          }else if(e.name == 'news'){
            this.news(e.id, e.category)
          }else if(e.name == 'contactus'){
            this.contactus(e.id, e.category)
          }
        })

        this.$root.$on('home', (e) => {
           if(e.name == 'category'){
             this.category(e.id, e.category)
           }else if(e.name == 'news'){
             if(e.lang == 'English'){
               this.news_details(e.id, e.category, 6)
             }else if(e.lang == 'Arabic'){
               this.news_details(e.id, e.category, 16)
             }else{
               this.news_details(e.id, e.category, 26)
             }
           }else if(e.name == 'achievement'){
             if(e.lang == 'English'){
               this.achievement_details(e.id, e.category, 3)
             }else if(e.lang == 'Arabic'){
               this.achievement_details(e.id, e.category, 13)
             }else{
               this.achievement_details(e.id, e.category, 23)
             }
           }
          
        })

        this.$root.$on('business', (e) => {
          this.clkCategory(e.id, e.category)
        })
    },
    data(){
        return {
            url: this.ul.url,
            reloadItem: '',
            id: '',
            imageA: [],
            imageC: [],
            submenus: [],
            test: true,
            bengalmenu1: false,
            videoarea: true,
            heroArea: false,
            changeMenu: 'bengalmenu d-none d-md-block',
            english: 'English',
            german: 'German',
            french: 'French',
            arabic: 'Arabic',
            menuE: false,
            menuG: false,
            menuF: false,
            menuA: false,
            bredcrumbs: [],
            bredcrumb: {},
            valuee: {
              id: '', category: ''
            },
            active: false,
            siteImage: '',
            titleArea: {},
            link: {},
            loader: true,
            //
            enableEnglish: true,
            enableArabic: false,
            enableFrench: false,
            form: {
              lang: '',
              category: '',
              id: ''
            },
            menus: [],
            categories: [],
            subs: [],
            sub_categories: [],
            pages: [],
            socials: [],
            aImages: [],
            cImages: [],
            href_one: '',
            href_two: '',
            icon_one: '',
            icon_two: '',
            langText:{},
            changeText: '',
            enableNav: true,
            testNav: false,
            finalNav: false,
            enableLang: true


        }
    },
    methods: {
      // start
       clkBar(){
        this.testNav = true
        this.enableNav = false
      },
      clkTime(){
        this.testNav = false
        this.enableNav = true
      },
      getLinkVideo(){
        axios.get(this.url + 'api/getVideoLink')
          .then(({data}) => {
            this.link = data
          })
      },
      
      getSiteLogo(){
        axios.get(this.url + 'api/getSiteLogo')
          .then(({data}) => {
            this.siteImage = data.image
          })
      },
      getTitle(){
        this.form.lang = 'English'
        this.emitTitle()
      },
      emitTitle(){
        axios.get(this.url + 'api/getTitle', {params: this.form})
          .then(({data}) => {
            this.titleArea = data
          })
      },
      getIcon(){
        axios.get(this.url + 'api/getSocial')
            .then(({data}) => {
              this.socials = data
              this.href_one = this.socials[0].href
              this.href_two = this.socials[1].href
              this.icon_one = this.socials[0].icon
              this.icon_two = this.socials[1].icon
            })
      },
      getData(){
        this.form.lang = 'English'
        this.menuWork()
      },
      menuWork(){
        this.menus = []
        this.categories = []
        this.subs = []
        this.pages = []
        this.imageA = []
        this.imageC = []
        this.aImages = []
        this.cImages = []
        axios.get(this.url + 'api/getMenus', {params: this.form})
          .then(({data}) => {
            this.menus = data.menus
            this.categories = data.categories
            this.subs = data.sub_categories
            this.pages = data.pages
            this.imageA = data.aImages
            this.imageA.forEach((item, index) => {
              if(index == 0 || index == 1){
                this.aImages.push(item)
              }
            })
            this.imageC = data.cImages
            this.imageC.forEach((item, index) => {
              if(index == 0 || index == 1){
                this.cImages.push(item)
              }
            })
          })
      },
      getSummenuByCategory(id){
        this.sub_categories = []
        this.subs.forEach((item) => {
          if(item.menu_categorie_id == id){
            this.sub_categories.push(item)
          }
        })
      },
      clickEnglish(){
          if(this.ul.lang == 'English'){
            this.enableEnglish = true
            this.enableArabic = false
            this.enableFrench = false
            this.emitTitle()
            this.menuWork()
            if(this.changeText == 'aboutus'){
              this.getBredcrumb(1, 'menu')
              localStorage.setItem('id', 1)
            }else if(this.changeText == 'main-category'){
              this.getBredcrumb(2, 'menu')
              localStorage.setItem('id', 1)
            }else if(this.changeText == 'achievement'){
              this.getBredcrumb(3, 'menu')
              localStorage.setItem('id', 3)
            }else if(this.changeText == 'responsibility'){
              this.getBredcrumb(4, 'menu')
              localStorage.setItem('id', 4)
            }else if(this.changeText == 'career'){
              this.getBredcrumb(5, 'menu')
              localStorage.setItem('id', 5)
            }else if(this.changeText == 'contactus'){
              this.getBredcrumb(9, 'menu')
              localStorage.setItem('id', 9)
            }else if(this.changeText == 'gallery'){
              this.getBredcrumb(29, 'menu')
              localStorage.setItem('id',29)
            }else if(this.changeText == 'news'){
              this.getBredcrumb(6, 'menu')
              localStorage.setItem('id',6)
            }
            
         }else{
            this.$root.$emit('english', this.english)
            this.form.lang = this.english
            this.enableEnglish = true
            this.enableArabic = false
            this.enableFrench = false
            this.emitTitle()
            this.menuWork()
            if(this.changeText == 'aboutus'){
              this.getBredcrumb(1, 'menu')
              localStorage.setItem('id', 2)
            }else if(this.changeText == 'main-category'){
              this.getBredcrumb(2, 'menu')
              localStorage.setItem('id', 2)
            }else if(this.changeText == 'achievement'){
              this.getBredcrumb(3, 'menu')
              localStorage.setItem('id', 3)
            }else if(this.changeText == 'responsibility'){
              this.getBredcrumb(4, 'menu')
              localStorage.setItem('id', 4)
            }else if(this.changeText == 'career'){
              this.getBredcrumb(5, 'menu')
              localStorage.setItem('id', 5)
            }else if(this.changeText == 'contactus'){
              this.getBredcrumb(9, 'menu')
              localStorage.setItem('id', 9)
            }else if(this.changeText == 'gallery'){
              this.getBredcrumb(29, 'menu')
              localStorage.setItem('id',29)
            }else if(this.changeText == 'news'){
              this.getBredcrumb(6, 'menu')
              localStorage.setItem('id',6)
            }
         }
      },
      clickArabic(){
          if(this.ul.lang == 'Arabic'){
            this.enableEnglish = false
            this.enableArabic = true
            this.enableFrench = false
            this.emitTitle()
            this.menuWork()
            if(this.changeText == 'aboutus'){
              this.getBredcrumb(8, 'menu')
              localStorage.setItem('id', 8)
            }else if(this.changeText == 'main-category'){
              this.getBredcrumb(12, 'menu')
              localStorage.setItem('id', 12)
            }else if(this.changeText == 'achievement'){
              this.getBredcrumb(13, 'menu')
              localStorage.setItem('id', 13)
            }else if(this.changeText == 'responsibility'){
              this.getBredcrumb(14, 'menu')
              localStorage.setItem('id', 14)
            }else if(this.changeText == 'career'){
              this.getBredcrumb(15, 'menu')
              localStorage.setItem('id', 15)
            }else if(this.changeText == 'contactus'){
              this.getBredcrumb(17, 'menu')
              localStorage.setItem('id', 17)
            }else if(this.changeText == 'gallery'){
              this.getBredcrumb(30, 'menu')
              localStorage.setItem('id',30)
            }else if(this.changeText == 'news'){
              this.getBredcrumb(16, 'menu')
              localStorage.setItem('id',16)
            }
         }else{
            this.$root.$emit('arabic', this.arabic)
            this.form.lang = this.arabic
            this.enableEnglish = false
            this.enableArabic = true
            this.enableFrench = false
            this.emitTitle()
            this.menuWork()
            if(this.changeText == 'aboutus'){
              this.getBredcrumb(8, 'menu')
              localStorage.setItem('id', 8)
            }else if(this.changeText == 'main-category'){
              this.getBredcrumb(12, 'menu')
              localStorage.setItem('id', 12)
            }else if(this.changeText == 'achievement'){
              this.getBredcrumb(13, 'menu')
              localStorage.setItem('id', 13)
            }else if(this.changeText == 'responsibility'){
              this.getBredcrumb(14, 'menu')
              localStorage.setItem('id', 14)
            }else if(this.changeText == 'career'){
              this.getBredcrumb(15, 'menu')
              localStorage.setItem('id', 15)
            }else if(this.changeText == 'contactus'){
              this.getBredcrumb(17, 'menu')
              localStorage.setItem('id', 17)
            }else if(this.changeText == 'gallery'){
              this.getBredcrumb(30, 'menu')
              localStorage.setItem('id',30)
            }else if(this.changeText == 'news'){
              this.getBredcrumb(16, 'menu')
              localStorage.setItem('id',16)
            }
         }
      },
      clickFrench(){
         if(this.ul.lang == 'French'){
            this.enableEnglish = false
            this.enableArabic = false
            this.enableFrench = true
            this.emitTitle()
            this.menuWork()
            this.getBredcrumb(21, 'menu')
            if(this.changeText == 'aboutus'){
              this.getBredcrumb(21, 'menu')
              localStorage.setItem('id', 21)
            }else if(this.changeText == 'main-category'){
              this.getBredcrumb(22, 'menu')
              localStorage.setItem('id', 22)
            }else if(this.changeText == 'achievement'){
              this.getBredcrumb(23, 'menu')
              localStorage.setItem('id', 23)
            }else if(this.changeText == 'responsibility'){
              this.getBredcrumb(24, 'menu')
              localStorage.setItem('id', 24)
            }else if(this.changeText == 'career'){
              this.getBredcrumb(25, 'menu')
              localStorage.setItem('id', 25)
            }else if(this.changeText == 'contactus'){
              this.getBredcrumb(27, 'menu')
              localStorage.setItem('id', 27)
            }else if(this.changeText == 'gallery'){
              this.getBredcrumb(31, 'menu')
              localStorage.setItem('id',31)
            }else if(this.changeText == 'news'){
              this.getBredcrumb(26, 'menu')
              localStorage.setItem('id',26)
            }
         }else{
            this.$root.$emit('french', this.french)
            this.form.lang = this.french
            this.enableEnglish = false
            this.enableArabic = false
            this.enableFrench = true
            this.emitTitle()
            this.menuWork()
            if(this.changeText == 'aboutus'){
              this.getBredcrumb(21, 'menu')
              localStorage.setItem('id', 21)
            }else if(this.changeText == 'main-category'){
              this.getBredcrumb(22, 'menu')
              localStorage.setItem('id', 22)
            }else if(this.changeText == 'achievement'){
              this.getBredcrumb(23, 'menu')
              localStorage.setItem('id', 23)
            }else if(this.changeText == 'responsibility'){
              this.getBredcrumb(24, 'menu')
              localStorage.setItem('id', 24)
            }else if(this.changeText == 'career'){
              this.getBredcrumb(25, 'menu')
              localStorage.setItem('id', 25)
            }else if(this.changeText == 'contactus'){
              this.getBredcrumb(27, 'menu')
              localStorage.setItem('id', 27)
            }else if(this.changeText == 'gallery'){
              this.getBredcrumb(31, 'menu')
              localStorage.setItem('id',31)
            }else if(this.changeText == 'news'){
              this.getBredcrumb(26, 'menu')
              localStorage.setItem('id',26)
            }
         }
          
         
      },
      enableMenu(){
        if(this.test == true){
          this.menuE = true
          this.changeMenu = 'bengalmenu d-none d-md-block active'
          this.test = false
        }else{
          this.menuE = false
          this.changeMenu = 'bengalmenu d-none d-md-block'
          this.test = true
        }
      },
      
      homeClicked(){
        this.$router.push('/')
        this.heroArea = false
        this.videoarea = true
        this.testNav = false
        this.enableNav = true
      },
      // Common Work For All
      loadWork(){
        this.loader = true
        setTimeout(() => {
          this.loader = false
        }, 2000)
      },
      comonWork(){
        this.videoarea = false
        this.heroArea = true
        this.changeMenu = 'bengalmenu d-none d-md-block'
        this.test = true
      },
      getBredcrumb(id, category){
        this.form.lang = this.ul.lang
        this.form.id = id
        this.form.category = category
        axios.post(this.url + 'api/getBredcrumb', this.form)
        .then(({data}) => {
          this.bredcrumb = data
          this.langText = data
          this.testNav = false
          this.enableNav = true
          let id = this.$route.params.id
          if(id){
            this.enableLang = false
          }else{
            this.enableLang = true
          }
        })
      },

      // Menu Items
      aboutus(id, category){
        this.changeText = 'aboutus'
        this.loadWork()
        this.comonWork()
        this.getBredcrumb(id, category)
        localStorage.setItem('id', id)
        localStorage.setItem('category', category)
        localStorage.setItem('title', 'aboutus')
        this.$router.push('/about-us').catch()
      },
      
      // What We Do
      category(id, category){
        this.changeText = 'main-category'
        this.loadWork()
        this.comonWork()
        this.getBredcrumb(id, category)
        localStorage.setItem('id', id)
        localStorage.setItem('category', category)
        localStorage.setItem('title', 'main-category')
        this.$router.push('/category').catch()
      },
      
      // Page Category
      page(id, category){
        this.changeText = 'page'
        this.loadWork()
        this.comonWork()
        this.getBredcrumb(id, category)
        localStorage.setItem('id', id)
        localStorage.setItem('category', category)
        localStorage.setItem('title', 'page')
        this.$router.push({ name: 'page-category', params: { id: id } }).catch()
      
      },

      // Business Category
      clkCategory(id, category){
        this.changeText = 'business-category'
        this.loadWork()
        this.comonWork()
        this.getBredcrumb(id, category)
        localStorage.setItem('id', id)
        localStorage.setItem('category', category)
        localStorage.setItem('title', 'business-category')
        this.$router.push({ name: 'business-category', params: { id: id } }).catch()
      },
      
      // Achievement 
      achievement(id, category){
        this.changeText = 'achievement'
        this.loadWork()
        this.comonWork()
        this.getBredcrumb(id, category)
        localStorage.setItem('id', id)
        localStorage.setItem('category', category)
        localStorage.setItem('title', 'achievement')
        this.$router.push('/achievement').catch()
      },
      // Achievement Details
      achievement_details(id, category, bId){
        this.changeText = 'achievement'
        this.loadWork()
        this.comonWork()
        this.getBredcrumb(bId, category)
        localStorage.setItem('id', bId)
        localStorage.setItem('category', category)
        localStorage.setItem('title', 'achievement')
        this.$router.push({name: 'achievement-details', params:{id: id}}).catch()
      },
      // Social Responsibility 
      responsibility(id, category){
        this.changeText = 'responsibility'
        this.loadWork()
        this.comonWork()
        this.getBredcrumb(id, category)
        localStorage.setItem('id', id)
        localStorage.setItem('category', category)
        localStorage.setItem('title', 'responsibility')
        this.$router.push('/responsibility').catch()
      },
      // Career 
      career(id, category){
        this.changeText = 'career'
        this.loadWork()
        this.comonWork()
        this.getBredcrumb(id, category)
        localStorage.setItem('id', id)
        localStorage.setItem('category', category)
        localStorage.setItem('title', 'career')
        this.$router.push('/career').catch()
      },
      // News 
      news(id, category){
        this.changeText = 'news'
        this.loadWork()
        this.comonWork()
        this.getBredcrumb(id, category)
        localStorage.setItem('id', id)
        localStorage.setItem('category', category)
        localStorage.setItem('title', 'news')
        this.$router.push('/news').catch()
      },
      // Blog (News Details)
      news_details(id, category, bId){
        this.changeText = 'news'
        this.loadWork()
        this.comonWork()
        this.getBredcrumb(bId, category)
        localStorage.setItem('id', bId)
        localStorage.setItem('category', category)
        localStorage.setItem('title', 'news')
        this.$router.push({name: 'news-details', params:{id: id}}).catch()
      },
      // Contact Us 
      contactus(id, category){
        this.changeText = 'contactus'
        this.loadWork()
        this.comonWork()
        this.getBredcrumb(id, category)
        localStorage.setItem('id', id)
        localStorage.setItem('category', category)
        localStorage.setItem('title', 'contactus')
        this.$router.push('/contactus').catch()
      },
      // Gallery For Footer
      footer(id, category){
        this.changeText = 'gallery'
        this.loadWork()
        this.comonWork()
        this.getBredcrumb(id, category)
        localStorage.setItem('id', id)
        localStorage.setItem('category', category)
        localStorage.setItem('title', 'gallery')
        this.$router.push('/gallery').catch()
      },
      // End
     
    },

    

}
</script>

<style scoped>
.slide-content{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}
a.mainlogo {
    display: block;
    width: 75px;
}
.bdtittle_area {
    position: absolute;
    top: calc(50% - 40px);
}
img{
  max-width: 100%;
}
.bg-dark {
    position: relative;
    z-index: 9;
}
.navbar.navbar-expand-lg.navbar-light.bg-light {
	margin: 0;
	padding: 0;
}
hr {
	margin: 0;
}
body {
    font-family: 'Rubik', sans-serif;
    overflow-x: hidden;
}
container-fluid {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
}

/**menu Area Start **/
.navbar{
  display: inherit;
}
ul.navbar-nav li a {
    color: #eee;
    font-size: 15px;
    font-family: 'Rubik';
    line-height: 33px;
}
.bengalmenu {
	background: rgb(0,48, 135, .8);
}
.menu_area, .sub-menu1, ul {
	list-style: none;
	padding: 0;
	position: relative;
  padding-left: 10px;
	
}
.menu_area > li > a {
	color: #fff;
	font-size: 30px;
  font-family: 'Rubik';
}

.sub-menu {
    background: #2f2f2f;
    left: 0;
    position: absolute;
    top: 100%;
    width: 200px;
    visibility: hidden;
    opacity: 0;
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: top;
    transform-origin: top;
	box-shadow: 0 7px 10px rgba(0,0,0,.1);
	
}
.sub-menu1 li{
	transition: .5s;
  position: relative;

}
.sub-menu1 li:hover {
	background: #fff;
	padding-left: 10px;
}
.sub-menu1 li:hover > a {
	color: #003087;
}
.sub-menu1 li a:hover {
	text-decoration: none;
}
.sub-menu1 li:hover > .sub-menu{
    top: 0;
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    opacity: 1;
    visibility: visible;
	z-index: 999;
	left: 60%;
}
.sub-menu1 li .sub-menu{
    left: 100%;
    top: 0;
}
.sub-menu1 li:hover > .sub-menu1 li .sub-menu{
    left: 100%;
    top: 0;
}
.sub-menu1 li:hover > .sub-menu li {
	padding-left: 10px;
}
.img-sub-menu1, .img-sub-menu2 {
	margin-top: 12px;
}
.img-sub-menu1 li:first-child{
	margin-bottom: 12px;
}
.img-sub-menu2 li:first-child{
	margin-bottom:5px;
}
.img-sub-menu2 li {
	display: inline-block;
}
.menu_area {
	border-right: 1px solid rgba(255, 255, 255, 0.17);
}
.bengalmenu {
	position: absolute;
	width: 100%;
	height: 400px;
	left: 0;
	bottom: 100%;
}
.bengalmenu::after {
	background: rgb(0,48, 135, .8);
	width: 100%;
	height: 100%;
	bottom: 100%;
	z-index: 99;
	content: "";
	display: block;
	position: absolute;
	z-index: -1;
}
.bengalmenu.active{
	top:100%
}

.sub-menu1 li a {
	border-bottom: 1px solid rgba(255, 255, 255, 0.17);
	padding-bottom: 5px;
  color: #eee;
	line-height: 36px;
  font-family: 'Rubik';
}



/**header style **/
.menuandsearch {
	margin-right: 40px;
}
.menu-icon {
  background: #fff;
  width: 48px;
  height: 48px;
  border-radius: 50px;
  text-align: center;
  line-height: 48px;
  font-size: 26px;
  color: #0070ba;
}
button.navbar-toggler {
    height: 48px;
    width: 48px;
    font-size: 26px;
    padding: 0;
}
.navbar-toggler{
  border:none
}
.search-icon {
  font-size: 18px;
  color: #fff;
}
#dropdownMenuButton {
  color: #fff !important;
  font-size: 16px;
  font-weight: 500;
  font-family: 'Rubik', sans-serif;
}


header{
position: relative;
z-index: 999;
}
header.sticky{
  position: fixed;
  width: 100%;
  top: 0;
  left:0;
  transition: 0.6s;
  background: rgba(0, 0, 0, 0.7);
  padding: 20px 0;

}
header.sticky .visiable-menu{
  padding-top:0;
}
header.sticky .bengal-logo-area img {
  transform: scale(1);
  width: 100px;
}
#dropdownMenuButton{
	  color: #222;
	  background: no-repeat;
	  border: none;
  }
  .social-icon ul {
	  list-style: none;
	}
	.social-icon ul li{
	  margin-left:10px;
	}
  .menuandsearch i {
	margin-left: 10px;
}


/** Video Area Start **/
.bengal-video-area {
width: 100vw;
height: 100vh;
position: relative;
top: -21px;
background: rgba(0,0,0, .1);
}
.bengal-video-area .container-fluid{
  padding-left: 0;
  padding-right: 0;
  margin-right: 0;
  margin-left: 0;
}
.bengal-video-area:before {
    content: "";
    width: 100%;
    height: 100%;
    background: rgb(0,0,0, .6);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}

.bengal-title {
position: relative;
z-index: 99;
text-align: center;
 top: calc(50% - 105px + 10px);
}

.bengal-title h1 {
color: #fff;

font-family: 'Rubik';
}
.bengal-title p {
color: #eee;
font-size: 16px;
font-family: 'Rubik';
}

/** About us Page Header Area  Start **/
.slide-content.row.text-center {
	position: absolute;
	left: 11%;
	top: 20%;
}
.swiper-slide.slide-center {
	position: relative;
}
.breadcumb-title {
	text-align: left;
	color: #fff;
  position: relative;
  margin-left: 10px;
}
.breadcumb-title::after {
    background: rgba(0, 0, 0, 0) -webkit-linear-gradient(right, transparent 0%, transparent 25%, #000 71%, #000 100%) repeat scroll 0 0;
    background: rgba(0, 0, 0, 0) linear-gradient(to left, transparent 20%, transparent 25%, #000 100%, #000 100%) repeat scroll 0 0;
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.breadcumb-title h2{
    position: relative;
    z-index: 1;
}
.breadcumb-title p{
position: relative;
    z-index: 1;
}
.breadcrumb-menu {
	position: absolute;
	right: 0;
	bottom: 0;
	background: #fff;
	padding: 6px;
}
.breadcrumb-menu ol {
	margin: 0;
	padding: 0;
	list-style: none;
}
.breadcrumb-menu ol li{
	display: inline-block;
}
.breadcrumb-menu ol li a{
	color: #0070ba;
  font-family: 'Rubik';
}
.breadcumb-title p {
	position: relative;
	padding-left: 10px;
  font-family: 'Rubik';
}
.breadcumb-title p::before {
	position: absolute;
	content: "";
	height: 20px;
	width: 2px;
	top: 0%;
	left: 0;
	background: #0070ba;
}

.breadcumb-title h2{
  font-family: 'Rubik';
  font-size: 20px !important
}
.language {
    margin-top: 7px;
}


/* Medium devices (tablets, 768px and up)*/
@media only screen and (max-width: 575px) and (min-width: 320px)  {

	.menuandsearch {
		margin-right: 20px;
	}
  .bengal-title{
    top: calc(50% - 168px + 60px)
  }
  .navbar-toggler{
    font-size: 20px;
  }
  .svg-inline--fa.fa-user.fa-w-14{
     font-size: 20px;
  }
	.col-xs-4.bengal-logo-area img {
		width: 130px;
		text-align: center;
		margin: 30px auto;
	}
	.menu-icon {
		width: 40px;
		height: 40px;
		line-height: 40px;
		font-size: 26px;
		
	}
	.lnch.d-flex.justify-content-sm-center {
		margin-right: auto;
		margin-left: auto;
	}
  	.slide-content.row.text-center {
		top: 16%;
	  }
	.site-title h2 {
		font-size: 36px;
	}
	
  .bengal-logo-area img {
    width: 130px;
    text-align: center;
    margin: 30px auto;
  }
	.swiper-slide.slide-center img {
    height: 100%;
    width: 100%;
  }
  .swiper-slide.slide-center {
    height: 150px;
  }
  .bdtittle_area{
    top: calc(50% - 59px);
  }
  #video_block_1 .video-inner{
    padding: 100px 0px;
  }
  
  
}
/* Medium devices (tablets, 768px and up)*/
@media only screen and (max-width: 767px) and (min-width: 576px)  {
  .bengal-title{
    top: calc(50% - 105px + 10px);
  }
	a.mainlogo{
    display: block;
    width: 130px;
  }
  .navbar-toggler{
    font-size: 22px;
  }
  
  .slide-content.row.text-center{
    top: 29%;
  }
  #video_block_1 .video-inner{
    padding: 150px 0px;
  }
}
/* Medium devices (tablets, 768px and up)*/
@media only screen and (max-width: 991px) and (min-width: 768px)  {
	.menu_area > li > a {
		font-size: 24px;
	}
  .slide-content.row.text-center {
		top: 30%;
	}
  
	
}
/* Large devices (desktops, 992px and up)*/
@media only screen and (max-width: 1199px) and (min-width: 992px)  {
	.menu_area > li > a {
		font-size: 24px;
	}
  	.slide-content.row.text-center {
		top: 30%;
	}
	
}
/* Extra large devices (large desktops, 1200px and up)*/




</style>
