
import Home from './components/home.vue'
import Aboutus from './components/aboutus/aboutus.vue'
import MainCategory from './components/category/main_category.vue'
import BusinessCategory from './components/category/business_category.vue'
import PageCategory from './components/page/page.vue'
import Achievement from './components/achievement/achievement.vue'
import AchieveDetails from './components/achievement/achieve_details.vue'
import Responsibility from './components/responsibility/responsibility.vue'
import Career from './components/career/career.vue'
import News from './components/news/news.vue'
import NewsDetails from './components/news/news_details.vue'
import Contact from './components/contactus/contactus.vue'
import Gallery from './components/footer_img.vue'


export const routes = [
    { path: '/', component: Home, name: 'home' },
    { path: '/about-us', component: Aboutus, name: 'about-us' },
    { path: '/category', component: MainCategory, name: 'category' },
    { path: '/business-category/:id', component: BusinessCategory, name: 'business-category' },
    { path: '/page-category/:id', component: PageCategory, name: 'page-category' },
    { path: '/achievement', component: Achievement, name: 'achievement' },
    { path: '/achievement-details/:id', component: AchieveDetails, name: 'achievement-details' },
    { path: '/responsibility', component: Responsibility, name: 'responsibility' },
    { path: '/career', component: Career, name: 'career' },
    { path: '/news', component: News, name: 'news' },
    { path: '/news-details/:id', component: NewsDetails, name: 'news-details' },
    { path: '/contactus', component: Contact, name: 'contactus' },
    { path: '/gallery', component: Gallery, name: 'gallery' },
    
]