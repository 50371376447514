<template>
    <div>
        <!--<page-loader></page-loader>--> 
        <section class="csr_area">
          <div class="container">
            <div class="row">
              <div class="col-md-4 col-sm-12 csrleft">
                <div class="title_des">
                  <h3>{{ responsibility.title }}</h3>
                  <!-- <h3>Our intention to ensure Corporate Social Responsibility (CSR) helps us to be socially accountable, corporate citizens. </h3> -->
                  <p>{{ responsibility.desc }}</p>
                  <!-- <p>We are aware of the impact we're having on all aspects of society, including economic, social, and environmental.</p> -->
                </div>
              </div>
              <div class="col-md-8 col-sm-12 p-0">
                <div class="csrtop">
                  <div class="csrall-area flex-column flex-lg-row justify-content-lg-center panel">
                    <!-- <div v-for="d in commitDtls" :key="d.id" class="csr_area d-flex">
                      <div class="csr_icon">
                        <img src="/images/education.png" alt="">
                        <i :class="d.icon"></i>
                      </div>
                      <div class="csr_description">
                        <h3>{{ d.title }}</h3>
                        <p>{{ d.desc }}</p>
                      </div>
                    </div> -->
                    <div v-for="d in details" :key="d.id" class="csr_area d-flex">
                      <div class="csr_icon">
                        <img v-lazy="url + d.image" alt="">
                        <!--<img src="/images/csr/found.png" alt="">-->
                        <!-- <i class="fas fa-book-open"></i> -->
                      </div>
                      <div class="csr_description">
                        <h3>{{d.title}}</h3>
                        <p>{{d.desc}}</p>
                      </div>
                    </div>
                    <!--<div class="csr_area d-flex">-->
                    <!--  <div class="csr_icon">-->
                    <!--    <img src="/images/csr/education.png" alt="">-->
                        <!-- <i class="fas fa-book-open"></i> -->
                    <!--  </div>-->
                    <!--  <div class="csr_description">-->
                    <!--    <h3>{{csr_two}}</h3>-->
                    <!--    <p>{{csr_d_two}}</p>-->
                    <!--  </div>-->
                    <!--</div>-->
                    <!--<div class="csr_area d-flex">-->
                    <!--  <div class="csr_icon">-->
                    <!--    <img src="/images/csr/religion.png" alt="">-->
                        <!-- <i class="fas fa-book-open"></i> -->
                    <!--  </div>-->
                    <!--  <div class="csr_description">-->
                    <!--    <h3>{{csr_three}}</h3>-->
                    <!--    <p>{{csr_d_three}}</p>-->
                    <!--  </div>-->
                    <!--</div>-->
                  </div>
                  <!-- <div class="csrall-area d-flex flex-column flex-lg-row justify-content-lg-center panel">
                    <div class="csr_area d-flex">
                      <div class="csr_icon">
                        <i class="fas fa-book-open"></i>
                      </div>
                      <div class="csr_description">
                        <h3>Eduction</h3>
                        <p>Every year generosity thousands children across Australia recover form the trauma of abuse neglect</p>
                      </div>
                    </div>
                    <div class="csr_area d-flex">
                      <div class="csr_icon">
                        <i class="fas fa-book-open"></i>
                      </div>
                      <div class="csr_description">
                        <h3>Eduction</h3>
                        <p>Every year generosity thousands children across Australia recover form the trauma of abuse neglect</p>
                      </div>
                    </div>
                  </div>  -->
                </div>
              </div>
            </div>
          </div>
        </section>
        
        
        <section class="csr-imgarea">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="site-title ">
                  <h4 class="sub-title">
                    {{titleArea.heading}}
                    <!-- {{ commit.title_one }} -->
                  </h4>
                  <h2>
                    {{titleArea.title}}
                    <!-- {{ commit.desc_one }} -->
                  </h2>
                </div>
              </div>
            </div>
            <div class="row">
              <div v-for="act in activities" :key="act.id" class="col-md-4 col-sm-6 col-12 single_activies">
                  <div class="csr-img">
                    <img v-lazy="url + act.image">
                  </div>
                   <h3 class="csr">{{act.link}}</h3>
              </div>
              
            </div>
          </div>
        </section>

    </div>
</template>

<script>
import axios from 'axios'
export default {
  props: ['ul'],
  created() {
        if(this.ul.lang == 'English'){
            this.commonWork()
        }else if(this.ul.lang == 'Arabic'){
            this.commonWork()
        }else if(this.ul.lang == 'French'){
            this.commonWork()
        }else{
            this.getData()
        }

        this.$root.$on('english', (e) => {
            this.form.lang = e
            this.commonWork()
        })

        this.$root.$on('french', (e) => {
            this.form.lang = e
            this.commonWork()
        })

        this.$root.$on('arabic', (e) => {
            this.form.lang = e
            this.commonWork()
        })

    },
    data() {
        return {
            url: this.ul.url,
            responsibility: {},
            details: [],
            titleArea: {},
            activities: [],
            form: {
                lang: this.ul.lang
            }
        }
    },
    methods: {
        getData(){
            this.form.lang = 'English'
            this.commonWork()
        },
        commonWork(){
            axios.get(this.url + 'api/getResponsibilitys', {params: this.form})
            .then(({data}) => {
                this.responsibility = data.responsibility
                this.details = data.details
                this.titleArea = data.title
                this.activities = data.activities
            })
        }
    }
}
</script>


<style scoped>
/* Nomalise*/
img {
	max-width: 100%;
}
.navbar.navbar-expand-lg.navbar-light.bg-light {
	margin: 0;
	padding: 0;
}
hr {
	margin: 0;
}
body {
    font-family: 'Rubik', sans-serif;
    overflow-x: hidden;
}
 h3.csr {
    font-family: 'Rubik';
    margin-top: 20px;
    color: #222;
}
.csr_description{
    padding-right: 20px;
  }
  .panel {
    margin-bottom: 0px;
  }
/* .csr_area {
    padding: 20px;
} */
.single_activies {
    text-align: center;
}
/** Business time line Area  Start **/

@media (min-width: 576px) and (max-width: 767) { 
	.single_activies:nth-child(3), .single_activies:nth-child(4) {
    margin-top: 60px;
  }
}
/* Medium devices (tablets, 768px and up)*/
@media only screen and (max-width: 575px) and (min-width: 320px)  {
  .site-title h2{
    font-size: 30px !important;
  }
  
	.csr_area .container::after{
    display: none;
  }
  .single_activies {
    margin-bottom: 70px;
  }
  .csr-img::after{
    left: 8% !important;
    width: 76% !important;
  }
  .csr_icon {
    margin-right: 15px !important;
  }
  .title_des{
    padding-top: 30px !important;
    padding-right:0 !important;
    padding-left:0 !important;
    padding-bottom: 30px;
  }
  .csrtop{
    padding: 0 !important;
  }
  
}
/* Medium devices (tablets, 768px and up)*/
@media only screen and (max-width: 767px) and (min-width: 576px)  {
  
	.csr_area .container::after{
    display: none;
  }
  .single_activies {
    margin-bottom: 70px;
  }
  

}
/* Medium devices (tablets, 768px and up)*/
@media only screen and (max-width: 991px) and (min-width: 768px)  {
  .csr_area .container::after{
    display: none;
  }
  .single_activies:nth-child(3), .single_activies:nth-child(4) {
    margin-top: 60px;
  }

  
	
}
/* Large devices (desktops, 992px and up)*/
@media only screen and (max-width: 1199px) and (min-width: 992px)  {
	csr_area .container::after{
    width: 2%;
  }
	
}
/* Extra large devices (large desktops, 1200px and up)*/

@media only screen and (max-width: 1600px) and (min-width: 1200px)  {
	.csr-img::after {
    width: 88%;
    left: -15px;
  }
}
/** Business time line Area END **/
.site-title {
	text-align: center;
	margin-bottom: 60px;
}
.site-title h4 {
	font-size: 18px;
	position: relative;
	color: #0070ba;
	display:inline-block;
  font-family: 'Rubik';
}
.site-title h2 {
	font-size: 48px;
	color: #222;
  font-family: 'Rubik';
}
.site-title h4::before {
	position: absolute;
	width: 63px;
	height: 2px;
	background: #0070ba;
	top: 10px;
	left: 110%;
	content: "";
}
.site-title h4::after {
    position: absolute;
    width: 63px;
    height: 2px;
    background: #0070ba;
    top: 10px;
    content: "";
    left: -76px;
}
.achi-details {
	padding-left: 20px;
}
.achi-details a {
	color: #0e75bc;
	font-weight: bold;
	font-size: 16px;
	display: block;
}
.site-title h4::before {
	position: absolute;
	width: 63px;
	height: 2px;
	background: #0070ba;
	top: 10px;
	left: 110%;
	content: "";
}
.preneext > div {
	border: 1px solid #777;
	width: 60px;
	font-size: 36px;
	height: 60px;
	text-align: center;
	line-height: 60px;
}
.preneext .right {
	margin-right: 20px;
}
.preneext.d-flex {
	margin-right: auto;
	margin-left: auto;
	margin-top: 40px;
}
.csr-img {
	position: relative;
}
.csr-img::after {
	content: "";
	width: 100%;
	height: 100%;
	border: 10px solid #0070ba;
	position: absolute;
	left: -5%;
	bottom: 9%;
	z-index: -1;
}
.csr-imgarea{
	padding: 100px 0px;
}
   /** Business CSR Area  Start **/

.csr_area .container {
	position: relative;
}
.csr_area .container::before,
.csr_area .container::after {
	content: "";
	height: 100%;
	width: 100%;
	position: absolute;
}
.csrleft{
	background: #232121;
}
.csr_area .container::before {
	background: #232121;
	right: 100%;
	top: 0;
}
.csr_area .container::after {
	background: #eeeeee;
	left: 100%;
	top: 0;
}
.csr_icon i {
	font-size: 24px;
	color: #004ea0;
}
.title_des {
	/* padding: 30px; */
	position: relative;
	padding-top: 60px;
	padding-right: 30px;
	padding-left: 30px;
}
.csr_description h3 {
    font-family: 'Rubik';
}
.csr_icon {
	width: 56px;
	height: 56px;
	/* border: 1px solid #0070ba; */
	text-align: center;
	/* border-radius: 50%; */
	line-height: 62px;
	margin-right: 30px;
}
.csrtop {
	background: #eeeeee;
	padding: 30px;
	position: relative;
}
.csrtop::before {
	background: #eeeeee;
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 999;
}
.title_des h3 {
	color: #0070ba;
  font-family: 'Rubik';
}
.csr_description p {
	color: #777;
  font-family: 'Rubik';
}
.title_des p {
	color: #fff;
	margin-top: 20px;
  font-family: 'Rubik';
}
.csr_description {
    width: 100%;
}

/** Business CSR Area END **/ 
</style>