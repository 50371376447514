<template>
    <div>
    <div class="container">
      
      <div class="row gallery_area">
        <div class="column" v-for="(img, index) in images" :key="'A'+ img.id" >
          <img v-lazy="url + img.image" style="width:100%" @click.prevent="openModal();currentSlide(index)" class="hover-shadow cursor">
        </div>
      </div>
     
    
    <div id="myModal" class="modal">
      <span class="close cursor" @click.prevent="closeModal()">&times;</span>
      <div class="modal-content">
    
        <div class="mySlides" v-for="(img, index) in images" :key="'B'+ img.id">
          <div class="numbertext">{{index + 1}} / {{images.length}}</div>
          <img v-lazy="url + img.image" style="width:100%" alt="">
        </div>
        <a class="prev" @click.prevent="plusSlides(-1)">&#10094;</a>
        <a class="next" @click.prevent="plusSlides(1)">&#10095;</a>
    
        <div class="caption-container">
          <p id="caption"></p>
        </div>
    
    
        <div class="column" v-for="img in images" :key="'C'+ img.id">
          <img class="demo cursor" v-lazy="url + img.image" style="width:100%; height:56px" @click.prevent="currentSlide(1)" :alt="img.caption">
        </div>
      </div>
    </div>
        
      </div>

    </div>
    
</template>



<script>
import $ from 'jquery'
import axios from 'axios'
export default {
    props: ['ul'],
    created(){
        axios.get(this.url + 'api/getGallery')
        .then(({data}) => {
            this.images = data
        })
    },
    data() {
        return {
            url: this.ul.url,
            isModalVisible: false,
            images: [],
            form: {
                lang: '',
                id: '',
                api: ''
            },
            slideIndex: 1,
            page: 1,
        }
    },
    methods: {
      next(){
          this.page = this.page + 1
          axios.get(this.url + 'api/getImages?page=' + this.page, {params: this.form})
          .then(({data}) => {
              this.images = data.data
          })
      },
      prev(){
          this.page = this.page - 1
          axios.get(this.url + 'api/getImages?page=' + this.page, {params: this.form})
          .then(({data}) => {
              this.images = data.data
          })
      },
      openModal() {
        document.getElementById("myModal").style.display = "block";
      },

      closeModal() {
        $("#myModal").hide(); 
      },
      plusSlides(n) {
        this.showSlides(this.slideIndex += n);
      },

      currentSlide(n) {
        this.showSlides(this.slideIndex = n+1);
      },
      showSlides(n){
        let i;
        let slides = document.getElementsByClassName("mySlides");
        let dots = document.getElementsByClassName("demo");
        let captionText = document.getElementById("caption");
        if (n > slides.length) {this.slideIndex = 1}
        if (n < 1) {this.slideIndex = slides.length}
        for (i = 0; i < slides.length; i++) {
            slides[i].style.display = "none";
        }
        for (i = 0; i < dots.length; i++) {
            dots[i].className = dots[i].className.replace(" active", "");
        }
        slides[this.slideIndex-1].style.display = "block";
         dots[this.slideIndex-1].className += " active";
         captionText.innerHTML = dots[this.slideIndex-1].alt;
      }

    },
}
</script>

<style scoped>
 img {
    max-width: 100%;
}
* {
  box-sizing: border-box;
}

.row > .column {
  padding: 0 8px 16px;
}
.row.gallery_area {
    margin-bottom: 60px;
}
.row:after {
  content: "";
  display: table;
  clear: both;
}

.column {
  float: left;
  width: 25%;
}

/* The Modal (background) */
.modal {
  display: none;
  position: fixed;
  z-index: 999;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: black;
}

/* Modal Content */
.modal-content {
  position: relative;
  background-color: #fefefe;
  margin: auto;
  padding: 0;
  width: 90%;
  max-width: 1200px;
}

/* The Close Button */
.close {
  color: white;
  position: absolute;
  top: 100px;
  right: 50px;
  font-size: 35px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #999;
  text-decoration: none;
  cursor: pointer;
}

.mySlides {
  display: none;
}

.cursor {
  cursor: pointer;
}

/* Next & previous buttons */
.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  margin-top: -50px;
  color: white;
  font-weight: bold;
  font-size: 20px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
  -webkit-user-select: none;
}

/* Position the "next button" to the right */
.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover,
.next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

/* Number text (1/3 etc) */
.numbertext {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}

img {
  margin-bottom: -4px;
}

.caption-container {
  text-align: center;
  
  padding: 2px 16px;
  color: white;
  position: absolute;
  bottom: 27px;
  left: 0;
  width: 100%;
  height: 61px;
  background-color: rgba(0,0,0, .6);
}
.caption-container p {
    font-size: 20px;
    font-weight: 400;
    font-family: 'Rubik';
    line-height: 50px;
}

.demo {
  opacity: 0.6;
}

.active,
.demo:hover {
  opacity: 1;
}

img.hover-shadow {
  transition: 0.3s;
}

.hover-shadow:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
/* Gallery Image*/
.column .demo {
    display: none;
}
.close {
      color: #000;
      z-index: 9;
      width: 37px;
      text-align: center;
      background:rgba(255, 255, 255, 0.6);
    }
    a.prev, a.next {
    background: rgb(255, 255,255, .6);
}
.previous_timelin {
  border-radius: 50px 0 0  50px;
  margin-right: 20px;
}
.next_timelin {
  border-radius: 0 50px 50px 0px;
  margin-left: 20px;
}
.previous_timelin a{
	color: #fff;
    font-family: 'Rubik';
    display: block;
}
.next_timelin a{
  color: #fff;
  font-family: 'Rubik';
  display: block;
}
.previous_timelin, .next_timelin{
    background: #0070ba;
    color: #fff;
    width: 90px;
    height: 50px;
    line-height: 50px;
    text-align: center;
}
.prenext {
    margin-bottom: 120px;
}

@media only screen and (max-width: 575px) and (min-width: 320px)  {
.row.gallery_area {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .close {
    color: #000;
    z-index: 9;
    width: 37px;
    text-align: center;
    background:rgba(255, 255, 255, 0.6);
  } 

}
@media only screen and (max-width: 767px) and (min-width: 576px)  {
   .close {
    color: #000;
    z-index: 9;
    width: 37px;
    text-align: center;
    background:rgba(255, 255, 255, 0.6);
  }
  .row.gallery_area {
    margin-top: 40px;
  }
  

}
@media only screen and (max-width: 991px) and (min-width: 768px)  {
  .close {
    color: #000;
    z-index: 9;
    width: 37px;
    text-align: center;
    background:rgba(255, 255, 255, 0.6);
  }
  
}
@media only screen and (max-width: 1199px) and (min-width: 992px)  {
    
  }

</style>