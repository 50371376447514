<template>
    <div>
         <!-- main-footer -->
  <footer class="main-footer">
    <div class="container">
      <div class="row">
        <div class="footer-top">
            <div class="widget-section">
                <div class="row clearfix">
                    <div class="col-lg-4 col-sm-4 col-12 footer-column">
                        <div class="footer-widget logo-widget">
                            <figure class="footer-logo"><a href>
                              <img :src="url + footerImage" alt="">
                              <!-- <img src="/images/footerlogo.png" alt=""> -->
                              </a>
                              </figure>
                            <div class="text">
                                <p>{{ content.desc }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-4 col-12 footer-column">
                        <div class="footer-widget contact-widget">
                            <ul class="info clearfix">
                                <li>
                                  <i class="fas fa-phone"></i>
                                    <p v-if="enableEnglish == true">Call Us</p>
                                    <p v-if="enableArabic == true">اتصل بنا</p>
                                    <p v-if="enableFrench == true">Appelez-nous</p>
                                    <h5><a style="cursor:pointer" href>{{content.callus}}</a></h5>
                                </li>
                                <li>
                                    <i class="fas fa-map-marker-alt"></i>
                                    <p v-if="enableEnglish == true">Address</p>
                                    <p v-if="enableArabic == true">تبوك</p>
                                    <p v-if="enableFrench == true">Adresse</p>
                                    <!-- <h5>Bengal House<br> 75 Gulshan Avenue<br> Gulshan 1<br> Dhaka 1212 Bangladesh</h5> -->
                                    <h5>{{content.addrs}}</h5>
                                </li>
                            </ul>
                            <ul class="social-links clearfix"> 
                                <li>
                                  <a :href="href_one" target="_blank"><i :class="icon_one"></i></a>
                                </li>
                                <li>
                                  <a :href="href_two" target="_blank"><i :class="icon_two"></i></a>
                                </li>
                                <!-- <li><a href="https://www.linkedin.com/company/bengalgroupbd" target="_blank"><i class="fab fa-linkedin-in"></i></a></li> -->
                                
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-4  col-sm-4 col-12 footer-column">
                        <div class="footer-widget newsletter-widget">
                            <div class="widget-title">
                                <h6 v-if="enableEnglish == true">Photo Gallery</h6>
                                <h6 v-if="enableArabic == true">معرض الصور</h6>
                                <h6 v-if="enableFrench == true">Galerie de photos</h6>
                            </div>
                            <div class="gallery-item">
                                <div class="gallerytop d-flex">
                                  <div class="single-gallery">
                                    <img @click.prevent="clkCarousel" :src="url + image_one" alt="Photo Gallery">
                                  </div>
                                  <div class="single-gallery">
                                    <img @click.prevent="clkCarousel" :src="url + image_two" alt="Photo Gallery">
                                  </div>
                                </div>
                                 <div class="gallerybottom d-flex">
                                  <div class="single-gallery">
                                    <img @click.prevent="clkCarousel" :src="url + image_three" alt="Photo Gallery">
                                  </div>
                                  <div class="single-gallery">
                                    <img @click.prevent="clkCarousel" :src="url + image_four" alt="Photo Gallery">
                                  </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-bottom d-flex">
            <div class="col-md-6 col-lg-6 col copyright">
              <!-- <h5>&copy;2021 Copyright <a href="#">Bengal Group Of Industries</a> All rights reserved.</h5> -->
              <h5>{{ content.cr }}</h5>
            </div>
            <ul v-if="enableEnglish == true" class="col-md-6 col-lg-6 col footer-nav ">
                <li><a @click.prevent="homeClick" href>Home</a></li>
                <li><a @click.prevent="aboutClick(1, 'menu')" href>About</a></li>
                <li><a @click.prevent="blogClick(6, 'menu')"  href>News & Events</a></li>
                <li><a @click.prevent="contactClick(9, 'menu')" href>Contact</a></li>
            </ul>
            <ul v-if="enableArabic == true" class="col-md-6 col-lg-6 col footer-nav ">
                <li><a @click.prevent="homeClick" href>الصفحة الرئيسية</a></li>
                <li><a @click.prevent="aboutClick(8, 'menu')" href>حول</a></li>
                <li><a @click.prevent="blogClick(16, 'menu')"  href>الأخبار و الأحداث</a></li>
                <li><a @click.prevent="contactClick(17, 'menu')" href>اتصال</a></li>
            </ul>
            <ul v-if="enableFrench == true" class="col-md-6 col-lg-6 col footer-nav ">
                <li><a @click.prevent="homeClick" href>Domicile</a></li>
                <li><a @click.prevent="aboutClick(21, 'menu')" href>À propos de</a></li>
                <li><a @click.prevent="blogClick(26, 'menu')"  href>Nouvelles et événements</a></li>
                <li><a @click.prevent="contactClick(27, 'menu')" href>Contact</a></li>
            </ul>
        </div>
      </div>
    </div>
  </footer>
  <!-- main-footer end -->

    </div>
</template>

<script>
  export default {
    props: ['ul'],
    created() {
        this.getIcon()
        this.getData()

     this.$root.$on('english', (e) => {
        this.form.lang = e
        this.commonWork()
		this.enableEnglish = true
		this.enableArabic = false
		this.enableFrench = false
      })

      this.$root.$on('french', (e) => {
        this.form.lang = e
        this.commonWork()
		this.enableEnglish = false
		this.enableArabic = false
		this.enableFrench = true
      })

      this.$root.$on('arabic', (e) => {
        this.form.lang = e
        this.commonWork()
		this.enableEnglish = false
		this.enableArabic = true
		this.enableFrench = false
      })


    },
    data () {
      return {
        url: this.ul.url,
        enableEnglish: true,
		    enableArabic: false,
		    enableFrench: false,
        content: {},
        images: [],
        form: {
            lang: ''
        },
        footerImage: '',
        socials: [],
        image_one:'',
        image_two:'',
        image_three:'',
        image_four:'',
        href_one: '',
        href_two: '',
        icon_one: '',
        icon_two: '',
        dataF: {
          name: '',
          id: '',
          category: 'menu'
        }
      }
    },
    methods: {
      getData(){
        if(!this.ul.lang){
          this.form.lang = 'English'
          this.enableEnglish = true
          this.enableArabic = false
          this.enableFrench = false
          this.commonWork()
        }else if(this.ul.lang == "Arabic"){
          this.form.lang = this.ul.lang
          this.enableEnglish = false
          this.enableArabic = true
          this.enableFrench = false
          this.commonWork()
        }else{
          this.form.lang = this.ul.lang
          this.enableEnglish = false
          this.enableArabic = false
          this.enableFrench = true
          this.commonWork()
        }
      },
      commonWork(){
         this.$http.get(this.url + 'api/getFooter', {params: this.form})
          .then(({data}) => {
            this.content = data.content
            this.images = data.images
            this.footerImage = data.logo.image
            this.images.forEach((value, index) => {
              if(index == 0){
                this.image_one = value.image
              }else if(index == 1){
                this.image_two = value.image
              }else if(index == 2){
                this.image_three = value.image
              }else{
                this.image_four = value.image
              }
            });
          })
      },
      getIcon(){
        this.$http.get(this.url + 'api/getSocial')
            .then(({data}) => {
              this.socials = data
              this.href_one = this.socials[0].href
              this.href_two = this.socials[1].href
              this.icon_one = this.socials[0].icon
              this.icon_two = this.socials[1].icon
            })
      },
      homeClick(){
        this.$root.$emit('footer_menu', 'home')
      },
      aboutClick(id){
        this.dataF.name = 'aboutus'
        this.dataF.id = id
        this.$root.$emit('footer_menu', this.dataF)
      },
      blogClick(id){
        this.dataF.name = 'news'
        this.dataF.id = id
        this.$root.$emit('footer_menu', this.dataF)
      },
      contactClick(id){
        this.dataF.name = 'contactus'
        this.dataF.id = id
        this.$root.$emit('footer_menu', this.dataF)
      },
      
      clkCarousel(){
        if(this.enableArabic == true){
          this.$root.$emit('footerA')
        }else if(this.enableFrench == true){
          this.$root.$emit('footerF')
        }else{
          this.$root.$emit('footer')
        }
        
      }
    }
  }
</script>


<style scoped>
    /** main-footer **/
    img {
    	max-width: 100%;
	}
.main-footer{
	position: relative;
	background: #1c1d1f;
	margin-top: 30px;
  }
  
  .main-footer .footer-top{
	position: relative;
	padding: 60px 0px 100px 0px;
	border-bottom: 2px solid rgba(255,255,255,0.1);
  }
  
  .main-footer .footer-top .logo-widget .footer-logo{
	position: relative;
	margin-bottom: 30px;
  }
  
  .main-footer .footer-top .logo-widget p{
	font-size: 14px;
	line-height: 30px;
	color: #fff;
	margin: 0px;
  font-family: 'Rubik';
  }
  
  .main-footer .footer-top .contact-widget{
	position: relative;
	display: block;
	padding: 48px 30px 50px 35px;
	border-radius: 5px;
	margin-top: -103px;
  }
  
  .main-footer .footer-top .contact-widget .info li{
	position: relative;
	display: block;
	padding-left: 73px;
	margin-bottom: 40px;
  }
  
  .main-footer .footer-top .contact-widget .info li:last-child{
	margin-bottom: 0px;
  }
  
  .main-footer .footer-top .contact-widget .info li .svg-inline--fa.fa-w-16, .svg-inline--fa.fa-w-12{
	position: absolute;
	left: 0px;
	top: 11px;
	font-size: 40px;
	line-height: 40px;
	color: #fff;
  }
  
  .main-footer .footer-top .contact-widget .info li p{
	color: #fff;
	margin-bottom: 5px;
  font-family: 'Rubik';
  font-size: 20px;
  }
  
  .main-footer .footer-top .contact-widget .info li h5{
	position: relative;
	display: block;
	font-size: 18px;
	line-height: 26px;
	color: #fff;
	font-weight: 400;
	margin: 0px;
  font-family: 'Rubik';
  }
  
  .main-footer .footer-top .contact-widget .info li h5 a{
	display: inline-block;
	color: #fff;
  }
  
  .main-footer .footer-top .contact-widget .info li h5 a:hover{
	text-decoration: underline;
  }
  
  .main-footer .footer-top .contact-widget .info{
	position: relative;
	margin-bottom: 57px;
  }
  
  .main-footer .footer-top .contact-widget .social-links li{
	position: relative;
	display: inline-block;
	
	margin-right: 10px;
  }
  
  .main-footer .footer-top .contact-widget .social-links li:last-child{
	margin-right: 0px;
  }
  
  .main-footer .footer-top .contact-widget .social-links li a{
	position: relative;
	display: inline-block;
	font-size: 15px;
	color: #fff;
	width: 42px;
	height: 42px;
	line-height: 42px;
	text-align: center;
	border-radius: 50%;
	border: 1px solid rgba(255,255,255,0.2);
  }
  .main-footer .footer-top .contact-widget {
	background: #003087;
  }
  .main-footer .footer-top .contact-widget .social-links li a:hover{
	border-color: #003087;
	background: #fff;
  }
  .main-footer .footer-top .contact-widget .social-links li a:hover {
	color: #003087;
}
  .main-footer .footer-top .widget-title{
	position: relative;
	margin-bottom: 20px;
  }
  
  .main-footer .footer-top .widget-title h6{
	position: relative;
	display: block;
	font-size: 16px;
	font-weight: 700;
	color: #fff;
	margin: 0px;
  font-family: 'Rubik';
  }
  
  .main-footer .footer-top .newsletter-widget{
	position: relative;
	margin-left: 70px;
  }
  
  .main-footer .footer-top .newsletter-widget .widget-content p{
	font-size: 14px;
	line-height: 30px;
	color: #fff;
	margin-bottom: 19px;
  }
  
  .main-footer .footer-top .newsletter-widget .newsletter-form .form-group{
	position: relative;
	margin: 0px;
  }
  .single-gallery {
	margin-bottom: 2px;
	margin-right: 2px;
  cursor: pointer;
}

  .main-footer .footer-top .newsletter-widget{
	position: relative;
	margin-top: 46px;
  }
  
  .main-footer .footer-bottom{
	position: relative;
	padding: 64px 0px;
  }
  
  .main-footer .footer-bottom .copyright h5{
    position: relative;
    font-size: 18px;
    line-height: 32px;
    color: #bbbbbc;
    font-family: 'Rubik';
    font-weight: 400;
    margin-top: 0;
  }
  
  .main-footer .footer-bottom .copyright h5 a{
	display: inline-block;
	color: #bbbbbc;
  font-family: 'Rubik';
  }
  
  
  
  .main-footer .footer-bottom .footer-nav li{
	position: relative;
	display: inline-block;
	font-size: 18px;
	color: #bbbbbc;
	margin-right: 35px;
  }
  
  .main-footer .footer-bottom .footer-nav li:last-child{
	margin-right: 0px;
  }
  
  .main-footer .footer-bottom .footer-nav li a{
	color: #bbbbbc;
  font-family: 'Rubik';
  font-weight: 18;
  
  }
  
  .footer-widget.contact-widget ul {
	padding: 0;
	margin: 0;
  }
  .col-sm-6.footer-nav.pull-right.justify-content-md-end {
	text-align: right;
}
  .main-footer .footer-bottom .footer-nav li:before{
	position: absolute;
	content: '';
	background: #bbbbbc;
	width: 1px;
	height: 15px;
	top: 8px;
	right: -20px;
  }
  
  .main-footer .footer-bottom .footer-nav li:last-child:before{
	display: none;
  }
  ul.col-md-6.col.footer-nav {
    text-align: right;
  }
.footer-bottom {
        width: 100%;
    }
  /* Medium devices (tablets, 768px and up)*/
@media only screen and (max-width: 480px) and (min-width: 320px)  {
	.site-title h2 {
		font-size: 36px;
	}
  .single-gallery img {
    max-width: 100%;
  }
  .main-footer .footer-top .newsletter-widget{
    margin-left: 10px;
  }
  .main-footer .footer-top .contact-widget{
    margin-top: 40px;
    }
  .footer-bottom.d-flex {
    display: block !important;
    text-align: center;
  }
  .footer-nav{
    text-align: center !important;
  }
  .gallerytop.d-flex {
    text-align: center !important;
    display: inherit !important;
  }
  .single-gallery{
    display: inline-block;
    margin-right: 8px;
  }
  .gallerybottom.d-flex {
    text-align: center !important;
    display: inherit !important;
    margin-top: 10px;
  }
  
  
  
  .main-footer .footer-top .logo-widget .footer-logo{
    text-align: center;
  }
  .footer-widget.logo-widget .text {
    padding: 10px;
  }
  .main-footer .footer-bottom .footer-nav li{
    margin-right: 10px;
  }
  .main-footer .footer-bottom .footer-nav li:before {
    top: 8px;
    right: -5px;
  }
  ul.col-md-6.col.footer-nav[data-v-585a4226] {
    text-align: left;
    margin-top: 20px;
}
  .main-footer .footer-top .contact-widget .info li i{
    font-size: 30px;
  }
  .main-footer .footer-top .contact-widget .info li{
    padding-left: 48px;
  }
  .main-footer .footer-top .contact-widget 
  .info li .svg-inline--fa.fa-w-16[data-v-6c4d8baa], 
  .svg-inline--fa.fa-w-12[data-v-6c4d8baa] {
   font-size: 26px;
  }
 
.main-footer .footer-top .logo-widget .footer-logo{
    margin-bottom: 0;
  }
}
@media only screen and (max-width: 575px) and (min-width: 481px)  {

  .footer-bottom ul {
    display: none;
  }
  figure.footer-logo {
    text-align: center;
  }
  .main-footer .footer-top .contact-widget{
    margin-top: 40px;
  }
  .footer-widget.logo-widget .text {
    padding-left: 40px;
  }
  
}
/* Medium devices (tablets, 768px and up)*/
@media only screen and (max-width: 767px) and (min-width: 576px)  {
  .main-footer .footer-top .contact-widget 
  .info li .svg-inline--fa.fa-w-16[data-v-6c4d8baa], 
  .svg-inline--fa.fa-w-12[data-v-6c4d8baa] {
   font-size: 26px;
  }
	.main-footer .footer-top .logo-widget .footer-logo img{
    width:80%
  }
  .main-footer .footer-bottom .footer-nav li:before{
    display: none;
  }
  .main-footer .footer-top .newsletter-widget{
    margin-top: 0;
    margin-left: 0;
  }
  .main-footer .footer-top .contact-widget{
      padding: 48px 10px 10px 10px;
  }
  .main-footer .footer-top .contact-widget .info li h5{
    font-size: 14px;
  }
  .main-footer .footer-top .contact-widget .info li i{
      font-size: 24px;
  }
  .main-footer .footer-top .contact-widget .info li{
    padding-left: 42px;
  }
  .container {
    max-width: 576px !important;
  }
  .footer-widget.logo-widget {
    margin-left: 15px;
  }
}
/* Medium devices (tablets, 768px and up)*/
@media only screen and (max-width: 991px) and (min-width: 768px)  {
  .main-footer .footer-top .contact-widget 
  .info li .svg-inline--fa.fa-w-16[data-v-6c4d8baa], 
  .svg-inline--fa.fa-w-12[data-v-6c4d8baa] {
   font-size: 26px;
  }
	.main-footer .footer-bottom .footer-nav li{
    margin-right: 10px;
  }
  .main-footer .footer-top .logo-widget .footer-logo img{
    width:80%
  }
  .main-footer .footer-bottom .footer-nav li:before{
    display: none;
  }
  .main-footer .footer-top .newsletter-widget{
    margin-top: 0;
    margin-left: 0;
  }
  .main-footer .footer-top .contact-widget{
      padding: 48px 20px 20px 20px;
  }
  .main-footer .footer-top .contact-widget .info li h5{
    font-size: 16px;
  }
  .main-footer .footer-top .contact-widget .info li i{
      font-size: 30px;
  }
  .main-footer .footer-top .contact-widget .info li{
    padding-left: 42px;
  }

  


}
/* Large devices (desktops, 992px and up)*/
@media only screen and (max-width: 1199px) and (min-width: 992px)  {
	.single-gallery img {
    width: 110px;
  }
  .footer-bottom {
        width: 100%;
    }
	
}
/* Extra large devices (large desktops, 1200px and up)*/

@media only screen and (max-width: 1600px) and (min-width: 1200px)  {
	.footer-bottom {
        width: 100%;
    }
}
</style>
