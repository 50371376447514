<template>
    <div>
           <section class="carrer-area">
        <div class="container">
            <div class="row featured-job-area">
              
              <div class="col-md-12">
                <div class="career-tittle">
                  <h2 v-if="enableEnglish == true">Find The Job That Fits Your Career</h2>
                  <h2 v-if="enableArabic == true">ابحث عن الوظيفة التي تناسب حياتك المهنية</h2>
                  <h2 v-if="enableFrench == true">Trouvez l'emploi qui correspond à votre carrière</h2>
                </div>
              </div>
               <div class="col-md-12">
                <div class="job-title-area d-flex align-items-center">
                  <div class="btl-area d-flex">
                    <div class="job-title-option">
                        <select @change="changeLabel" v-model="form.job_title_id" required >
                          <option v-if="enableEnglish == true" disabled value="">Please select one</option>
                          <option v-if="enableArabic == true" disabled value="">رجاءا اختر واحدة</option>
                          <option v-if="enableFrench == true" disabled value="">S'il vous plait sélectionner en un</option>
                          <option v-for="t in titles" :key="t.id" :value="t.id">{{ t.title_name }}</option>
                        </select>
                    </div>
                    <div class="job-label-option">
                      <select v-model="form.job_label_id" required v-bind:disabled="jobLabelDisabled">
                        <option v-if="enableEnglish == true" disabled value="">Please select one</option>
                        <option v-if="enableArabic == true" disabled value="">رجاءا اختر واحدة</option>
                        <option v-if="enableFrench == true" disabled value="">S'il vous plait sélectionner en un</option>
                        <option v-for="t in labels" :key="t.id" :value="t.id">{{ t.label_name }}</option>
                      </select>
                    </div>
                  </div>
                  <div class="submit">
                    <a type="submit" v-if="enableEnglish == true" href @click.prevent="searchResult">Submit</a>
                    <a type="submit" v-if="enableArabic == true" href @click.prevent="searchResult">يقدم</a>
                    <a type="submit" v-if="enableFrench == true" href @click.prevent="searchResult">Soumettre</a>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="career-tittle">
                  <h2 v-if="enableEnglish == true">Featured Job</h2>
                  <h2 v-if="enableArabic == true">وظيفة مميزة</h2>
                  <h2 v-if="enableFrench == true">Emploi en vedette</h2>
                </div>
              </div>
              <div class="col-md-12">
                <div v-for="j in jobs" :key="j.id" class="job-title-area mb d-flex align-items-center">
                  <div class="btl-area d-flex">
                    <div class="job-title-option d-flex">
                      <div class="sis-image">
                        <img :src="url + j.image" >
                      </div>
                      <div class="jobdetails">
                        <h3>{{ j.title_name }}</h3>
                        <h5>{{ j.label_name }}</h5>
                        <div class="loca-icon d-flex">
                          <div class="icon-l">
                            <i class="fas fa-map-marker-alt"></i>
                          </div>
                          <div class="loction-name">
                            <p>{{ j.location }}</p>
                          </div>
                        </div>
                        
                      </div>
                    </div>
                  </div>
                  <div class="submit">
                    <a :href="j.an"  target="_blank" v-if="enableEnglish == true">Apply Now</a>
                    <a :href="j.an"  target="_blank" v-if="enableArabic == true">قدم الآن</a>
                    <a :href="j.an"  target="_blank" v-if="enableFrench == true">Appliquer maintenant</a>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="d-flex justify-content-end" style="margin-top:20px;">
                  <button style="color:white; font-size:16px; margin-right:10px; padding-right: 10px; padding-left: 10px;" v-if="enableEnglish == true" @click.prevent="prevAcvnt"  class="btn btn-sm btn-info">Prev</button>
                  <button style="color:white; font-size:16px; margin-right:10px; padding-right: 10px; padding-left: 10px;" v-if="enableArabic == true" @click.prevent="prevAcvnt"  class="btn btn-sm btn-info">السابق</button>
                  <button style="color:white; font-size:16px; margin-right:10px; padding-right: 10px; padding-left: 10px;" v-if="enableFrench == true" @click.prevent="prevAcvnt"  class="btn btn-sm btn-info">Précédente</button>
                  <button style="color:white; font-size:16px" v-if="enableEnglish == true" @click.prevent="nextAcvnt" class="btn btn-sm btn-info">Next</button>
                  <button style="color:white; font-size:16px" v-if="enableArabic == true" @click.prevent="nextAcvnt" class="btn btn-sm btn-info">التالي</button>
                  <button style="color:white; font-size:16px" v-if="enableFrench == true" @click.prevent="nextAcvnt" class="btn btn-sm btn-info">Prochaine</button>
                </div>
              </div>
            </div>


        </div>
      </section>  
    </div>
</template>

<script>
import axios from 'axios'
export default {
  props: ['ul'],
  created() {
        if(this.ul.lang == 'English'){
            this.commonWork()
             this.enableEnglish = true
            this.enableArabic = false
            this.enableFrench = false
        }else if(this.ul.lang == 'Arabic'){
            this.commonWork()
             this.enableEnglish = false
            this.enableArabic = true
            this.enableFrench = false
        }else if(this.ul.lang == 'French'){
            this.commonWork()
            this.enableEnglish = false
            this.enableArabic = false
            this.enableFrench = true
        }else{
            this.getData()
        }

        this.$root.$on('english', (e) => {
            this.form.lang = e
            this.commonWork()
            this.enableEnglish = true
            this.enableArabic = false
            this.enableFrench = false
        })

        this.$root.$on('french', (e) => {
            this.form.lang = e
            this.commonWork()
            this.enableEnglish = false
            this.enableArabic = false
            this.enableFrench = true
        })

        this.$root.$on('arabic', (e) => {
            this.form.lang = e
            this.commonWork()
            this.enableEnglish = false
            this.enableArabic = true
            this.enableFrench = false
        })
  },
  data(){
    return {
      enableEnglish: true,
      enableArabic: false,
      enableFrench: false,
      jobLabelDisabled: true,
      url: this.ul.url,
      titles: [],
      labels: [],
      form: {
        lang: this.ul.lang,
        title: '',
        label: '',
        job_title_id: '',
        job_label_id: ''
      },
      page: 1,
      jobs: []
    }
  },
  methods: {
    getData(){
        this.form.lang = 'English'
        this.commonWork()
    },
    commonWork(){
        axios.get(this.url + 'api/getJobs?page=' + this.page, {params: this.form})
            .then(({data}) => {
                this.jobs = data.data
                this.changeTitle()
                this.jobLabelDisabled = true
                this.form.job_label_id = ''
                this.form.job_title_id = ''
            })
    },
    changeTitle(){
      axios.post(this.url + 'api/getJobTitleData', this.form)
        .then(({data}) => {
            this.titles = data
        })
    },
    changeLabel(){
        axios.post(this.url + 'api/getJobLabelData', this.form)
        .then(({data}) => {
            this.labels = data
            this.jobLabelDisabled = false
        })
    },
    searchResult(){
      axios.get(this.url + 'api/getJobs?page=' + this.page, {params: this.form})
        .then(({data}) => {
            this.jobs = data.data
        })
    },
    nextAcvnt(){
        this.page = this.page + 1
        axios.get(this.url + 'api/getJobs?page=' + this.page, {params: this.form})
          .then(({data}) => {
            this.jobs = data.data
          })
      },
	  prevAcvnt(){
        this.page = this.page - 1
        axios.get(this.url + 'api/getJobs?page=' + this.page, {params: this.form})
          .then(({data}) => {
            this.jobs = data.data
          })
      }
  }
}
</script>

<style scoped>
    /** all businees Area  Start **/
.no-gutters {
	padding: 0;
}
.site-title {
	text-align: center;
	margin-bottom: 60px;
}
.site-title h4 {
	font-size: 18px;
	position: relative;
	color: #0070ba;
	display:inline-block;
 
}
.site-title h2 {
	font-size: 48px;
	color: #222;	
  
}
.achi-details {
	padding-left: 20px;
}
.achi-details a {
	color: #0e75bc;
	font-weight: bold;
	font-size: 16px;
	display: block;
}

.site-title h4::before {
	position: absolute;
	width: 63px;
	height: 2px;
	background: #0070ba;
	top: 10px;
	left: 110%;
	content: "";
}
.carrer-area {
	background: #fbfbfb;
	padding-bottom: 120px;
}
.btl-area {
	padding-left: 20px;
	background: #fff;
	box-shadow: 12px 12px 30px 5px rgba(0, 0, 0, .2);
	padding-right: 175px;
	width: 90%;
}

.job-title-option {
	padding: 20px 0;
	width: 50%;
}
.job-label-option {
	width: 50%;
	padding: 20px 0;
}
.job-title-option select, .job-label-option select {
	padding: 20px 10px;
	border: 1px solid rgb(0,0,0, .2);
	width: 100%;
}
.job-title-option {
	margin-right: 30px;
}
.submit {
	background: #0070ba;
	width: 180px;
	height: 47px;
	line-height: 47px;
	text-align: center;
	color: #fff;
	position: relative;
	left: -75px;
	border-radius: 4px;
}
.submit a {
	color: #fff;
  font-family: 'Rubik';
}
.career-tittle {
	margin-bottom: 50px;
}
.career-tittle h2, .career-tittle p{
  font-family: 'Rubik';
}
.featured-job-area {
    margin-top: 70px;
}
.sis-image {
    width: 123px;
    height: 123px;
    text-align: center;
    line-height: 123px;
    border: 1px solid rgb(0,0,0, .2);
    margin-right: 20px;
}
.jobdetails h6 {
    font-size: 13px;
    margin-top: 10px;
    color: #0070ba;
    font-family: 'Rubik';
}
.jobdetails h3 {
    color: #222;
    font-size: 20px;
    font-family: 'Rubik';
}
.jobdetails h5 {
    font-size: 14px;
    color: #777;
    font-family: 'Rubik';
}
.icon-l i {
    margin-right: 5px;
}
.loction-name p {
    color: #777;
    font-size: 14px;
    font-family: 'Rubik';
}
.featured-job-area .btl-area.d-flex {
    border-left: 2px solid #0070ba;
}
.featured-job-area .submit {
    background: none;
	border: 1px solid #0070ba;
}
.featured-job-area .submit a{
	color: #0070ba;
  font-family: 'Rubik';
}
.jobdetails h6 {
    font-size: 12px;
    margin-top: 10px;
    color: #0070ba;
    font-weight: 400;
}
.mb {
    margin-bottom: 30px;
}
select#cars {
    font-family: 'Rubik';
}
button.btn.btn-sm.btn-info {
    font-family: 'Rubik';
}
.btn-info {
    color: #fff;
    background-color: #0070ba;
    border-color: #0070ba;
}
@media (min-width: 576px) { 
	
}
/* Medium devices (tablets, 768px and up)*/
@media only screen and (max-width: 575px) and (min-width: 320px)  {
  .job-title-area.d-flex {
    display: inherit !important;
  }
  .carrer-area{
    padding-bottom: 50px;
    /* padding-top: 50px; */
  }
	.site-title h2 {
		font-size: 36px;
	}
	.jobdetails {
		width: 100%;
	}
	.btl-area.d-flex {
		padding-right: 20px;
		width: 100%;
	}
	.job-title-option{
		width: 50%;
	}
	.submit {
		left: 0px;
		margin-top: 10px;
	}
	.job-title-area.mb.d-flex.align-items-center {
		display: block !important;
	}
  .btl-area{
    padding-left: 20px;
    padding-right: 20px;
   
  }
  
}
/* Medium devices (tablets, 768px and up)*/
@media only screen and (max-width: 767px) and (min-width: 576px)  {
  .job-title-area.d-flex {
    display: inherit !important;
  }
	.jobdetails {
		width: 100%;
	}
	.job-title-option.d-flex {
		width: 100%;
	}
	.submit{
    left: 0px;
    margin-top: 20px;
    margin-right: 0;
  }
	.btl-area{
    padding-left: 20px;
    padding-right: 20px;
   
  }
  
}
/* Medium devices (tablets, 768px and up)*/
@media only screen and (max-width: 991px) and (min-width: 768px)  {
  .job-title-area.d-flex {
    display: inherit !important;
}
	.jobdetails {
		width: 100%;
	}
	.job-title-option.d-flex {
		width: 100%;
	}
	.submit {
		left: 0px;
    margin-top: 20px;
	}
  .btl-area{
    padding-left: 20px;
    padding-right: 20px;
   
  }
	
}
/* Large devices (desktops, 992px and up)*/
@media only screen and (max-width: 1199px) and (min-width: 992px)  {
	
	.jobdetails {
		width: 100%;
	}
  .submit {
		left: 0px;
    margin-top: 20px;
	}
  .btl-area{
    padding-left: 20px;
    padding-right: 20px;
   
  }

}
/* Extra large devices (large desktops, 1200px and up)*/

@media only screen and (max-width: 1600px) and (min-width: 1200px)  {
	.jobdetails {
		width: 100%;
	}
  .submit {
		left: 0px;
    margin-top: 20px;
	}
  .btl-area{
    padding-left: 20px;
    padding-right: 20px;
   
  }
}
</style>