<template>
    <div>
           
        <section class="section-padding bg-light-white">
          <div class="container">
              <div class="row">
                  <div class="col-lg-6 col-md-6 mb-md-80">
                      <div class="section-header left-heading pb-0">
                          <div class="section-heading">
                              <!-- <h6 class="text-orng mb-xl-10 sub-heading"><span>{{ profit.heading }}</span></h6> -->
                              <h3 class="text-blue fw-700 title">{{ profit.title }}<span class="text-orng"></span></h3>
                             <p class="desc">{{ profit.desc }}</p>
                          </div>
                      </div>
                      <!-- 
                      <ul class="custom profit-list" v-for="d in details" :key="d.id">
                          <li><i class="fas fa-check"></i><span class="font-weight:bold">{{ d.desc_item }}</span></li>
                      </ul>-->
                     <a href @click.prevent="category(profit.lang)" class="theme-btn btn-orange catbutton">
                          {{ profit.case_study }} <i class="fa fa-plus"></i>
                      </a>
                  </div>
                   <!-- <div class="col-lg-6 col-md-6 col-xs-12">
                      <div class="profit-chart-box">
                          <a href="index.html" class="chart-logo">
                              <img src="images/bengal-cat-logo.png" class="image-fit" alt="">
                          </a>
                          <a href="#" class="profit-icon icon-ps-1" v-for="img in images" :key="img.id">
                              <span class="icon">
                                <img :src="'http://192.168.1.6:8002/' + img.image">
                              </span>
                              <span class="icon-text">dfdf</span>
                          </a>
                      </div>
                  </div> -->
                  <div class="col-lg-6 col-sm-6 col-md-6 col-xs-12">
                      <div class="profit-chart-box">
                          <a href="#" class="chart-logo">
                              <img src="images/bengal-cat-logo.png" class="image-fit" alt="">
                          </a>
                          <a href @click.prevent="clkPlastic(plastic.menu_categorie_id, 'category')" class="profit-icon icon-ps-1">
                              <span class="icon">
                                  <img :src="url + plastic.image">
                              </span>
                              <span class="icon-text">{{plastic.category}}</span>
                          </a>
                          <a href @click.prevent="clkRealestate(building.menu_categorie_id, 'category')" class="profit-icon icon-ps-2">
                                <span class="icon">
                                <img :src="url + building.image">
                              </span>
                              <span class="icon-text">{{building.category}}</span>
                              
                          </a>
                          <a href @click.prevent="clkFood(food.menu_categorie_id, 'category')" class="profit-icon icon-ps-3">
                              <span class="icon">
                                <img :src="url + food.image">
                              </span>
                              <span class="icon-text">{{food.category}}</span>
                          </a>
                          <a href @click.prevent="clkTextile(textile.menu_categorie_id, 'category')" class="profit-icon icon-ps-4">
                               <span class="icon">
                                <img :src="url + textile.image">
                              </span>
                              <span class="icon-text">{{textile.category}}</span>
                              
                          </a>
                          <a href @click.prevent="clkMedia(media.menu_categorie_id, 'category')" class="profit-icon icon-ps-5">
                              <span class="icon">
                                <img :src="url + media.image">
                              </span>
                              <span class="icon-text">{{media.category}}</span>
                              
                          </a>
                          <a href @click.prevent="clkInfrustructure(infrastructure.menu_categorie_id, 'category')" class="profit-icon icon-ps-6">
                               <span class="icon">
                                <img :src="url + infrastructure.image">
                              </span>
                              <span class="icon-text">{{infrastructure.category}}</span>
                          </a>
                          <a href @click.prevent="clkPower(power.menu_categorie_id, 'category')" class="profit-icon icon-ps-7">
                             <span class="icon">
                                <img :src="url + power.image">
                              </span>
                              <span class="icon-text">{{power.category}}</span>
                              
                          </a>
                          <a href @click.prevent="clkHospitality(hospitality.menu_categorie_id, 'category')" class="profit-icon icon-ps-8">
                              <span class="icon">
                                <img :src="url + hospitality.image">
                              </span>
                              <span class="icon-text">{{hospitality.category}}</span>
                          </a>
                      </div>
                  </div>
              </div>
          </div>
      </section>

    </div>
</template>

<script>
import axios from 'axios'
export default {
    props: ['ul'],
    created() {

        if(this.ul.lang == 'English'){
            this.commonWork()
        }else if(this.ul.lang == 'Arabic'){
            this.commonWork()
        }else if(this.ul.lang == 'French'){
            this.commonWork()
        }else{
            this.getData()
        }

        this.$root.$on('english', (e) => {
            this.form.lang = e
            this.commonWork()
        })

        this.$root.$on('arabic', (e) => {
            this.form.lang = e
            this.commonWork()
        })

        this.$root.$on('french', (e) => {
            this.form.lang = e
            this.commonWork()
        })
    },
    data() {
        return {
            url: this.ul.url,
            profit: {},
            plasticD: '',
            buildingD: '',
            textileD: '',
            mediaD: '',
            infrastructureD: '',
            powerD: '',
            hospitalityD: '',
            form: {
                lang: this.ul.lang
            },
            food: {},
            plastic: {},
            building: {},
            textile: {},
            media: {},
            infrastructure: {},
            power: {},
            hospitality: {},
            dataC: {
                category: '',
                id: '',
                name: ''
            }
        }
    },
    methods: {
        category(lang){
            if(lang == 'English'){
                this.dataC.category = 'menu'
                this.dataC.id = 2
                this.dataC.name = 'category'
                this.$root.$emit('home', this.dataC)
            }else if(lang == 'Arabic'){
                this.dataC.category = 'menu'
                this.dataC.id = 12
                this.dataC.name = 'category'
                this.$root.$emit('home', this.dataC)
            }else{
                this.dataC.category = 'menu'
                this.dataC.id = 22
                this.dataC.name = 'category'
                this.$root.$emit('home', this.dataC)
            }
            
        },
        getData(){
            this.form.lang = 'English'
            this.commonWork()
        },
        commonWork(){
            axios.get(this.url + 'api/getProfit', {params: this.form})
                .then(({data}) => {
                    this.profit = data.profit
                    this.plastic = data.commons[0]
                    this.building = data.commons[1]
                    this.food = data.commons[2]
                    this.textile = data.commons[3]
                    this.media = data.commons[4]
                    this.infrastructure = data.commons[5]
                    this.power = data.commons[6]
                    this.hospitality = data.commons[7]
                })
           
        },
        
        getCategoryData(){
        this.form.lang = 'English'
        axios.get(this.url + 'api/getProfitCategory', {params: this.form})
          .then(({data}) => {
            this.plastic = data[1]
            this.building = data[5]
            this.food = data[0]
            this.textile = data[2]
            this.media = data[6]
            this.infrastructure = data[4]
            this.power = data[7]
            this.hospitality = data[3]
          })
        },
        clkFood(id, category){
            this.dataC.id = id
            this.dataC.category = category
            this.$root.$emit('business', this.dataC)
        },
        clkPlastic(id, category){
            this.dataC.id = id
            this.dataC.category = category
            this.$root.$emit('business', this.dataC)
        },
        clkTextile(id, category){
            this.dataC.id = id
            this.dataC.category = category
            this.$root.$emit('business', this.dataC)
        },
        clkHospitality(id, category){
            this.dataC.id = id
            this.dataC.category = category
            this.$root.$emit('business', this.dataC)
        },
        clkInfrustructure(id, category){
            this.dataC.id = id
            this.dataC.category = category
            this.$root.$emit('business', this.dataC)
        },
        clkRealestate(id, category){
            this.dataC.id = id
            this.dataC.category = category
            this.$root.$emit('business', this.dataC)
        },
        clkMedia(id, category){
            this.dataC.id = id
            this.dataC.category = category
            this.$root.$emit('business', this.dataC)
        },
        clkPower(id, category){
            this.dataC.id = id
            this.dataC.category = category
            this.$root.$emit('business', this.dataC)
        }
    }
}
</script>


<style scoped>
    /** Business Catagory Area  Start **/
.section-header.left-heading {
	text-align: left;
	margin-left: 0;
}
.section-header {
	max-width: 550px;
	margin: 0 auto;
	padding-bottom: 30px;
	position: relative;
	z-index: 10;
	text-align: center;
}
.section-header .section-heading .sub-heading {
	font-family: 'Rubik';
	font-weight: 500;
}
.custom profit-list li{
	font-family: 'Rubik';
}
.text-orng {
	color: #0070ba;
}
.text-blue {
    color: #00954c;
}
.section-header .section-heading .title {
	font-size: 50px;
	text-align: left;
    font-family: 'Rubik';
    color: #000;
    font-weight: 700;
}

.section-header .section-heading .desc {
	font-size: 16px;
	max-width: 450px;
	font-family: 'Rubik';
    line-height: 40px;
}
ul.profit-list {
	margin-bottom: 20px;
    padding:0;
}
ul.profit-list li {
	font-size: 16px;
	font-weight: 500;
	margin-bottom: 20px;
	display: flex;
	align-items: center;
	font-family: 'Rubik';
}
.custom.profit-list li i {
    margin-right: 10px;
}
.theme-btn.btn-orange i {
	margin-left: 10px;
}
.theme-btn.btn-orange {
	color: #fff;
	background-color: #004ea0;
	font-family: 'Rubik';
}
.theme-btn {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	padding: 15px 40px;
	color: #fff;
	transition: 0.5s all;
	white-space: nowrap;
	font-size: 16px;
	font-weight: 500;
}
.section-header .section-heading {
	position: relative;
}
.svg-inline--fa.fa-plus.fa-w-14 {
    margin-left: 12px;
}
.bg-light-white {
	background-color: #f3f5f7;
}
.section-padding {
	padding: 120px 0;
}
ul.profit-list{
    margin-bottom: 20px;
}
ul.profit-list li{
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
}
.profit-chart-box{
    background-color: transparent;
    height: 100%;
    width: calc(100% - 80px);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-left: 80px;
}
.profit-chart-box:before{
    content: '';
    position: absolute;
    top: 50px;
    left: 50px;
    width: calc(100% - 100px);
    height: calc(100% - 100px);
    background: #fff;
}
.profit-chart-box .chart-logo{
    width: 100px;
    height: 90px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: 10px;
}
.profit-chart-box .chart-logo:before{
    content: '';
    border: 4px solid #f3f5f7;
    position: absolute;
    width: calc(100% + 20px);
    height: calc(100% + 20px);
}
.profit-chart-box .profit-icon{
    height: 110px;
    width: 130px;
    justify-content: center;
    border: 4px solid #f3f5f7;
    text-align: center;
    padding: 10px 0px;
    background: #fff;
    position: absolute;
    transition: .4s;
}
.profit-chart-box .profit-icon:hover{
    color: #14212b;
    border-color: #004ea0;
    height: 115px;
}
.profit-chart-box .profit-icon .icon{
    display: block;
    flex-basis: 100%;
    text-align: center;
    font-size: 32px;
    line-height: 1;
    color: #004ea0;
}
.profit-chart-box .profit-icon .icon-text{
    flex-basis: 100%;
    text-align: center;
    font-size: 14px;
	font-weight: 600;
	color: #1b1b1b;
	font-family: 'Rubik';
}
.profit-chart-box .profit-icon.icon-ps-1{
    top: 0;
    left: 0;
}
.profit-chart-box .profit-icon.icon-ps-2{
    top: 0;
    transform: translateX(0);
}
.profit-chart-box .profit-icon.icon-ps-3{
    top: 0;
    right: 0;
}
.profit-chart-box .profit-icon.icon-ps-4{
    left: 0;
    top: 50%;
    transform: translateY(-50%);
}
.image-fit {
    width: auto;
    height: auto;
    object-fit: cover;
    object-position: center;
}
.profit-chart-box .profit-icon.icon-ps-5{
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}
.profit-chart-box .profit-icon.icon-ps-6{
    bottom: 0;
    left: 0;
}
.profit-chart-box .profit-icon.icon-ps-7{
    bottom: 0;
    transform: translateX(0);
}
.profit-chart-box .profit-icon.icon-ps-8{
    bottom: 0;
    right: 0;
}
/* Extra small devices (portrait phones, less than 576px)*/
/* No media query since this is the default in Bootstrap*/

@media (min-width: 576px) { 
	
}
/* Medium devices (tablets, 768px and up)*/
@media only screen and (max-width: 575px) and (min-width: 320px)  {
	
    .section-header .section-heading .title{
        font-size: 30px;
    }
    /* .profit-chart-box .chart-logo{
    width: 72px;
    height: 90px;
    margin-right: 10px;
    display: block;
    margin-top: -15px;
    margin-bottom: 0px;
    margin-left: 0;
    }
    .profit-chart-box .chart-logo:before {
        top: -28px;
        left: -25px;
        width: calc(100% + 42px);
        height: calc(100% + 40px);
    }
    .profit-chart-box .chart-logo img{
        max-width: 100%;
    }

    .profit-chart-box .profit-icon.icon-ps-2{
        transform: translateX(-55%);
        width: 110px;
        height: 117px;
    }
    .profit-chart-box .profit-icon{
        height: 120px !important;
        width: 90px !important;
    }
    .profit-chart-box .profit-icon.icon-ps-6{
        bottom: 17px;
        left: 0;
        transform: translateX(-10%);
    }
	.profit-chart-box .profit-icon .icon-text{
        font-size: 13px;
        word-break: break-all;
    }
    .profit-chart-box .profit-icon .icon img {
        width: 30px;
    }
    
    .profit-chart-box .profit-icon.icon-ps-8{
    bottom: 16px;
    right: -1px;
    } */
    
    .profit-chart-box {
        height: 400px;
    }
    .profit-chart-box .profit-icon, 
    .profit-chart-box .chart-logo {
        width: 100px;
        height: 120px;
        margin: 0;
    }
    .profit-chart-box .profit-icon {
        padding: 13px 0;
    }
    .profit-chart-box .profit-icon .icon-text{
        font-size: 13px;
        font-weight: 400;
        color: #000;
    }
    .profit-chart-box {
        width: 100%;
        margin-left: 0;
    }
    .catbutton{
        display: none;
    }
}
/* Medium devices (tablets, 768px and up)*/
@media only screen and (max-width: 767px) and (min-width: 576px)  {
	/* .profit-chart-box {
		margin-top:70px;
		margin-left: 0;
		height: 380px;
	} */
    .profit-chart-box {
        height: 510px;
        background-color: transparent;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        margin-left: 0px;
        margin-top:50px;
    }
    
}
/* Medium devices (tablets, 768px and up)*/
@media only screen and (max-width: 991px) and (min-width: 768px)  {
	.section-header .section-heading .title {
		font-size: 24px;
	}
	.profit-chart-box{
        width: 100%;
        margin-left: 0px;
    }
    .profit-chart-box .profit-icon{
            width: 106px;
        }
	
}
/* Large devices (desktops, 992px and up)*/
@media only screen and (max-width: 1199px) and (min-width: 992px)  {
	.profit-chart-box .profit-icon{
            
            width: 120px;
           
        }
	
}
/* Extra large devices (large desktops, 1200px and up)*/

@media only screen and (max-width: 1600px) and (min-width: 1200px)  {
        .profit-chart-box .profit-icon{
            
            width: 120px;
           
        }
    
}
/** Business Catagory Area END **/
</style>