<template>
    <div>
          <section class="export-area">
          <div class="site-title col-sm-12">
            <h4 class="sub-title">
              {{titleArea.heading}}
            </h4>
            <h2>{{titleArea.title}}</h2>
          </div>
            <div class="container-full">
              <div class="row export-map">
                <div class="col-md-12 export-area-map">
                  <div class="title col-sm-12">
                    <h4 class="sub-title">
                      {{titleArea.heading}}
                    </h4>
                    <h2>{{titleArea.title}}</h2>
                  </div>
                    <img :src="url + bg.image">
                    <div v-if="enableMap == true">
						<div :style="{top: expt.top_d, left: expt.left_d}" v-for="expt in expts" :key="expt.id" :class="(selectedCountry == expt.country) ? 'map-location active' +' '+ selectedCountry: 'map-location'">
							<div class="map-colm">
								<div class="map-colm-sec1"><a @click.prevent="doSomething(expt.country)" href class="map-point">{{ expt.country }}</a>
								<div v-if="isExpand == true && selectedCountry == expt.country" class="map-location-details">
									<ul class="india-colm">
										<li><a href="#"><img :src="url + expt.image"></a></li>  
									</ul>
								</div>
								</div>
								<div v-if="isExpand == false || selectedCountry != expt.country" class="map-colm-sec2">
									<a @click.prevent="doSomething(expt.country)" href class="map-point">
									<img src="img/plus.png" alt="">
									</a>
								</div>
								<div v-if="isExpand == true && selectedCountry == expt.country" class="map-colm-sec2">
									<a @click.prevent="resetThings" href class="map-point">
									<img src="img/minus.png" alt="">
									</a>
								</div>
							</div>
						</div>
					</div>

					<div v-if="enableMap_1 == true">
						<div :style="{top: expt.top_one, left: expt.left_one}" v-for="expt in expts" :key="expt.id" :class="(selectedCountry == expt.country) ? 'map-location active' +' '+ selectedCountry: 'map-location'">
							<div class="map-colm">
								<div class="map-colm-sec1"><a @click.prevent="doSomething(expt.country)" href class="map-point">{{ expt.country }}</a>
								<div v-if="isExpand == true && selectedCountry == expt.country" class="map-location-details">
									<ul class="india-colm">
										<li><a href="#"><img :src="url + expt.image"></a></li>  
									</ul>
								</div>
								</div>
								<div v-if="isExpand == false || selectedCountry != expt.country" class="map-colm-sec2">
									<a @click.prevent="doSomething(expt.country)" href class="map-point">
									<img src="img/plus.png" alt="">
									</a>
								</div>
								<div v-if="isExpand == true && selectedCountry == expt.country" class="map-colm-sec2">
									<a @click.prevent="resetThings" href class="map-point">
									<img src="img/minus.png" alt="">
									</a>
								</div>
							</div>
						</div>
					</div>

					<div v-if="enableMap_2 == true">
						<div :style="{top: expt.top_two, left: expt.left_two}" v-for="expt in expts" :key="expt.id" :class="(selectedCountry == expt.country) ? 'map-location active' +' '+ selectedCountry: 'map-location'">
							<div class="map-colm">
								<div class="map-colm-sec1"><a @click.prevent="doSomething(expt.country)" href class="map-point">{{ expt.country }}</a>
								<div v-if="isExpand == true && selectedCountry == expt.country" class="map-location-details">
									<ul class="india-colm">
										<li><a href="#"><img :src="url + expt.image"></a></li>  
									</ul>
								</div>
								</div>
								<div v-if="isExpand == false || selectedCountry != expt.country" class="map-colm-sec2">
									<a @click.prevent="doSomething(expt.country)" href class="map-point">
									<img src="img/plus.png" alt="">
									</a>
								</div>
								<div v-if="isExpand == true && selectedCountry == expt.country" class="map-colm-sec2">
									<a @click.prevent="resetThings" href class="map-point">
									<img src="img/minus.png" alt="">
									</a>
								</div>
							</div>
						</div>
					</div>

					<div v-if="enableMap_3 == true">
						<div :style="{top: expt.top_three, left: expt.left_three}" v-for="expt in expts" :key="expt.id" :class="(selectedCountry == expt.country) ? 'map-location active' +' '+ selectedCountry: 'map-location'">
							<div class="map-colm">
								<div class="map-colm-sec1"><a @click.prevent="doSomething(expt.country)" href class="map-point">{{ expt.country }}</a>
								<div v-if="isExpand == true && selectedCountry == expt.country" class="map-location-details">
									<ul class="india-colm">
										<li><a href="#"><img :src="url + expt.image"></a></li>  
									</ul>
								</div>
								</div>
								<div v-if="isExpand == false || selectedCountry != expt.country" class="map-colm-sec2">
									<a @click.prevent="doSomething(expt.country)" href class="map-point">
									<img src="img/plus.png" alt="">
									</a>
								</div>
								<div v-if="isExpand == true && selectedCountry == expt.country" class="map-colm-sec2">
									<a @click.prevent="resetThings" href class="map-point">
									<img src="img/minus.png" alt="">
									</a>
								</div>
							</div>
						</div>
					</div>

					<div v-if="enableMap_4 == true">
						<div :style="{top: expt.top_four, left: expt.left_four}" v-for="expt in expts" :key="expt.id" :class="(selectedCountry == expt.country) ? 'map-location active' +' '+ selectedCountry: 'map-location'">
							<div class="map-colm">
								<div class="map-colm-sec1"><a @click.prevent="doSomething(expt.country)" href class="map-point">{{ expt.country }}</a>
								<div v-if="isExpand == true && selectedCountry == expt.country" class="map-location-details">
									<ul class="india-colm">
										<li><a href="#"><img :src="url + expt.image"></a></li>  
									</ul>
								</div>
								</div>
								<div v-if="isExpand == false || selectedCountry != expt.country" class="map-colm-sec2">
									<a @click.prevent="doSomething(expt.country)" href class="map-point">
									<img src="img/plus.png" alt="">
									</a>
								</div>
								<div v-if="isExpand == true && selectedCountry == expt.country" class="map-colm-sec2">
									<a @click.prevent="resetThings" href class="map-point">
									<img src="img/minus.png" alt="">
									</a>
								</div>
							</div>
						</div>
					</div>

					<div v-if="enableMap_5 == true">
						<div :style="{top: expt.top_five, left: expt.left_five}" v-for="expt in expts" :key="expt.id" :class="(selectedCountry == expt.country) ? 'map-location active' +' '+ selectedCountry: 'map-location'">
							<div class="map-colm">
								<div class="map-colm-sec1"><a @click.prevent="doSomething(expt.country)" href class="map-point">{{ expt.country }}</a>
								<div v-if="isExpand == true && selectedCountry == expt.country" class="map-location-details">
									<ul class="india-colm">
										<li><a href="#"><img :src="url + expt.image"></a></li>  
									</ul>
								</div>
								</div>
								<div v-if="isExpand == false || selectedCountry != expt.country" class="map-colm-sec2">
									<a @click.prevent="doSomething(expt.country)" href class="map-point">
									<img src="img/plus.png" alt="">
									</a>
								</div>
								<div v-if="isExpand == true && selectedCountry == expt.country" class="map-colm-sec2">
									<a @click.prevent="resetThings" href class="map-point">
									<img src="img/minus.png" alt="">
									</a>
								</div>
							</div>
						</div>
					</div>
                    
                </div>

              </div>
            </div>
        </section>

    </div>
</template>

<script>
import axios from 'axios'
export default {
  props: ['ul'],
  created() {
    this.getMap()

	if(this.ul.lang == 'English'){
		this.commonWork()
	}else if(this.ul.lang == 'Arabic'){
		this.commonWork()
	}else if(this.ul.lang == 'French'){
		this.commonWork()
	}else{
		this.getData()
	}

    this.$root.$on('english', (e) => {
        this.form.lang = e
        this.commonWork()
    })

    this.$root.$on('arabic', (e) => {
        this.form.lang = e
        this.commonWork()
    })

    this.$root.$on('french', (e) => {
        this.form.lang = e
        this.commonWork()
    })
   
  },
  data() {
    
    return {
      url: this.ul.url,
      bg: {},
      expts: [],
      isExpand: false,
      selectedCountry: '',
	  titleArea: '',
      form: {
          lang: this.ul.lang
      },
	  enableMap: false,
	  enableMap_1: false,
	  enableMap_2: false,
	  enableMap_3: false,
	  enableMap_4: false,
	  enableMap_5: false
	  
    }

  },
  methods: {
	getMap(){
		if(window.innerWidth <= 575){
			this.enableMap_1 = true
		}else if(window.innerWidth <= 767){
			this.enableMap_2 = true
		}else if(window.innerWidth <= 991){
			this.enableMap_3 = true
		}else if(window.innerWidth <= 1199){
			this.enableMap_4 = true
		}else if(window.innerWidth <= 1600){
			this.enableMap_5 = true
		}else{
			this.enableMap = true
		}
	},
    getData(){
        this.form.lang = 'English'
        this.commonWork()
    },
    commonWork(){
        axios.get(this.url + 'api/getExport', {params: this.form})
          .then(({data}) => {
            this.titleArea = data.title
            this.bg = data.bg
            this.expts = data.exports
          })
    },
    doSomething(country){
      this.isExpand = true
      this.selectedCountry = country
    },
    resetThings(){
      this.isExpand = false
      this.selectedCountry = ''
    }
  }
}
</script>

<style scoped>
    /** map  Area  Start **/
    img{
      max-width: 100%;
    }
  
.export-area-map .title {
	position: absolute;
	color: #fff;
	text-align: right;
	right: 23%;
	top: 8%;
}
.title h4 {
	font-size: 18px;
	position: relative;
	font-family: 'Rubik';
	display:inline-block;
	font-family: 'Rubik';
	right: 0;
	
}
/* .title h4::before {
	position: absolute;
	width: 63px;
	height: 2px;
	background: #fff;
	top: 10px;
	right: 0px;
	content: "";
} */
.title h4::after {
    position: absolute;
    width: 63px;
    height: 2px;
    background: #fff;
    top: 10px;
    content: "";
    left: -76px;
  }
.title h2 {
	font-size: 48px;
  	font-family: 'Rubik';
  	font-weight: 700;
	  margin-top: 0;
}
.map-location {
	background: #fff;
	padding: 4px;
	max-width: 500px;
	position: absolute;
	z-index: 1;
}
.map-location.active {
	z-index: 9999;
}
.map-colm-sec2 {
	padding: 8px 12px;
	position: relative;
}
.map-colm-sec1 {
	padding-right: 8px;
	border-right: 1px solid #c5dbdf;
}
.map-colm {
	display: flex;
}
.map-location-details ul {
	padding: 0;
	list-style: none;
}
.map-colm-sec2 img {
	width: 15px;
	height: auto;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 2px;
	right: 0;
	
}
.map-colm .map-colm-sec1 a{
	font-size: 14px;
	color: #000 !important;
	text-decoration: none;
  font-family: 'Rubik';
}
.map-location-expanded.ng-hide-remove {   
    transition: max-height 1s;
    overflow: hidden;
    max-height: 100px;
}

.map-location-expanded.ng-hide {
    transition: max-height 1s;
    overflow: hidden;
    max-height: 0; 
}
.canada {
	top: 30%;
	left: 20%;
}
.usa {
	top: 46%;
	left: 20%;
}
.mexico {
	left: 12%;
	top: 54%;
}
.nicaragua {
	left: 15%;
	top: 60%;
}
.dominicanrepublic {
	top: 53%;
	left: 26%;
}
.brazil {
	top: 67%;
	left: 30%;
}
.ireland {
	top: 34%;
	left: 37%;
}
.switzerland{
	top: 38%;
left: 40%;
}
.germany{
	top: 42%;
left: 40%;
}
.france{
	top: 46%;
left: 37%;
}
.spain{
	top: 46%;
	left: 40%;
}
.portugal{
	top: 52%;
	left: 38%;
}
.sweden {
	top: 28%;
	left: 43%;
}
.netherlands {
	top: 32%;
	left: 42%;
}
.denmark {
	top: 40%;
	left: 42%;
}
.belgium {
	top: 43%;
	left: 43%;
}
.uk {
	top: 36%;
	left: 43%;
}
.slovakia {
	top: 40%;
	left: 48%;
}
.poland {
	top: 37%;
	left: 49%;
}
.czechrepublic {
	top: 43%;
	left: 48%;
}
.hungary {
	top: 40%;
	left: 54%;
}
.austria {
	top: 46%;
	left: 43%;
}
.romania{
	top: 46%;
	left: 48%;
}
.italy{
	top: 49%;
	left: 43%;
}
.slovenia{
	top: 49%;
	left: 47%;
}
.bulgaria{
	top: 49%;
	left: 52%;
}
.greece {
	top: 52%;
	left: 47%;
}
.croatia {
	top: 52%;
	left: 52%;
}
.cyprus{
	top: 55%;
	left: 52%;
}
.saudi{
	top: 58%;
left: 48%;
}
.yemen{
	top: 61%;
left: 53%;
}
.somalia{
	top: 64%;
left: 52%;
}
.sudan{
	top: 67%;
left: 52%;
}
.iran{
	top: 52%;
	left: 57%;	
}
.qatar{
	top: 55%;
	left: 57%;	
}
.bahrain{
	top: 58%;
	left: 57%;	
}
.kuwait{
	top: 53%;
    left: 55%;
}
.uae{
	top: 56%;
    left: 56%;
}
.napal{
	top: 50%;
	left: 61%;
}
.india{
	top: 55%;
left: 62%;
}
.maldives{
	top: 64%;
left: 62%;
}
.myanmar{
	top: 55%;
left: 67%;
}
.thailand{
	top: 58%;
	left: 70%;
}
.malaysia{
	top: 63%;
left: 71%;
}
.singapore{
	top: 71%;
right: 27%;
}
.australia{
	top: 75%;
right: 22%;
}
.newZealand{
	top: 90%;
right: 15%;
}
.southkorea{
	top: 46%;
right: 16%;
}
.japan{
	top: 50%;
right: 19%;
}
.cambadia{
	top: 53%;
right: 22%;
}
.philippines{
	top: 56%;
right: 19%;
}
.tasttimor {
	top: 67%;
	left: 72%;
}
.nepal {
    top: 51%;
    left: 63%;
  }
.site-title{
	display: none;
	text-align: center;
}
.site-title h2 {
	font-size: 26px !important;
	font-family: 'Rubik';
  display: inline-block;
  position: relative;
}
.site-title h4.sub-title {
	font-size: 16px;
	position: relative;
  font-family: 'Rubik';
}
/* .site-title h4.sub-title::before {
	content: "";
	display: block;
	height: 2px;
	width: 63px;
	background: #354e8d;
	top: 10px;
	left: 108%;
	position: absolute;
} */
/* Medium devices (tablets, 768px and up)*/
@media only screen and (max-width: 575px) and (min-width: 320px)  {
  .site-title h2 {
    font-size: 30px !important;
	display: block;
}
	.maplocation {
		display: inline-block;
		margin-bottom: 5px;
		margin-right: 20px;
		margin-left: 20px;
	}
	.export-area-map .title{
		display: none;
	}
	.site-title{
		display: block;
	}
  .export-area-map img {
	  max-width: 100%;
  }
  .site-title h4.sub-title::before {
      left: 21px;
  }

  .asia {
    top: 41%;
    left: 59%;
  }
  .africa {
    top: 64%;
    left: 40%;
  }
  .europe {
    top: 37%;
    left: 31%;
  }
  .sAmerica {
    top: 14%;
    left: 5%;
   
  }
  .nAmerica {
    top: 81%;
    left: 6%;
  }
  .australia{
    top: 78%;
    right: 6%;
  }
.map-colm .map-colm-sec1 a{
    font-size: 12px;
}
  .usa{
   top: 10%;
    left: 11%;
}
.switzerland{
   top: 10%;
    left: 33%;
}
.germany{
    top: 10%;
    left: 66%;
}
.spain{
    top: 27%;
    left: 11%;
}
.portugal{
    top: 27%;
    left: 36%;
}
.romania{
    top: 27%;
    left: 65%;
}
.italy{
    top: 45%;
    left: 11%;
}
.greece{
    top: 45%;
    left: 32%;
}
.saudi{
    top: 45%;
    left: 58%;
}
.kuwait{
    top: 64%;
    left: 06%;
}
.uae{
    top: 64%;
    left: 30%;
}
.nepal{
    top: 64%;
    left: 51%;
}
.india{
    top: 81%;
    left: 11%;
}
.thailand{
    top: 81%;
    left: 35%;
}
.southkorea{
    top: 81%;
    right: 5%;
}
.japan{
    top: 64%;
    right: 4%;
}

}
/* Medium devices (tablets, 768px and up)*/
@media only screen and (max-width: 767px) and (min-width: 576px)  {
  .site-title h4.sub-title::before {
    left: 34%;
  }
  .site-title{
		display: block;
	}
	.export-area-map .title{
		display: none;
	}
	.export-area-map > img {
		min-height: 450px;
	}
	.canada {
		top: 2%;
		left: 10%;
	}
	.usa{
		    top: 36%;
    left: 19%;
	}
	.maxico {
		top: 2%;
		left:41%;
	}
	.nicaragua {
		top: 2%;
		left: 58%;
	}
	.philippines{
		top: 6%;
		right: 60%;
	}
	.dominicanrepublic {
		top: 10%;
		left: 10%;
	}
	.brazil {
		top: 10%;
		left: 41%;
	}
	.ireland {
		top: 18%;
		left: 10%;
	}
	.switzerland {
		top: 18%;
		left: 26%;
	}
	.germany {
		top: 35%;
    	left: 33%;
	}
	.france {
		top: 18%;
		left: 65%;
	}
	.spain {
		top: 49%;
    	left: 25%;
	}
	.portugal {
		top: 56%;
    	left: 30%;
	}
	.sweden {
		top: 26%;
		left: 27%;
	}
	.netherlands {
		top: 26%;
		left: 44%;
	}
	.uk {
		top: 26%;
		left: 65%;
	}
	.denmark {
		top: 26%;
		left: 76%;
	}
	.belgium {
		top: 34%;
		left: 10%;
	}
	.slovakia {
		top: 34%;
		left: 27%;
	}
	.poland {
		top: 34%;
		left: 44%;
	}
	.czechrepublic {
		top: 34%;
		left: 57%;
	}
	.hungary {
		top: 42%;
		left: 10%;
	}
	.austria {
		top: 42%;
		left: 27%;
	}
	.romania {
		top: 42%;
		left: 43%;
	}
	.italy {
		top: 42%;
    left: 33%;
	}
	.slovenia {
		top: 42%;
		left: 74%;
	}
	.bulgaria {
		top: 50%;
		left: 10%;
	}
	.greece {
		top: 49%;
    left: 35%
	}
	.croatia {
		top: 50%;
		left: 42%;
	}
	.cyprus {
		top: 50%;
		left: 58%;
	}
	.saudi {
		top: 50%;
    	left: 47%;
	}
	.yemen {
		top: 58%;
		left: 10%;
	}
	.somalia {
		top: 58%;
		left: 26%;
	}
	.sudan {
		top: 58%;
		left: 42%;
	}
	.iran {
		top: 58%;
		left: 56%;
	}
	.qatar {
		top: 58%;
		left: 68%;
	}
	.bahrain {
		top: 58%;
		left: 81%;
	}
	.kuwait {
		top: 57%;
    	left: 50%;
	}
	.uae {
		top: 64%;
    	left: 51%;
	}
	.napal {
		top: 66%;
		left: 37%;
	}
	.india {
		top: 53%;
    left: 63%;
	}
	.maldives {
		top: 66%;
		left: 64%;
	}
	.myanmar {
		top: 66%;
		left: 80%;
	}
	.thailand {
		top: 60%;
    	left: 70%;
	}
	.malaysia {
		top: 74%;
		left: 27%;
	}
	.singapore {
		top: 74%;
		right: 38%
	}
	.tasttimor {
		top: 74%;
		left: 63%;
	}
	.australia {
		top: 82%;
		right:74%;
	}
	.newZealand {
		top: 82%;
		right: 53%;
	}
	.southkorea {
		top: 53%;
    	right: 12%;
	}
	.japan {
		top: 46%;
		right: 14%;
	}
	.cambadia {
		top: 90%;
		right: 73%;
	}
	.philippines {
		top: 90%;
		right: 52%;
	}
	.nepal{
    top: 46%;
    left: 63%;
	}
	
}
/* Medium devices (tablets, 768px and up)*/
@media only screen and (max-width: 991px) and (min-width: 768px)  {
	
  .export-area-map .title{
    top: 20px;
  }
  .title h4{
    font-size: 15px;
  }
  .title h2{
    font-size: 26px;
    font-family: 'Rubik';
  }
	.canada {
		top: 13%;
		left: 10%;
	}
	.usa{
		top: 34%;
    left: 18%;
	}
	.maxico {
		top: 10%;
		left: 32%;
	}
	.nicaragua {
		top: 90%;
		left: 10%;
	}
	.philippines{
		top: 90%;
		right: 60%;
	}
	.dominicanrepublic {
		top: 13%;
		left: 45%;
	}
	.brazil {
		top: 13%;
		left: 67%;
	}
	.ireland {
		top: 13%;
		left: 78%;
	}
	.switzerland {
		top: 28%;
    	left: 33%;
	}
	.germany {
		top: 43%;
    	left: 33%;
	}
	.france {
		top: 20%;
		left: 40%;
	}
	.spain {
		top: 36%;
    	left: 37%;
	}
	.portugal {
		left: 32%;
    	top: 61%;
	}
	.sweden {
		top: 20%;
		left: 77%;
	}
	.netherlands {
		top: 30%;
		left: 10%;
	}
	.uk {
		top: 30%;
		left: 26%;
	}
	.denmark {
		top: 30%;
		left: 35%;
	}
	.belgium {
		top: 30%;
		left: 49%;
	}
	.slovakia {
		top: 30%;
		left: 62%;
	}
	.poland {
		top: 30%;
		left: 76%;
	}
	.czechrepublic {
		top: 40%;
		left: 10%;
	}
	.hungary {
		top: 40%;
		left: 29%;
	}
	.austria {
		top: 40%;
		left: 43%;
	}
	.romania {
		top: 44%;
    	left: 46%;
	}
	.italy {
		top: 36%;
    	left: 48%;
	}
	.slovenia {
		top: 40%;
		left: 78%;
	}
	.bulgaria {
		top: 50%;
		left: 10%;
	}
	.greece {
		top: 51%;
    	left: 30%;
	}
	.croatia {
		top: 50%;
		left: 35%;
	}
	.cyprus {
		top: 50%;
		left: 47%;
	}
	.saudi {
		top: 51%;
    	left: 42%;
	}
	.yemen {
		top: 50%;
		left: 72%;
	}
	.somalia {
		top: 60%;
		left: 10%;
	}
	.sudan{
		top: 60%;
		left: 23%;
	}
	.iran{
		top: 60%;
		left: 35%;
	}
	.qatar{
		top: 60%;
		left: 45%;
	}
	.bahrain{
		top:60%;
		left: 55%;
		
	}
	.kuwait{
		top: 58%;
    left: 52%;
	}
	.uae{
		top: 66%;
    	left: 52%;
	}
	.nepal{
    	top: 43%;
    	left: 59%;
	}
	.india {
		top: 51%;
    	left: 59%;
	}
	.maldives{
		top: 70%;
		left: 20%;
	}
	.myanmar{
		top: 70%;
		left: 33%;
	}
	.thailand{
		top: 60%;
    	left: 67%;
	}
	.malaysia{
		top: 70%;
		left: 60%;
	}
	.singapore {
		top: 70%;
		right: 13%;
	}
	.tasttimor {
		top: 80%;
		left: 10%;
	}
	.australia {
		top: 80%;
		right: 62%;
	}
	.newZealand {
		top: 80%;
		right: 46%;
	}
	.southkorea {
		top: 52%;
    right: 16%;
	}
	.japan {
		top: 42%;
    	right: 17%
	}
	.cambadia {
		top: 80%;
		right: 5%;
	}
}
/* Large devices (desktops, 992px and up)*/
@media only screen and (max-width: 1199px) and (min-width: 992px)  {
	/**New css */
	.romania{
    	top: 39%;
    	left: 48%;
	}
	.maxico {
		left: 10%;
	}
	.dominicanrepublic {
		left: 19%;
	}
	.ireland {
		top: 28%;
		left: 35%;
	}
	.switzerland {
		top: 34%;
		left: 35%;
	}
	.germany {
		top: 40%;
		left: 35%;
	}
	.france {
		top: 46%;
		left: 26%;
	}
	.spain {
		top: 50%;
    	left: 36%;
	}
	.portugal {
		top: 59%;
		left: 36%;
	}
	.sweden {
		top: 22%;
	}
	.netherlands {
		top: 28%;
		left: 44%;
	}
	.uk {
		top: 34%;
		left: 47%;
	}
	.poland {
		top: 34%;
		left: 54%;
	}
	.denmark {
		left: 45%;
	}
	.hungary {
		left: 58%;
	}
	.belgium {
		top: 46%;
		left: 44%;
	}
	.czechrepublic {
		top: 46%;
		left: 58%;
	}
	.slovakia {
		top: 46%;
		/* left: 48%; */
	}
	.austria {
		top: 46%;
		left: 43%;
	}
	.bulgaria {
		top: 52%;
		left: 51%;
	}
	
	.sudan {
		top: 80%;
	}
	.usa {
		left: 19%;
	}
	.austria {
		top: 46%;
		left: 35%;
	}
	.italy {
		top: 52%;
		left: 44%;
	}
	.sudan {
		top: 88%;
	}
	.somalia {
		top: 82%;
	}
	.yemen {
		top: 76%;
		left: 53%;
	}
	.saudi {
		top: 46%;
    	left: 47%;
	}
	.slovenia {
		top: 58%;
		left: 47%;
	}
	.bahrain {
		top: 63%;
		left: 57%;
	}
	.nepal{
		top: 47%;
    	left: 62%;
	}
	.india {
		top: 53%;
    	left: 63%;
	}
	.singapore {
		top: 67%;
		right: 17%;
	}
	.greece {
		top: 58%;
		
	}
	.australia {
		
		right: 17%;
	}
	.japan {
		top: 45%;
		right: 19%;
	}
	.southkorea {
		top: 39%;
		right: 16%;
	}
	.cambadia {
		top: 51%;
		right: 9%;
	}
	.philippines {
		top: 57%;
		right: 6%;
	}
	.malaysia {
		top: 66%;
		left: 84%;
	}
	.maldives {
		top: 75%;
	}
	.kuwait {
		top: 52%;
    left: 53%;
	}
	.uae {
		top: 58%;
    left: 56%;
	}
	
	.qatar {
		top: 58%;
		left: 57%;
	}
	.thailand {
		top: 61%;
		left: 74%;
	}
	.myanmar {
		top: 55%;
		left: 70%;
	}
	.cyprus {
		top: 70%;
		left: 48%;
	}
	.croatia {
		top: 64%;
		left: 48%;
	}
	
}
/* Extra large devices (large desktops, 1200px and up)*/

@media only screen and (max-width: 1600px) and (min-width: 1200px)  {
	.dominicanrepublic {
		top: 36%;
		left: 24%;
	}
	.portugal {
		top: 59%;
		left: 38%;
	}
	.spain {
		top: 54%;
	}
	.france {
		top: 49%;
	}
	.germany {
		top: 44%;
	}
	.switzerland {
		top: 39%;
	}
	.ireland {
		top: 34%;
	}
	.netherlands {
		top: 32%;
	}
	.uk {
		top: 37%;
		left: 45%;
	}
	.denmark {
		top: 42%;
		left: 45%;
	}
	.poland {
		top: 37%;
		left: 50%;
	}
	.belgium {
		top: 46%;
		left: 44%;
	}
	.austria {
		top: 54%;
		left: 44%;
	}
	.italy {
		top: 58%;
		left: 42%;
	}
	.slovakia {
		top: 42%;
		left: 48%;
	}
	.hungary {
		top: 39%;
		left: 59%
	}
	.sudan {
		top: 74%;
	}
	.somalia {
		top: 70%;
	}
	.yemen {
		left: 52%;
		top: 66%;
	}
	.saudi {
		top: 62%;
		left: 46%;
	}
	.greece {
		top: 58%;
		left: 48%;
	}
	.uae{
    top: 58%;
    left: 56%;
	}
	.kuwait {
		top: 53%;
    	left: 55%;
	}
	.maldives {
		top: 67%;
		left: 66%;
	}
	.bahrain {
		top: 62%;
		left: 58%;
	}
	.qatar {
		top: 58%;
	}
	.bulgaria {
		top: 47%;
		left: 56%;
	}
	.czechrepublic {
		left: 56%;
	}
	.slovenia {
		top: 50%;
		left: 44%;
	}
	.portugal {
		top: 63%;
	}
	.napal {
		top: 49%;
		left: 64%;
	}
	.iran {
		top: 51%;
		left: 59%;
	}
	.croatia {
		top: 51%;
		
	}
	.myanmar {
		top: 59%;
		left: 65%;
	}
	.southkorea {
		top: 43%;
	}
	.japan {
		top: 47%;
	}
	.cambadia {
		top: 51%;
	}
	.philippines {
		top: 55%;
		right: 19%;
	}
	.thailand {
		top: 59%;
		left: 73%;
	}
	.tasttimor {
		top: 67%;
		right: 22%;
	}
  .nepal {
    top: 51%;
    left: 66%;
  }
	.map-location {
		padding: 2px;
	}	
	.map-colm .map-colm-sec1 a {
		font-size: 13px;
	}
	

}
/** Business Catagory Area END **/
</style>