<template>
    <div>
       <section class="partner-area">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="site-title ">
                <h4 class="sub-title">
                  {{ titleArea.heading }}
                </h4>
                <h2>{{ titleArea.title }}</h2>
              </div>
            </div>
          </div>
        </div>
        <div class="container">
            <div class="row">
              
              <!-- <div v-for="i in images" :key="i.id" class="pr col-md-4 col-lg-4 col-6">
                <div class="bengal-partner">
                    <div class="img">
                      <img :src="url + i.image">
                    </div>
                </div>
              </div> -->
              
              <carousel v-if="images.length > 0" :autoplay="true" :nav="false" :items="2" :responsive="{0:{items:2,nav:false},600:{items:3}}">
                <div v-for="img in images" :key="img.id">
                    <a @click.prevent="imageLink(img.link)">
                      <img class="img-responsive" v-lazy="url + img.image" alt="dd" >
                    </a>
                </div>
              </carousel> 
              <!-- <div class="pr col-md-4 col-lg-4 col-6">
                <div class="bengal-partner">
                    <div class="img">
                      <img src="images/partner/partner-7.jpg">
                    </div>
                </div>
              </div>
               
              <div class="pr col-md-4 col-lg-4 col-6">
                <div class="bengal-partner">
                    <div class="img">
                   <img src="images/partner/partner-8.jpg">
                    </div>
                </div>
              </div>
              <div class="pr col-md-4 col-lg-4 col-6">
                <div class="bengal-partner">
                    <div class="img">
                       <img src="images/partner/partner-9.jpg">
                    </div>
                </div>
              </div>
              <div class="pr col-md-4 col-lg-4 col-6">
                <div class="bengal-partner">
                    <div class="img">
                     <img src="images/partner/partner-1.jpg">
                    </div>
                </div>
              </div>
              <div class="pr col-md-4 col-lg-4 col-6">
                <div class="bengal-partner">
                    <div class="img">
                      <img src="images/partner/partner-2.jpg">
                    </div>
                </div>
              </div>
             <div class="pr col-md-4 col-lg-4 col-6">
                <div class="bengal-partner">
                    <div class="img">
                      <img src="images/partner/partner-4.jpg">
                    </div>
                </div>
              </div> -->
               <!-- <div class="pr col-md-4 col-lg-4 col">
                <div class="bengal-partner">
                    <div class="img">
                       <img src="images/partner/partner-4.jpg">
                    </div>
                </div>
              </div>
              <div class="pr col-md-4 col-lg-4 col">
                <div class="bengal-partner">
                    <div class="img">
                       <img src="images/partner/partner-5.jpg">
                    </div>
                </div>
              </div> 
              <div class="pr col-md-4 col-lg-4 col">
                <div class="bengal-partner">
                    <div class="img">
                       <img src="images/partner/partner-6.jpg">
                    </div>
                </div>
              </div> -->
                 
            </div>
        </div>
      </section>  
         
    </div>
</template>

<script>
import axios from 'axios'
import carousel from 'vue-owl-carousel'
export default {
  props: ['ul'],
  components: {
    carousel
  },
  created() {
    //this.getData()

    if(this.ul.lang == 'English'){
        this.commonWork()
    }else if(this.ul.lang == 'Arabic'){
        this.commonWork()
    }else if(this.ul.lang == 'French'){
        this.commonWork()
    }else{
      this.getData()
    }

    this.$root.$on('english', (e) => {
        this.form.lang = e
        this.commonWork()
    })

    this.$root.$on('french', (e) => {
        this.form.lang = e
        this.commonWork()
    })

    this.$root.$on('arabic', (e) => {
        this.form.lang = e
        this.commonWork()
    })
    
  },
  data() {
    return {
      url: this.ul.url,
      titleArea: {},
      images: [],
      form: {
        lang: this.ul.lang,
        category: 'Partners'
      }
    }
  },
  methods: {
    getData(){
      this.form.lang = 'English'
      this.commonWork()
    },
    commonWork(){
        axios.post(this.url + 'api/getPartner', this.form)
        .then(({data}) => {
            this.titleArea = data.title
            this.images = data.images
        })
    },
  }
}
</script>

<style scoped>
    /** partner Area  Start **/
    img {
    max-width: 100%;
  }
.partner-area{
	padding: 100px 0;
}
.no-gutters {
	padding: 0;
}
.site-title {
	text-align: center;
	margin-bottom: 60px;
}
.achi-details {
	padding-left: 20px;
}
.achi-details a {
	color: #0e75bc;
	font-weight: bold;
	font-size: 16px;
	display: block;
}
.site-title h4::before {
	position: absolute;
	width: 63px;
	height: 2px;
	background: #0070ba;
	top: 10px;
	left: 110%;
	content: "";
}
.site-title h4::after {
    position: absolute;
    width: 63px;
    height: 2px;
    background: #0070ba;
    top: 10px;
    content: "";
    left: -76px;
}
.bengal-partner .img{
	position: relative;
	overflow: hidden;
}

.bengal-partner .img{
	transition: .5s;
}
.bengal-partner .img:hover {
	transform: scale(1.5);
	
	box-shadow: 2px 1px 15px rgba(0, 0, 0, 0.15);
	z-index: 1;
}

@media (min-width: 576px) { 
	
}
/* Medium devices (tablets, 768px and up)*/
@media only screen and (max-width: 480px) and (min-width: 320px)  {
	.site-title h2 {
		font-size: 30px;
	}
  img {
   max-width: 100%;
  }
  
}
@media only screen and (max-width: 767px) and (min-width: 480px)  {

}
/* Medium devices (tablets, 768px and up)*/
@media only screen and (max-width: 991px) and (min-width: 768px)  {
	
}
/* Large devices (desktops, 992px and up)*/
@media only screen and (max-width: 1199px) and (min-width: 992px)  {
	
	.bengal-partner img {
    width: 75%;
    
	}
}
/* Extra large devices (large desktops, 1200px and up)*/

@media only screen and (max-width: 1600px) and (min-width: 1200px)  {
.bengal-partner img {
    width: 75%;
   
	}
	
}
/** Partner Area END **/
</style>