import Vue from 'vue'
import App from './App.vue'

import VueRouter from 'vue-router'
Vue.use(VueRouter)

// import $ from 'jquery'
// window.$ = $


Vue.config.productionTip = false
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'

import axios from 'axios'
Vue.prototype.$http = axios

import "bootstrap/dist/css/bootstrap.min.css"

import VideoBackground from 'vue-responsive-video-background-player'
Vue.component('video-background', VideoBackground)

import AboutUs from './components/home/aboutUs.vue'
Vue.component('about-us', AboutUs)

import Counter from './components/home/counter.vue'
Vue.component('counter-area', Counter)

import Category from './components/home/category.vue'
Vue.component('category-area', Category)

import Mapp from './components/home/map.vue'
Vue.component('map-area', Mapp)

import Blog from './components/home/blog.vue'
Vue.component('blog-area', Blog)

import Achievement from './components/home/achievement.vue'
Vue.component('achievement-area', Achievement)

import Partner from './components/home/partner.vue'
Vue.component('partner-area', Partner)

import Topbar from './components/top_bar.vue'
Vue.component('top-bar', Topbar)

import TopbarMobile from './components/topbar_mobile.vue'
Vue.component('topbar-mobile', TopbarMobile)

import Footer from './components/footer.vue'
Vue.component('footer-area', Footer)

import VueLazyload from 'vue-lazyload'
Vue.use(VueLazyload, {
    preLoad: 1.3,
    error: 'dist/error.jpg',
    loading: 'dist/main.jpg',
    attempt: 1
  })

import { routes } from './routes'

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior() {
    return {x: 0, y: 0}
  }
})

import Embed from 'v-video-embed'
// global register
Vue.use(Embed);

new Vue({
  render: h => h(App),
  router
}).$mount('#app')
